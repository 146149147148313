import { useRef, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';

import { Loader } from '@casanova/casanova-common';
import useQuery from 'hooks/useQuery';
import { getCloseContractDetails } from 'store/dailyRentContracts/actions';

import styles from './styles.module.scss';
import { number2mxn } from 'utils/currency';
import { getExtraChargesByCheckListType } from '@reservations/commons/utils';
import { useReservation } from 'hooks';
import moment from 'moment';
import { PAYMENTS_TYPES } from 'utils/constants/payments';

export default function PDF({ withLoader = true }) {
  const [extraCharges, setExtraCharges] = useState({});
  const dispatch = useDispatch();
  const closeContractDetail = useSelector(
    (state) => state.dailyRentContracts.detailsOfCloseContract.data
  );
  const hasCloseContractFetched = useSelector(
    (state) => state.dailyRentContracts.detailsOfCloseContract.success
  );
  const loader = useSelector((state) => state.commons.loader);

  const params = useParams();
  const query = useQuery();
  const contractUuid = query.get('uuid') || params.id;
  const parentUrl = useMemo(() => query.get('parentUrl'), [query]);

  const pdfRef = useRef(null);

  const {
    id = '',
    customer = '',
    crid,
    licencia,
    telefono,
    direccion,

    sucursal,
    sucursalDir,
    sucursalTel,
    totalBonificado,
    cargoInicial,
    cargosExtra,
    subtotalCargoExtra,
    subtotalInicial,

    folio,
    // gasolinaSalidaInicial,
    diasIniciales,
    totalPagado,

    // subtotal,
    // iva,
    total,
    cargos = [],
    totalAnticipado,
    saldo,
    firma,
    reservationData = {},
    fechaCierre,
    usuarioCierre = '',
  } = closeContractDetail;

  const {
    invoiceInfo,
    vehicleInfo,
    reservationInfo: { payments = [] },
  } = useReservation();
  const {
    total: totalAmount,
    // rental,
    // IVAOfAmount,
    daysDifference,
  } = invoiceInfo;

  const hasExtraChargePayment = Boolean(
    payments.find((payment) => payment.type === PAYMENTS_TYPES.EXTRA_CHARGES)
  );

  const totalExtraCharges = hasExtraChargePayment
    ? extraCharges?.extraChargesReturn?.totalPrice
    : 0;
  const totalCargos = cargos.reduce((prev, cargo) => prev + cargo.amount, 0);
  const totalAccount = total;
  const iva = (totalAccount * 0.16) / 1.16;
  const subtotal = totalAccount - iva;

  const { vehicleTitle } = vehicleInfo;

  const getCheckByCheckType = (type = '') =>
    reservationData?.verificationChecklists &&
    reservationData?.verificationChecklists.find(
      (check) => check.type === type
    );

  const formatearKilometraje = (numero = 0) =>
    numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const dateTimeToSpanishFormat = (datetime = '') => {
    if (!datetime.length) return 'Sin fecha';
    const date = new Date(datetime);
    const optionsDate = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    };
    return date.toLocaleString('es-MX', optionsDate);
  };

  const dataEntrega = getCheckByCheckType('DELIVERY');
  const dataDevolucion = getCheckByCheckType('RETURN');

  /* Firmas */
  const firmaDevolucion = dataDevolucion?.form?.inputs?.[0]?.signature;
  const firmaEntrega = dataEntrega?.form?.inputs?.[0]?.signature;

  const getGasOrKm = (data = {}, type = '') => {
    const groupDashboard = data?.verificationGroups?.find((group) =>
      group.name.includes('Tablero')
    );

    return groupDashboard
      ? groupDashboard?.verificationPoints?.[0]?.additionalFields?.[type]?.value
      : 0;
  };

  /* Gasolina */
  const gasolinaSalidaInicial = getGasOrKm(dataEntrega, 'gas');
  const gasolinaEntregaFinal = getGasOrKm(dataDevolucion, 'gas');

  /* Kilometraje */
  const kilometrajeInicial = getGasOrKm(dataEntrega, 'km');
  const kilometrajeFinal = getGasOrKm(dataDevolucion, 'km');

  /* Días */
  const daysOfDifferenceClose = () => {
    const startDate = moment(
      reservationData?.priceQuote?.pickupDateTime,
      'yyyy-MM-DD hh:mm'
    );
    const endDate = moment(
      fechaCierre ? fechaCierre : reservationData?.priceQuote?.returnDateTime,
      'yyyy-MM-DD hh:mm'
    );

    const differenceD = endDate.diff(startDate, 'days');
    const differenceM = endDate.diff(startDate, 'minutes');

    if (differenceD === 0) return 1;

    const daysToMinutes = differenceD * 24 * 60;
    const minutesRemaining = differenceM - daysToMinutes;
    const minutesToDays = differenceM / (24 * 60);
    const hasRemaining = minutesToDays - differenceD > 0;

    let minutesToDaysRounded = 0;
    if (hasRemaining) {
      // Comprobar que sean mas de 3 horas en los minutos restantes
      if (minutesRemaining > 180) {
        minutesToDaysRounded = Math.ceil(minutesToDays);
      } else {
        minutesToDaysRounded = Math.floor(minutesToDays);
      }
    }

    return hasRemaining ? minutesToDaysRounded : differenceD;
  };

  useEffect(() => {
    if (!_isEmpty(reservationData)) {
      setExtraCharges({
        ...extraCharges,
        extraChargesReturn: getExtraChargesByCheckListType(
          'RETURN',
          reservationData
        ),
      });
    }
  }, [reservationData]);

  useEffect(() => {
    if (contractUuid) {
      dispatch(getCloseContractDetails(contractUuid));
    }
  }, []);

  useEffect(() => {
    if (
      window?.parent &&
      parentUrl &&
      hasCloseContractFetched &&
      !_isEmpty(closeContractDetail)
    ) {
      window.parent.postMessage('hasFetched', parentUrl);
    }
  }, [parentUrl, hasCloseContractFetched, closeContractDetail]);

  if (loader && withLoader) return <Loader show />;

  return (
    <>
      <div ref={pdfRef} className={`w-100 px-6 ${styles.pdf}`}>
        <div className="row">
          <div className="col-7">
            <h4 className="text-uppercase">
              {reservationData?.naturalPerson?.fullName}
            </h4>
            {/* <div>
            <p className="font-weight-bold d-inline-block">CRID: </p>{' '}
            <p className="d-inline-block"> {crid}</p>
          </div> */}
            <div>
              <p className="font-weight-bold d-inline-block">LICENCIA: </p>{' '}
              <p className="d-inline-block"> {licencia}</p>
            </div>
            <div>
              <p className="font-weight-bold d-inline-block">TEL: </p>{' '}
              <p className="d-inline-block"> {telefono}</p>
            </div>
            <div>
              <p className="font-weight-bold d-inline-block">DIR: </p>{' '}
              <p className="d-inline-block"> {direccion}</p>
            </div>
          </div>
          <div className="col-5">
            <div className={`card ${styles.card}`}>
              <div className="card-body">
                <p className="text-uppercase">{sucursal}</p>
                <p className="text-uppercase">CV43534534FRFTGERTE</p>
                <p className="text-uppercase">{sucursalDir}</p>
                <p className="text-uppercase">TELEFONO (S): {sucursalTel}</p>
              </div>
            </div>

            {/* <div className="d-flex justify-content-end mt-1">
            <p className="font-weight-bold d-inline-block">ID:</p>
            <p className="d-inline-block ml-2">{id}</p>
          </div> */}
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12 py-0">
            <h4>CARGOS INICIALES</h4>
          </div>
          <div className="col-12 d-flex justify-content-between">
            <p className="text-uppercase">
              {
                reservationData?.priceQuote?.selectedVehicleLinePrice
                  ?.vehicleLinePrice?.rentalPlanName
              }{' '}
              {vehicleTitle}
            </p>
            <p className="font-weight-bold">{number2mxn(totalAmount)}</p>
          </div>
          <div className="col-12 d-flex justify-content-between">
            <p className="text-uppercase">Subtotal</p>
            <p className="font-weight-bold">{number2mxn(totalAmount)}</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12 py-0">
            <h4>CARGOS DURANTE LA RENTA</h4>
          </div>
          <>
            {extraCharges?.extraChargesReturn?.listOfExtraCharges?.length > 0 &&
              extraCharges?.extraChargesReturn?.listOfExtraCharges.map(
                (extraCharge) => (
                  <div
                    key={extraCharge.id}
                    className="col-12 d-flex justify-content-between"
                  >
                    <p className="text-uppercase">
                      ({extraCharge.groupName}) {extraCharge.name}
                    </p>
                    <p className="font-weight-bold">
                      {number2mxn(extraCharge.price)}
                    </p>
                  </div>
                )
              )}
            {cargos.length > 0 &&
              cargos.map((extraCharge) => (
                <div
                  key={extraCharge.uuid}
                  className="col-12 d-flex justify-content-between"
                >
                  <p className="text-uppercase">{extraCharge.concept}</p>
                  <p className="font-weight-bold">
                    {number2mxn(extraCharge.amount)}
                  </p>
                </div>
              ))}
            <div className="col-12 d-flex justify-content-between">
              <p className="text-uppercase">Subtotal</p>
              <p className="font-weight-bold">
                {number2mxn(totalExtraCharges + totalCargos)}
              </p>
            </div>
          </>
        </div>

        <div className="row">
          <div className="col-6 offset-2">
            <div className="d-flex justify-content-between">
              <p className="mb-0">SUBTOTAL</p>
              <p className="mb-0">{number2mxn(subtotal)}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">IVA</p>
              <p className="mb-0">{number2mxn(iva)}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0 font-weight-bold">TOTAL</p>
              <p className="mb-0">{number2mxn(totalAccount)}</p>
            </div>
            {/* <div className="d-flex justify-content-between">
            <p className="mb-0 font-weight-bold">**** **** **** 6453</p>
          </div> */}
          </div>
          <div className="col-4" />
        </div>

        <div className="row">
          <div className="col-12 m-0">
            <h5>VEHICULO</h5>
          </div>
          <div className="col-12">
            <p className="text-uppercase">NO. CONTRATO: --- {folio}</p>
            <p className="text-uppercase">
              Gasolina salida: --- {gasolinaSalidaInicial}/8 KILOMETRAJE
              INICIAL: --- {`${formatearKilometraje(kilometrajeInicial)} km`}{' '}
              SALIDA: ---{' '}
              {dateTimeToSpanishFormat(
                reservationData?.priceQuote?.pickupDateTime
              ) ?? ''}
            </p>
            <p className="text-uppercase">
              Gasolina entrada: --- {gasolinaEntregaFinal}/8 KILOMETRAJE FINAL:
              --- {`${formatearKilometraje(kilometrajeFinal)} km`} REGRESO: ---{' '}
              {dateTimeToSpanishFormat(
                fechaCierre
                  ? fechaCierre
                  : reservationData?.priceQuote?.returnDateTime
              ) ?? ''}
            </p>
            {/* <p className="text-right text-uppercase">{daysDifference} Días</p> */}
            <p className="text-right text-uppercase">
              {daysOfDifferenceClose()} Días
            </p>
          </div>
          <div className="col-12 d-flex justify-cont">
            <p>{diasIniciales}</p>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-6 flex flex-column align-items-center">
            <div className={styles.signature}>
              <img
                className="w-100 h-100"
                src={firma || firmaDevolucion || firmaEntrega}
                alt="Signature"
              />
            </div>
            <p className="text-uppercase">
              {reservationData?.naturalPerson?.fullName}
            </p>
          </div>
          <div className="col-6 flex flex-column justify-content-evenly">
            <span className="font-normal text-xl">
              A LA FECHA {dateTimeToSpanishFormat(fechaCierre) ?? ''}
            </span>
            <span className="font-normal text-xl">
              TOTAL PAGADO: {number2mxn(totalPagado)}
            </span>
            <span className="font-normal text-xl">
              TOTAL BONIFICADO: {number2mxn(totalBonificado)}
            </span>
            <span className="font-normal text-2xl">
              SALDO DE LA CUENTA:{' '}
              <span
                className={
                  parseFloat(saldo) > 0
                    ? 'text-success'
                    : parseFloat(saldo) < 0
                    ? 'text-danger'
                    : ''
                }
              >
                {number2mxn(saldo)}
              </span>
            </span>
          </div>
        </div>
        <div className="row justify-content-end">
          <p className="font-normal text-base text-right subtitle">
            CIERRE: {usuarioCierre}
          </p>
        </div>
      </div>
    </>
  );
}

PDF.propTypes = {
  withLoader: PropTypes.bool,
};
