import * as yup from 'yup';
import i18n from '@i18n';
import { setParamsListOfReservations } from 'store/reservations/actions';
import { getBrach, getRoleName } from '../../../services/auth';

const userBranchUuid =
  getRoleName() != 'CONTACT_CENTER' && getRoleName() != 'AUDITOR' && getBrach()
    ? getBrach()
    : '';

const schema = yup.object().shape({
  folioRD: yup.string(),
  nameRD: yup.string(),
  emailRD: yup.string(),
  cellphoneRD: yup.string(),
  createdDate: yup.string(),
  categoryRD: yup.string(),
  deliveryDate: yup.string(),
  returnDate: yup.string(),
  daysDiffRD: yup.string(),
  deliveryBranchRD: yup.string(),
  observationsRD: yup.string(),
  stepRD: yup.string(),
  plateNumberRD: yup.string(),
});

const initialValues = {
  folioRD: '',
  nameRD: '',
  emailRD: '',
  cellphoneRD: '',
  createdDate: '',
  categoryRD: '',
  deliveryDate: '',
  returnDate: '',
  daysDiffRD: '',
  deliveryBranchRD: userBranchUuid,
  observationsRD: '',
  stepRD: '',
  plateNumberRD: '',
};

export const initialTouched = {
  folioRD: true,
  nameRD: true,
  emailRD: true,
  cellphoneRD: true,
  createdDate: true,
  categoryRD: true,
  deliveryDate: true,
  returnDate: true,
  daysDiffRD: true,
  deliveryBranchRD: true,
  observationsRD: true,
  stepRD: true,
  plateNumberRD: true,
};

const formatter = (values) => {
  const { folioRD } = values;
  return {
    folio: folioRD == '' ? folioRD : folioRD.padStart(3, '0'),
    name: values.nameRD,
    email: values.emailRD,
    cellphone: values.cellphoneRD,
    category: values.categoryRD,
    daysDiff: values.daysDiffRD,
    deliveryBranch: values.deliveryBranchRD,
    observations: values.observationsRD,
    step: values.stepRD,
    plateNumber: values.plateNumberRD,
  };
};

export const config = {
  schema,
  modalType: 'empty',
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter,
  submitForm: setParamsListOfReservations,
  withButtons: false,
  dialogs: {
    confirmation: {},
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      successMessage: '',
      successLabel: '',
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      errorLabel: i18n('COMMONS__CLOSE__TEXT'),
    },
  },
};
