import React, { useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, useFormikContext, validateYupSchema } from 'formik';
import classNames from 'classnames';
import {
  MaskedInput as Input,
  Button,
  Icons,
  FileInput,
} from '@casanova/casanova-common';
import Section from 'components/Section';
import ConnectedSelect from 'components/ConnectedSelect';
import {
  alphaMask,
  alphaMaskWithSpaces,
  numericMask,
  alphaMaskWithSpacesAndAccent,
  alphaMaskWithAmpersand,
} from 'utils/masks';
import { transformForSelect, transformIdOfList } from 'utils/transformHelpers';
import { acceptFiles } from 'utils/files';
import i18n from '@i18n';
import { defaultInvoiceData } from './config';
import { invoiceDataSchema } from './schema';

const InvoiceData = ({
  action,
  isJuridicalCustomer,
  getTaxZipCode,
  taxZipCode,
  fetchCatalog,
  dataLow,
}) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setTouched,
    setValues,
  } = useFormikContext();
  const { invoiceData = [] } = values;
  const [exist, setExist] = useState(true);
  const [rfcIndex, setRfcIndex] = useState(0);
  const taxZipCodeExist = taxZipCode?.list?.length >= 1;

  const handleFileInputChange = (index, event) => {
    const file = event.target.files[0];
    setFieldValue('invoiceData', (prevInvoiceData) => {
      const updatedInvoiceData = [...prevInvoiceData];
      updatedInvoiceData[index].taxSituationFile = file;
      return updatedInvoiceData;
    });
  };

  const handleGetTaxZipCode = useCallback(
    (event) => {
      const { value } = event.target;
      if (value && value.length >= 5) {
        if (!taxZipCodeExist && exist) {
          setFieldValue(
            `invoiceData[${rfcIndex}].invoiceTaxZipCodeExist`,
            false
          );
        }
        getTaxZipCode(value);
      }
      if (value.length === 0) {
        setFieldValue(`invoiceData[${rfcIndex}].invoiceTaxZipCodeExist`);
      }
    },
    [getTaxZipCode, setFieldValue, rfcIndex]
  );

  // useEffect(() => {
  //   setFieldValue(`invoiceData[${rfcIndex}].fiscalRegime`, '');
  // }, [invoiceData[rfcIndex].rfc]);

  // useEffect(() => {
  //   setFieldValue(`invoiceData[${rfcIndex}].cfdiUse`, '');
  // }, [invoiceData[rfcIndex].fiscalRegime]);

  useEffect(() => {
    if (taxZipCodeExist) {
      setExist(false);
      setFieldValue(`invoiceData[${rfcIndex}].invoiceTaxZipCodeExist`, true);
    }
    if (taxZipCodeExist || exist) {
      return;
    }
    setFieldValue(
      `invoiceData[${rfcIndex}].invoiceTaxZipCodeExist`,
      taxZipCodeExist
    );
  }, [taxZipCode, exist]);

  useEffect(() => {
    if (!isJuridicalCustomer && action === 'add') {
      setFieldValue('invoiceData[0].socialReason', '');
      setFieldValue('invoiceData[0].rfc', '');
    }
  }, [setFieldValue, isJuridicalCustomer, action]);

  const handleCheckDefault = useCallback(
    (elementIndex) => {
      setTimeout(() => {
        invoiceData.forEach((_, index) => {
          if (index !== elementIndex) {
            setFieldValue(`invoiceData[${index}].useDefault`, undefined);
          }
        });
      }, 1);
      setFieldValue(`invoiceData[${elementIndex}].useDefault`, true);
    },
    [invoiceData, setFieldValue]
  );

  const handleAddInvoice = useCallback(
    ({ push }) => {
      const index = invoiceData.length - 1;
      const lastInvoice = invoiceData[index];
      const someFieldWithValue = Object.values(lastInvoice).some(
        (value) => value
      );
      if (lastInvoice && someFieldWithValue) {
        validateYupSchema(lastInvoice, invoiceDataSchema)
          .then(() => push(defaultInvoiceData[0]))
          .catch(() => {
            const invoiceTouched = Object.entries(lastInvoice).reduce(
              (acc, [key]) => {
                acc[key] = true;
                return acc;
              },
              {}
            );
            const newInvoiceData = [...invoiceData];
            newInvoiceData[index] = invoiceTouched;
            setTouched({ ...touched, invoiceData: newInvoiceData });
          });
      }
    },
    [invoiceData, touched, setTouched]
  );

  const hasFieldWithValue = useMemo(() => {
    const someFieldWithValue = ([, value]) => Boolean(value);
    const currentInvoiceDataValues = Object?.entries(invoiceData[0]);
    return currentInvoiceDataValues?.some(someFieldWithValue);
  }, [values]);

  useEffect(() => {
    if (values.invoiceData[rfcIndex]?.fiscalRegime !== '') {
      fetchCatalog({
        catalogId: 'catalogs/cfdi-uses/fiscal-regime',
        params: {
          uuid: values.invoiceData[rfcIndex]?.fiscalRegime,
          isJuridical: !invoiceData[rfcIndex]?.rfc?.length == 12,
        },
        index: rfcIndex,
        catalogPath: 'pricequotes',
        initialLows: dataLow,
      });
    }
  }, [values.invoiceData[rfcIndex]?.fiscalRegime]);

  const cleanLicenseButton = () => {
    const cleanedInvoiceData = values.invoiceData.map(() => ({
      ...defaultInvoiceData[0],
    }));
    setValues({
      ...values,
      invoiceData: cleanedInvoiceData,
    });
  };

  return (
    <Section title={i18n('CUSTOMERS__INVOICE__DATA__INVOICE_DATA_TITLE')}>
      {invoiceData && invoiceData.length > 0 && (
        <FieldArray name="invoiceData">
          {(arrayHelpers) => {
            const { remove } = arrayHelpers;
            return (
              <>
                {transformIdOfList(values.invoiceData).map(({ id }, index) => {
                  const handleRemove = () => remove(index);
                  const handleCheck = () => handleCheckDefault(index);

                  // const arrayListTransformed = transformForSelect(
                  //   dataLow[index] !== undefined && dataLow[index] !== null
                  //     ? dataLow[index]
                  //     : [],
                  //   'uuid',
                  //   'name'
                  // );

                  const arrayListTransformed = transformForSelect(
                    dataLow[0] !== undefined && dataLow[0] !== null
                      ? dataLow[0]
                      : [],
                    'uuid',
                    'name'
                  );

                  return (
                    <div
                      onClick={() => setRfcIndex(id)}
                      key={id}
                      className="row"
                    >
                      <div className="col-4">
                        <Input
                          // mask={alphaMaskWithSpacesAndAccent(50)}
                          maskPlaceholder=""
                          label={`${i18n(
                            'CUSTOMERS__INVOICE__DATA__SOCIAL_REASON'
                          )} ${hasFieldWithValue ? '*' : ''}`}
                          placeholder={`${i18n(
                            'CUSTOMERS__INVOICE__DATA__SOCIAL_REASON'
                          )} ${hasFieldWithValue ? '*' : ''}`}
                          name={`invoiceData[${index}].socialReason`}
                          disabled={action === 'view'}
                        />
                      </div>
                      <div className="col-4">
                        <Input
                          mask={alphaMaskWithAmpersand(13)}
                          maskPlaceholder=""
                          label={`RFC ${hasFieldWithValue ? '*' : ''}`}
                          placeholder={`RFC ${hasFieldWithValue ? '*' : ''}`}
                          name={`invoiceData[${index}].rfc`}
                          disabled={action === 'view'}
                          onChange={() => {
                            setFieldValue(
                              `invoiceData[${rfcIndex}].fiscalRegime`,
                              ''
                            );
                            setFieldValue(
                              `invoiceData[${rfcIndex}].cfdiUse`,
                              ''
                            );
                          }}
                        />
                      </div>
                      <div className="col-md-4 d-flex align-items-center">
                        <ConnectedSelect
                          label={`${i18n(
                            'CUSTOMERS__INVOICE__DATA__FISCAL_REGIME'
                          )} ${hasFieldWithValue ? '*' : ''}`}
                          placeholder={`${i18n(
                            'CUSTOMERS__INVOICE__DATA__FISCAL_REGIME'
                          )} ${hasFieldWithValue ? '*' : ''}`}
                          name={`invoiceData[${index}].fiscalRegime`}
                          options={
                            invoiceData[index].rfc.length == 13
                              ? 'commons.catalogsCfdiFiscalRegimeNaturalPerson'
                              : 'commons.catalogsCfdiFiscalRegimeJuridicalPerson'
                          }
                          onChange={() =>
                            setFieldValue(
                              `invoiceData[${rfcIndex}].cfdiUse`,
                              ''
                            )
                          }
                        />
                        {values.invoiceData && values.invoiceData.length > 1 && (
                          <span
                            onClick={handleRemove}
                            onKeyPress={handleRemove}
                            tabIndex="0"
                            role="button"
                            className="removable svg-icon svg-icon-center"
                          >
                            <Icons.CloseGroup width="100%" height="100%" />
                          </span>
                        )}
                      </div>
                      {invoiceData[index].fiscalRegime.length !== 0 && (
                        <div className="col-4">
                          <ConnectedSelect
                            disabled={action === 'view'}
                            label={`${i18n(
                              'CUSTOMERS__INVOICE__DATA__CFDI_USES'
                            )} ${hasFieldWithValue ? '*' : ''}`}
                            isArrayList
                            placeholder={`${i18n(
                              'CUSTOMERS__INVOICE__DATA__CFDI_USES'
                            )} ${hasFieldWithValue ? '*' : ''}`}
                            name={`invoiceData[${index}].cfdiUse`}
                            options="commons.catalogsCfdiUsesFiscalRegime"
                            arrayList={arrayListTransformed}
                            className="full-row"
                          />
                        </div>
                      )}
                      <div className="col-4">
                        <Input
                          mask={numericMask(5)}
                          maskPlaceholder=""
                          label={`${i18n(
                            'CUSTOMERS__INVOICE__DATA__TAX_ZIP_CODE'
                          )} ${hasFieldWithValue ? '*' : ''}`}
                          placeholder={`${i18n(
                            'CUSTOMERS__INVOICE__DATA__TAX_ZIP_CODE'
                          )} ${hasFieldWithValue ? '*' : ''}`}
                          name={`invoiceData[${index}].taxZipCode`}
                          onChange={handleGetTaxZipCode}
                        />
                      </div>
                      <div className="col-4">
                        <FileInput
                          url={
                            invoiceData[index].taxSituationFile
                              ? invoiceData[index].taxSituationFile.presignedUrl
                              : undefined
                          }
                          displayText={
                            invoiceData[index].taxSituationFile.originalName
                          }
                          type="file"
                          label="SITUACIÓN FISCAL"
                          placeholder="SITUACIÓN FISCAL"
                          name={`invoiceData[${index}].taxSituationFile`}
                          accept={acceptFiles}
                        />
                      </div>
                      {!isJuridicalCustomer && invoiceData.length < 3 && (
                        <div className="d-flex col-4 justify-content-end align-items-center">
                          <span className="btn-add-span">
                            {i18n('CUSTOMERS__INVOICE__ADD_INVOICE_DATA')}
                          </span>
                          <Button
                            onClick={() => handleAddInvoice(arrayHelpers)}
                            color="add"
                          >
                            <span className="icon">+</span>
                          </Button>
                        </div>
                      )}
                      <div className="col-md-4 d-flex align-items-center">
                        {hasFieldWithValue && values.invoiceData.length == 1 && (
                          <span
                            onClick={() => cleanLicenseButton(index)}
                            onKeyPress={() => cleanLicenseButton(index)}
                            className="removable svg-icon svg-icon-center"
                            role="button"
                            tabIndex="0"
                          >
                            <Icons.CloseGroup width="100%" height="100%" />
                          </span>
                        )}
                      </div>
                      {values.invoiceData && values.invoiceData.length > 1 && (
                        <div className="col-12 d-flex align-items-center extra-row">
                          <span
                            onClick={handleCheck}
                            onKeyPress={handleCheck}
                            tabIndex="0"
                            role="button"
                            className={classNames(
                              `svg-icon`,
                              invoiceData[index].useDefault &&
                                'svg-icon--active'
                            )}
                          >
                            <Icons.CheckGroup width="100%" height="100%" />
                          </span>
                          <span className="text">
                            {i18n('CUSTOMERS__INVOICE__DEFAULT_INVOICE_DATA')}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            );
          }}
        </FieldArray>
      )}
      {errors && errors.globalValidation && (
        <div className="col-form-error text-danger">
          {errors.globalValidation}
        </div>
      )}
    </Section>
  );
};
InvoiceData.propTypes = {
  action: PropTypes.string,
};
InvoiceData.defaultProps = {
  action: 'add',
};
export default InvoiceData;
