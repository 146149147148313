import { CellText, CellDashed } from 'components/ColumnsCells';
import { AdditionalHeaderCamp } from 'components';
import {
  onlyLettersWithSpaces,
  numericMask,
  alphaEmailMask,
  alphaMaskWithSpacesAndAccent,
  alphaMaskWithSpaces,
} from 'utils/masks';
import VERSIONS from 'utils/constants/versions';
import {
  CANCEL_RESERVATION as CANCEL_RESERVATION_PERMISSION,
  EDIT_RESERVATION as EDIT_RESERVATION_PERMISSION,
} from 'utils/roles/permissions/reservationsPermissions';
import { validateRolePermissions } from 'utils/roles';
import { getBrach, getRoleName } from '../../../services/auth';

export default [
  {
    label: 'Folio',
    field: 'folio',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={numericMask(10)}
        name="folioR"
        searchKey="folioR"
      />
    ),
  },
  {
    label: 'Cliente',
    field: 'naturalPersonName',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersWithSpaces(40)}
        name="nameR"
        searchKey="nameR"
      />
    ),
  },
  // {
  //   label: 'Correo electrónico',
  //   field: 'email',
  //   align: 'center',
  //   renderer: CellText,
  //   AdditionalHeaderCamp: () => (
  //     <AdditionalHeaderCamp
  //       mask={alphaEmailMask(50)}
  //       name="emailR"
  //       searchKey="emailR"
  //     />
  //   ),
  // },
  // {
  //   label: 'Teléfono',
  //   field: 'cellphone',
  //   align: 'center',
  //   renderer: CellText,
  //   AdditionalHeaderCamp: () => (
  //     <AdditionalHeaderCamp
  //       mask={numericMask(10)}
  //       name="cellphoneR"
  //       searchKey="cellphoneR"
  //     />
  //   ),
  // },
  {
    label: 'Reservación',
    field: 'createdDateFormatted',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="createdDate"
        mask={numericMask(10)}
        searchKey="createdDate"
        type="DATERANGE"
        dateProps={{
          minimumDate: 'reservations.filters.reservationList.minCreatedDate',
          maximumDate: 'reservations.filters.reservationList.maxCreatedDate',
        }}
      />
    ),
  },
  {
    label: 'Línea',
    field: 'lineVersion',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={alphaMaskWithSpaces(20)}
        name="lineVersionR"
        searchKey="lineVersionR"
      />
    ),
  },
  {
    label: 'Categoría del vehículo',
    field: 'category',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={alphaMaskWithSpacesAndAccent(16)}
        name="categoryR"
        searchKey="categoryR"
      />
    ),
  },
  {
    label: 'Salida',
    field: 'deliveryDateFormatted',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="deliveryDate"
        mask={numericMask(10)}
        searchKey="deliveryDate"
        type="DATERANGE"
        dateProps={{
          minimumDate: 'reservations.filters.reservationList.minDeliveryDate',
          maximumDate: 'reservations.filters.reservationList.maxDeliveryDate',
        }}
      />
    ),
  },
  {
    label: 'Devolución',
    field: 'returnDateFormatted',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="returnDate"
        mask={numericMask(10)}
        searchKey="returnDate"
        type="DATERANGE"
        version={VERSIONS.V2}
        dateProps={{
          minimumDate: 'reservations.filters.reservationList.minReturnDate',
          maximumDate: 'reservations.filters.reservationList.maxReturnDate',
        }}
      />
    ),
  },
  {
    label: 'Días de renta',
    field: 'daysDiff',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={numericMask(2)}
        name="daysDiffR"
        searchKey="daysDiffR"
      />
    ),
  },
  {
    label: 'Sucursal',
    field: 'deliveryBranchName',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="deliveryBranchR"
        searchKey="deliveryBranchR"
        options="reservations.filters.reservationList.deliveryBranches"
        selectProps={{ fullKey: true, availableFirstValue: true }}
        type="SELECT"
        disabled={
          getRoleName() != 'CONTACT_CENTER' &&
          getRoleName() != 'AUDITOR' &&
          getBrach()
        }
      />
    ),
  },
  {
    label: 'Observaciones',
    field: 'observations',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp name="observationsR" searchKey="observationsR" />
    ),
  },
  {
    label: 'Realizada por',
    field: 'createdBy',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersWithSpaces(40)}
        name="createdByR"
        searchKey="createdByR"
      />
    ),
  },
  {
    label: 'Etapa',
    field: 'stepName',
    align: 'center',
    renderer: (props) => {
      const { data = {} } = props;
      return (
        <CellDashed
          {...props}
          fixedColor={data.statusColor}
          value={data.stepName}
          align="left"
          textClass="text-left"
        />
      );
    },
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="stepR"
        searchKey="stepR"
        options="reservations.filters.reservationList.steps"
        type="SELECT"
        selectProps={{ fullKey: true, availableFirstValue: true }}
      />
    ),
  },
];

export const CANCEL_RESERVATION = {
  label: 'Cancelar',
  name: 'cancel',
  showExpanded: false,
};

export const MODIFY_RESERVATION = {
  label: 'Modificar',
  name: 'modify',
  showExpanded: false,
};

export const contextualMenu = {
  CANCEL_RESERVATION,
  MODIFY_RESERVATION,
};

export const getContextualMenu = () => {
  const menu = {
    CANCEL_RESERVATION,
    MODIFY_RESERVATION,
  };
  if (!validateRolePermissions(CANCEL_RESERVATION_PERMISSION))
    delete menu.CANCEL_RESERVATION;
  if (!validateRolePermissions(EDIT_RESERVATION_PERMISSION))
    delete menu.MODIFY_RESERVATION;
  return menu;
};
