import React, { useCallback, useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { TableNavigation, Table, withForm } from '@casanova/casanova-common';
import useCleanModule from 'hooks/useCleanModule';
import { config } from './config';
import columns from './columns';
import { getRoleName } from '../../../services/auth';

function ListOfReturns({
  history,
  listOfReservations,
  setListOfReservationsPage,
  setInitialParamsListOfReservations,
  getListOfReservations,
  getReservationInfo,
  getReservationListFilters,
  hydrateChangeOfVehicleFlow,
  resetReservations,
  onUpdateForm,
}) {
  const { params, searchPattern, filters, results } = listOfReservations;
  const [hasPermissionToDetail, setHasPermissionToDetail] = useState(true);

  useEffect(() => {
    if (getRoleName() === 'AUDITOR') {
      setHasPermissionToDetail(false);
    }
  }, []);

  useCleanModule({ module: 'reservations' });

  const handleRowClick = useCallback(
    ({ uuid, changeRequest, ...reservation }) => {
      if (!hasPermissionToDetail) return;
      if (changeRequest) {
        hydrateChangeOfVehicleFlow({
          reservationUuid: uuid,
          changeRequestUuid: changeRequest,
        });
      } else {
        getReservationInfo({ uuid, ...reservation });
      }
    },
    [
      history,
      getReservationInfo,
      hydrateChangeOfVehicleFlow,
      hasPermissionToDetail,
    ]
  );

  useEffect(() => {
    resetReservations({ exceptions: ['listOfReservations'] });
    setInitialParamsListOfReservations('RETURN');
    getReservationListFilters({ tableSelected: 'RETURN' });
  }, []);

  useEffect(() => {
    if (params.tableSelected == 'RETURN')
      getListOfReservations({ params, filters, searchPattern });
  }, [getListOfReservations, params, filters, searchPattern]);

  useEffect(() => {
    if (!_isEmpty(params)) {
      onUpdateForm(params);
    }
  }, [onUpdateForm]);

  return (
    <div>
      <Table
        additionalHeader
        rowClassName="row-clickable"
        rowClick={handleRowClick}
        emptyMessage="No se encontraron resultados"
        dataList={results.list}
        columns={columns}
      />
      <div className="row">
        <div className="col-12 col-xl-7 table-paginator text-right d-flex justify-content-between align-items-center">
          <TableNavigation
            search={listOfReservations}
            onChange={setListOfReservationsPage}
          />
        </div>
        <div className="col-12 col-md-8 col-xl-5" />
      </div>
    </div>
  );
}

export default withForm({ config })(ListOfReturns);
