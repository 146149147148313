import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withLayout } from '@casanova/casanova-common';
import {
  getListOfReservations,
  getListOfReservationsForExcel,
  setListOfReservationsPage,
  setInitialParamsListOfReservations,
  getReservationInfo,
  getReservationListFilters,
  closeResponseDialog,
  hydrateChangeOfVehicleFlow,
  resetReservations,
  cancelReservation,
  getVehicleTypes,
} from 'store/reservations/actions';
import { fetchCatalog } from 'store/commons/actions';
import ListOfReservations from './ListOfReservations';
import { search, reset } from 'store/customers/actions';
import { getDetail } from 'store/customers/actions';

export default withLayout(
  compose(
    connect(
      (state) => ({
        listOfReservations: state.reservations.listOfReservations.list,
        listOfReservationsForExcel: state.reservations.listOfReservationsForExcel.list,
        confirmationMessage: false,
        responseDialog: {
          ...state.reservations.responseDialog,
          closeInConfirmation: true,
        },
      }),
      {
        search,
        resetCustomers: reset,
        getDetail,
        getListOfReservations,
        getListOfReservationsForExcel,
        setListOfReservationsPage,
        setInitialParamsListOfReservations,
        getReservationInfo,
        fetchCatalog,
        getReservationListFilters,
        closeResponseDialog,
        hydrateChangeOfVehicleFlow,
        resetReservations,
        cancelReservation,
        getVehicleTypes,
      }
    )
  )(ListOfReservations)
);
