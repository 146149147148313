import React, { useState, useCallback } from 'react';
import Modal from '@casanova/casanova-common/lib/Modal';
import PageNavigator from 'components/PageNavigator';
import { SpecItem } from '@reservations/components';
import { getSpectList as getSpecList } from '@reservations/commons/transformers';
import './VerificationPointModal.scss';
import { boolean } from 'yup';
import classNames from 'classnames';
import i18n from '@i18n';
import _isEmpty from 'lodash/isEmpty';

const VALUES_IDENTIFIERS = {
  KM: 'KM',
  GAS: '/ 8',
};

export default function VerificationPointModal({
  open = false,
  verificationPoint = {},
  deliveryInfo = {},
  onClose,
}) {
  const [swiperIndex, setSwiperIndex] = useState(0);
  const {
    images = [],
    remarks = '',
    name = '',
    isSet = false,
    passed = false,
    additionalFields = {},
    title = '',
    position = 1,
    vehicleImage = '',
    showDetails = true,
    signature = null,
    extraCharges = [],
  } = verificationPoint;
  const [inDeliveryMode, setInDeliveryMode] = useState(false);
  const [stockInCurrentPoint, setStockInCurrentPoint] = useState(false);
  const [checkPassed, setCheckPassed] = useState(false);

  const selectModeClassName = classNames(
    'VehicleParts__Select-mode--rectangle',
    inDeliveryMode && 'VehicleParts__Select-mode--rectangle__primary-bg'
  );
  const selectModeShapeClassName = classNames(
    'shape',
    `shape--${inDeliveryMode ? 'inactive' : 'active'}`
  );
  const handleChangeCurrentPage = useCallback((currentPage) => {
    setSwiperIndex(currentPage);
  }, []);

  const deliveryModeClassName = classNames(
    'btn delivery-button',
    `text-${inDeliveryMode ? 'white' : 'primary'}`,
    stockInCurrentPoint && 'disable-pointer'
  );
  const refundModeClassName = classNames(
    'btn refund-button',
    `text-${inDeliveryMode ? 'primary' : 'white'}`,
    stockInCurrentPoint && 'disable-pointer'
  );
  const imageSrc = images[swiperIndex] ? images[swiperIndex].presignedUrl : '';

  const changeVerifyList = (mode) => () => {
    setSwiperIndex(0);
    if (inDeliveryMode === true && mode === 'DELIVERY')
      return setStockInCurrentPoint(true);
    if (inDeliveryMode === false && mode === 'RETURN')
      return setStockInCurrentPoint(true);
    setInDeliveryMode(mode === 'DELIVERY');
    setStockInCurrentPoint(false);
  };

  const subItems = Object.values(additionalFields).map(
    ({ label = '', value = '' }) => {
      const isKM = label.toLowerCase().includes('kilometraje');
      const isGas = label.toLowerCase().includes('gasolina');

      const valueIdentifier = isKM
        ? VALUES_IDENTIFIERS.KM
        : isGas
        ? VALUES_IDENTIFIERS.GAS
        : '';

      let valueToShow;

      if (typeof value === 'boolean') {
        valueToShow = `${label}: ${value ? '✔️' : '✖'}`;
        return label ? valueToShow : label;
      }
      valueToShow = `${label}: ${value} ${valueIdentifier}`;
      return label && value ? valueToShow : label;
    }
  );

  React.useEffect(() => {
    setSwiperIndex(0);
    setInDeliveryMode(deliveryInfo.onlyEntrega);
    if (verificationPoint.extraCharges)
      setCheckPassed(passed && _isEmpty(verificationPoint.extraCharges));
  }, [deliveryInfo]);

  return (
    <Modal
      open={open}
      header={null}
      footer={null}
      className="VerificationPointModal"
      containerClassName="VerificationPointModal__Container"
      contentClassName="VerificationPointModal__Content px-4 py-2"
    >
      <div className="modal-header justify-content-center align-items-end p-0">
        <span
          className="btn py-0 pr-2"
          role="button"
          tabIndex="0"
          onClick={() => {
            setInDeliveryMode(false);
            onClose();
          }}
          onKeyDown={onClose}
        >
          <h3 className="text-secondary m-0 p-2">X</h3>
        </span>
      </div>
      <div className="row text-center ">
        <div className="col-12">
          <h3 className="text-center mb-3">
            {title && title.length > 4 ? title : name}
          </h3>
        </div>
        {!deliveryInfo.onlyEntrega && !(position === 1 || position === 0) && (
          <div className="col-12">
            <div className="col-12">
              <div className={selectModeClassName}>
                <div className={selectModeShapeClassName} />
                <button
                  className={deliveryModeClassName}
                  onClick={changeVerifyList('DELIVERY')}
                  type="button"
                  tabIndex="0"
                >
                  <h6 className="m-0">
                    {i18n('RESERVATIONS__DELIVERY__DETAIL_TITLE')}
                  </h6>
                </button>
                <button
                  className={refundModeClassName}
                  onClick={changeVerifyList('RETURN')}
                  type="button"
                  tabIndex="0"
                >
                  <h6 className="m-0">
                    {i18n('RESERVATIONS__REFUND__DETAIL_TITLE')}
                  </h6>
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="col-12 VerificationPointModal__Content--Image-container">
          {position !== 1 &&
            !inDeliveryMode &&
            (vehicleImage || imageSrc || signature) && (
              <>
                <img
                  src={
                    signature ||
                    deliveryInfo.returnPoint.verificationPoints[
                      deliveryInfo.index
                    ]?.images[swiperIndex]?.presignedUrl
                  }
                  className="view-image__image mt-2 VerificationPointModal__Image"
                  alt="jhkjkjk"
                />
                {!(position === 1 || position === 0) && (
                  <p className="mt-2">
                    {new Date(deliveryInfo.returnDate).toLocaleString()}
                  </p>
                )}
              </>
            )}
          {position === 1 && (
            <img
              src={vehicleImage || imageSrc}
              className="view-image__image mt-2 VerificationPointModal__Image"
              alt="dsadas"
            />
          )}
          {inDeliveryMode &&
            deliveryInfo.images &&
            deliveryInfo.images.length > 0 && (
              <>
                <img
                  src={
                    signature || deliveryInfo?.images[swiperIndex]?.presignedUrl
                  }
                  className="view-image__image mt-2 VerificationPointModal__Image"
                  alt="fdfdfddf"
                />
                <p className="mt-2">
                  {new Date(deliveryInfo.deliveryDate).toLocaleString()}
                </p>
              </>
            )}
          {!inDeliveryMode &&
            deliveryInfo?.returnPoint?.verificationPoints[deliveryInfo?.index]
              ?.images.length > 1 && (
              <PageNavigator
                containerClassName="view-image__paginator-container mt-1 VerificationPointModal__Paginator"
                elements={
                  deliveryInfo?.returnPoint?.verificationPoints[
                    deliveryInfo?.index
                  ]?.images
                }
                onChangeCurrentPage={handleChangeCurrentPage}
              />
            )}
          {inDeliveryMode && deliveryInfo?.images?.length > 1 && (
            <PageNavigator
              containerClassName="view-image__paginator-container mt-1 VerificationPointModal__Paginator"
              elements={deliveryInfo?.images}
              onChangeCurrentPage={handleChangeCurrentPage}
            />
          )}
        </div>
      </div>
      {showDetails && (
        <>
          <div className="row mt-4 p-0 d-flex">
            <div className="col-6 mt-5">
              <div className="row ">
                <div className="col-12 d-flex alig-items-center justify-content-start">
                  <SpecItem
                    specList={[
                      getSpecList({
                        isSet,
                        checkPassed,
                      }),
                    ]}
                    check={checkPassed}
                    subItems={subItems}
                    className="CardInfo__info-container--Spec-item"
                    textClassName="m-0"
                    iconStyle={{
                      width: '2.25rem',
                      height: '2.25rem',
                    }}
                    disableIconType={3}
                  />
                </div>
              </div>
            </div>
            <div className="col" />
          </div>
          <div className="row">
            <div className="col-12 text-left Remarks">
              <p>{remarks || 'Sin comentarios.'}</p>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}
