import { all, call, put, select } from 'redux-saga/effects';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import * as additionalsMeta from 'services/vehicles/additionals';
import i18n from '@i18n';
import { showLoader, hideLoader } from 'store/commons/actions';
import { setResponseDialog as setReservationResDialog } from 'store/reservations/actions';
import {
  fetchAllVehicles,
  fetchVehicleFilters,
  saveVehicle,
  uploadVehicles,
  uploadMassiveChange,
  downloadRecords,
  downloadMassiveChangeRecords,
  fetchVehicleInfo,
  editVehicle,
  exportVehicleDetail,
  exportVehicles,
  downloadTemplateForMassiveLoad,
  downloadTemplateForMassiveChange,
  getAdditional,
  updateAdditional,
  saveAdditional,
  uploadAdditionalFile,
  getAdditionalDownloadLink,
  getVehiclesTypes,
  getVehicleHistoricStatus,
  getVehicleHistoricContracts,
  patchVehicleDetails,
  getVehicleDetails,
  patchMarkVehicleForSale,
  getVehiclesByPlate,
  getVehicleHistoricVirtualContracts,
} from 'services/vehicles';
import { getLoansList } from 'services/loans';
import { getOptionsAuth } from 'services/auth';
import { getVehicleParamsToFetchSections } from '@vehicles/common/utils';
import { getTransfersList } from 'services/transfers';
import {
  fecthVehicles,
  fetchVehiclesSucceeded,
  fetchVehiclesFailed,
  saveVehicleSucceeded,
  fetchVehiclesFiltersSucceeded,
  fetchVehiclesFiltersFailed,
  saveVehicleFailed,
  fetchVehicleInfoSucceeded,
  fetchVehicleInfoFailed,
  editVehicleSucceeded,
  editVehicleFailed,
  getAdditional as getAdditionalAction,
  getTypesSucceeded,
  getTypesFailed,
  vehicleStatusSucceeded,
  vehicleStatusFailed,
  vehicleContractsSucceeded,
  vehicleContractsFailed,
  vehicleLoansSucceeded,
  vehicleLoansFailed,
  vehicleTransfersSucceeded,
  vehicleTransfersFailed,
  fetchHistoricDetailsSucceeded,
  fetchHistoricDetailsFailed,
  editVehicleDetailsSucceeded,
  editVehicleDetailsFailed,
  fetchVehicleDetailsSucceeded,
  fetchVehicleDetailsFailed,
  markVehicleForSaleSucceeded,
  markVehicleForSaleFailed,
  searchVehiclesFailed,
  searchVehiclesSucceeded,
  updateVehiclesList,
  exportVehiclesCatalogSucceeded,
  exportVehiclesCatalogFailed,
  vehicleVirtualContractsSucceed,
  vehicleVirtualContractsFailed,
} from './actions';
import {
  UPLOAD_VEHICLES_SUCCEEDED,
  UPLOAD_VEHICLES_FAILED,
  UPLOAD_MASSIVE_CHANGE_SUCCEEDED,
  UPLOAD_MASSIVE_CHANGE_FAILED,
  GET_ADDITIONAL_SUCCEED,
  SAVE_ADDITIONAL_SUCCEED,
  SAVE_ADDITIONAL_FAILED,
} from './actions-types';

export function* fetchVehiclesSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(fetchAllVehicles, action.payload, getOptionsAuth());
    yield put(fetchVehiclesSucceeded({ data }));
  } catch (error) {
    yield put(fetchVehiclesFailed({ error }));
  } finally {
    yield put(hideLoader());
  }
}

export function* fetchVehiclesFiltersSaga() {
  try {
    yield put(showLoader());
    const data = yield call(fetchVehicleFilters, {}, getOptionsAuth());
    yield put(fetchVehiclesFiltersSucceeded(data));
  } catch (error) {
    console.log(error);
    yield put(fetchVehiclesFiltersFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* fetchVehiclesInfoSaga(action) {
  try {
    const data = yield call(fetchVehicleInfo, action.payload, getOptionsAuth());
    // console.log(data, 'data');
    yield put(fetchVehicleInfoSucceeded({ data }));
  } catch (error) {
    yield put(fetchVehicleInfoFailed(error));
  }
}

export function* saveVehicleSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(saveVehicle, action.payload, getOptionsAuth());
    if (!data.success) {
      yield put(saveVehicleFailed(data));
    } else {
      yield put(saveVehicleSucceeded({ data }));
    }
  } catch (error) {
    yield put(saveVehicleFailed({ error }));
  } finally {
    yield put(hideLoader());
  }
}

export function* editVehicleSaga(action) {
  try {
    const data = yield call(editVehicle, action.payload, getOptionsAuth());
    if (!data.success) {
      yield put(editVehicleFailed(data));
    } else {
      yield put(editVehicleSucceeded({ data }));
    }
  } catch (error) {
    yield put(editVehicleFailed({ error }));
  }
}

export function* uploadVehiclesSaga(action) {
  try {
    const massiveLoadType = yield select(
      (state) => state.vehicles.massiveLoadType
    );
    const data = yield call(
      uploadVehicles,
      action.payload,
      getOptionsAuth(),
      massiveLoadType
    );

    if (!data.success) {
      yield put({
        type: UPLOAD_VEHICLES_FAILED,
        payload: { data: { errorCode: data.data.errorCode } },
      });
    } else {
      yield put({
        type: UPLOAD_VEHICLES_SUCCEEDED,
        payload: { data: data.data },
      });
    }
  } catch (error) {
    yield put({ type: UPLOAD_VEHICLES_FAILED, payload: error });
  }
}

export function* uploadMassiveChangeSaga(action) {
  try {
    const massiveChangeType = yield select(
      (state) => state.vehicles.massiveChangeType
    );
    const data = yield call(
      uploadMassiveChange,
      action.payload,
      getOptionsAuth(),
      massiveChangeType
    );

    if (!data.success) {
      yield put({
        type: UPLOAD_MASSIVE_CHANGE_FAILED,
        payload: { data: { errorCode: data.data.errorCode } },
      });
    } else {
      yield put({
        type: UPLOAD_MASSIVE_CHANGE_SUCCEEDED,
        payload: { data: data.data },
      });
    }
  } catch (error) {
    yield put({ type: UPLOAD_MASSIVE_CHANGE_FAILED, payload: error });
  }
}

export function* downloadFailedRecordsSaga() {
  const getFile = (state) => state.vehicles.massiveRecords.file;
  const file = yield select(getFile);

  yield call(downloadRecords, file, getOptionsAuth());
}

export function* downloadMassiveChangeFailedRecordsSaga() {
  const getFile = (state) => state.vehicles.massiveChangeRecords.file;
  const file = yield select(getFile);

  yield call(downloadMassiveChangeRecords, file, getOptionsAuth());
}

export function* getAdditionalSaga({ payload }) {
  const { name, data, withFiles } = payload;
  try {
    const result = yield call(
      getAdditional,
      name,
      data,
      withFiles,
      getOptionsAuth()
    );
    yield put({
      type: GET_ADDITIONAL_SUCCEED,
      payload: {
        name,
        data: result,
      },
    });
  } catch (error) {
    yield put({
      type: GET_ADDITIONAL_SUCCEED,
      payload: {
        name,
        data: null,
      },
    });
  }
}

export function* saveAdditionalSaga({ payload }) {
  const { name, data, forceCreate } = payload;
  try {
    const isUpdate = forceCreate ? false : !!data.id;
    const result = yield call(
      isUpdate ? updateAdditional : saveAdditional,
      name,
      data
    );
    const id = isUpdate ? result.id : result;

    const nextData = {
      ...data,
      ...(isUpdate ? result : { id: result }),
    };
    const fileFields = additionalsMeta[name].fileFields || [];
    const requests = fileFields
      .filter((field) => typeof _get(data, `${field}.name`) === 'string')
      .map((field) => call(uploadAdditionalFile, name, { ...data, id }, field));

    yield all(requests);
    yield put({
      type: SAVE_ADDITIONAL_SUCCEED,
      payload: {
        name,
        data: nextData,
        resultMessage: {
          result,
          message: isUpdate
            ? 'The additional has been updated'
            : 'The additional has been created',
        },
      },
    });
    yield put(
      getAdditionalAction({ name, data: data.vehicleId, withFiles: fileFields })
    );
  } catch (error) {
    yield put({
      type: SAVE_ADDITIONAL_FAILED,
      payload: {
        name,
        resultMessage: {
          message: error.message,
          errorCode: error.name,
        },
      },
    });
  }
}

export function* downloadAdditionalFileSaga({ payload }) {
  const { name, data, field } = payload;
  try {
    const url = yield call(getAdditionalDownloadLink, name, data, field);
    window.open(url);
  } catch (error) {
    console.error(error);
  }
}

export function* exportVehiclesSaga(action) {
  try {
    yield call(exportVehicles, action.payload);
    yield put(exportVehiclesCatalogSucceeded());
  } catch (e) {
    yield put(exportVehiclesCatalogFailed());
  }
}

export function* exportVehicleDetailSaga(action) {
  yield call(exportVehicleDetail, action.payload);
}

export function* downloadTemplateSaga(action) {
  yield call(downloadTemplateForMassiveLoad, action.payload);
}

export function* downloadMassiveChangeTemplateSaga(action) {
  yield call(downloadTemplateForMassiveChange, action.payload);
}

export function* getTypesSaga({ payload }) {
  try {
    const data = yield call(getVehiclesTypes, payload);
    yield put(getTypesSucceeded(data));
  } catch (error) {
    yield put(getTypesFailed({ error }));
  }
}

export function* vehicleStatusSaga({ payload }) {
  const { withLoader = true } = payload;
  try {
    if (withLoader) yield put(showLoader());
    const data = yield call(getVehicleHistoricStatus, payload);
    yield put(vehicleStatusSucceeded(data));
  } catch (error) {
    yield put(vehicleStatusFailed(error));
  } finally {
    if (withLoader) yield put(hideLoader());
  }
}

export function* vehicleContractsSaga({ payload }) {
  const { withLoader = true } = payload;
  try {
    if (withLoader) yield put(showLoader());
    const data = yield call(getVehicleHistoricContracts, payload);
    yield put(vehicleContractsSucceeded(data));
  } catch (error) {
    yield put(vehicleContractsFailed(error));
  } finally {
    if (withLoader) yield put(hideLoader());
  }
}

export function* vehicleLoansSaga({ payload }) {
  const { withLoader = true } = payload;
  try {
    if (withLoader) yield put(showLoader());
    const params = getVehicleParamsToFetchSections(payload);
    const data = yield call(getLoansList, {
      params: {
        ...params,
        ...payload.params,
      },
    });
    yield put(vehicleLoansSucceeded(data));
  } catch (error) {
    yield put(vehicleLoansFailed(error));
  } finally {
    if (withLoader) yield put(hideLoader());
  }
}

export function* vehicleTransfersSaga({ payload }) {
  const { withLoader = true } = payload;
  try {
    if (withLoader) yield put(showLoader());
    const params = getVehicleParamsToFetchSections(payload);
    const data = yield call(getTransfersList, {
      params: {
        ...params,
        ...payload.params,
      },
    });
    yield put(vehicleTransfersSucceeded(data));
  } catch (error) {
    console.log(error);
    yield put(vehicleTransfersFailed(error));
  } finally {
    if (withLoader) yield put(hideLoader());
  }
}

export function* fetchHistoricDetailsSaga({ payload }) {
  try {
    yield put(showLoader());
    let vehicleDetails = yield select((state) =>
      _get(state, 'vehicles.vehicleDetails.data', {})
    );
    const commonParams = {
      uuid: payload.vehicleUuid,
      withLoader: false,
    };

    if (_isEmpty(vehicleDetails)) {
      vehicleDetails = yield fetchVehicleDetailSaga({ payload: commonParams });
    }

    const loanAndTransferParams = {
      plateNumber: vehicleDetails.plateNumber,
      serialNumber: vehicleDetails.serialNumber,
      uuid: payload.vehicleUuid,
      vehicleUuid: payload.vehicleUuid,
      withLoader: false,
    };
    const statusParams = yield select(
      (state) => state.vehicles.vehicleStatus.params
    );
    const contractsParams = yield select(
      (state) => state.vehicles.vehicleContracts.params
    );
    const loansParams = yield select(
      (state) => state.vehicles.vehicleLoans.params
    );
    const transfersParams = yield select(
      (state) => state.vehicles.vehicleTransfers.params
    );
    const virtualContractsParams = yield select(
      (state) => state.vehicles.virtualContracts.params
    );
    yield all([
      vehicleStatusSaga({
        payload: { params: statusParams, ...commonParams },
      }),
      vehicleContractsSaga({
        payload: { params: contractsParams, ...commonParams },
      }),
      vehicleLoansSaga({
        payload: {
          params: loansParams,
          ...loanAndTransferParams,
        },
      }),
      vehicleTransfersSaga({
        payload: {
          params: transfersParams,
          ...loanAndTransferParams,
        },
      }),
      vehicleVirtualContractsSaga({
        payload: {
          params: virtualContractsParams,
          ...loanAndTransferParams,
        },
      }),
    ]);
    yield put(fetchHistoricDetailsSucceeded({ success: true }));
  } catch (error) {
    yield put(fetchHistoricDetailsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* editVehicleDetailSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(patchVehicleDetails, payload);
    const updatedDetails = yield call(getVehicleDetails, payload.vehicleUuid);
    yield put(fetchVehicleDetailsSucceeded(updatedDetails));
    yield put(editVehicleDetailsSucceeded(data));
    const list = yield select(
      (state) => state.vehicles.vehiclesList.results.list
    );
    if (list && list.length > 0) {
      const valueToEdit = list.find((element) => element.uuid === data.uuid);
      valueToEdit.status = data.vehicleStatus.name;
      yield put(updateVehiclesList(list));
    }
  } catch (error) {
    yield put(editVehicleDetailsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* fetchVehicleDetailSaga({ payload }) {
  const { withLoader = true } = payload;
  try {
    if (withLoader) yield put(showLoader());
    const data = yield call(getVehicleDetails, payload.uuid);
    yield put(fetchVehicleDetailsSucceeded(data));
    return data;
  } catch (error) {
    yield put(fetchVehicleDetailsFailed(error));
  } finally {
    if (withLoader) yield put(hideLoader());
  }
}

export function* markVehicleForSaleSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(patchMarkVehicleForSale, payload);
    yield put(markVehicleForSaleSucceeded(data));
  } catch (error) {
    yield put(markVehicleForSaleFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getVehiclesByPlateSaga(action) {
  let loader = false;
  let showResponseDialog = false;
  const errorDialog = (message) => ({
    open: true,
    success: false,
    error: true,
    message,
    errorLabel: 'Cerrar',
    closeInConfirmation: true,
  });

  try {
    if (typeof action.payload === 'object') {
      loader = Boolean(action.payload.showLoader);
      showResponseDialog = Boolean(action.payload.showResponseDialog);
    }

    if (loader) {
      yield put(showLoader());
    }

    if (showResponseDialog) {
      yield put(
        setReservationResDialog({
          open: false,
          success: false,
          error: false,
          message: '',
          errorLabel: '',
          closeInConfirmation: false,
        })
      );
    }

    const data = yield call(getVehiclesByPlate, action.payload);
    yield put(searchVehiclesSucceeded(data));

    if (showResponseDialog && data.length === 0) {
      yield put(
        setReservationResDialog(
          errorDialog(i18n('VEHICLES__ERROR__NO_VEHICLES_FOUND'))
        )
      );
    }
  } catch (error) {
    yield put(searchVehiclesFailed(error));
    if (showResponseDialog) {
      yield put(setReservationResDialog(errorDialog(error.message)));
    }
  } finally {
    if (loader) {
      yield put(hideLoader());
    }
  }
}

export function* vehicleVirtualContractsSaga({ payload }) {
  const { withLoader = true } = payload;
  try {
    if (withLoader) yield put(showLoader());
    const data = yield call(
      getVehicleHistoricVirtualContracts,
      payload.vehicleUuid,
      payload.params
    );
    yield put(vehicleVirtualContractsSucceed(data));
  } catch (error) {
    console.log(error);
    yield put(vehicleVirtualContractsFailed(error));
  } finally {
    if (withLoader) yield put(hideLoader());
  }
}
