import qs from 'qs';
import {
  sendCustomRequest,
  sendFormRequest,
} from '@casanova/casanova-common/lib/utils/service';
import { cleanParams } from '@casanova/casanova-common/lib/utils/requests';
import { transformForSelect } from 'utils/transformHelpers';
import { APIError } from 'utils/exceptions';
import { vehicleRoot } from 'services/roots';
import {
  transformToFilters,
  transformReservationListFilters,
} from './transformers';
//import { getList } from '../../store/customers/actions';

const root = 'pricequotes/v1';
const invoice = 'http://localhost';

export async function createInvoiceSap(body) {
  const url = `api/v1/invoice/setInvoice`;
  const response = await sendCustomRequest(url, {}, body, 'POST', invoice);
  if (!response.success) {
    const { errorCode, message } = response;
    throw new APIError(errorCode, message);
  }
  return response.data;
}

export async function getMostQuoted(category = '') {
  const url = `${root}/catalogs/vehicle-prices/vehicle-lines/most-viewed?category=${category}`;
  const response = await sendCustomRequest(url, {}, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getVehicleLinePrices(payload) {
  const { vehicleLineUuid, businessArea, startRentalDay } = payload;
  const params = { businessArea, startRentalDay };
  const url = `${root}/catalogs/vehicle-prices/${vehicleLineUuid}${
    params && '?' + qs.stringify({ ...params })
  }`;
  const response = await sendCustomRequest(url, {}, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getVehicleTypesFilters() {
  const url = `${vehicleRoot}/catalog/rental-filter-v2`;
  const response = await sendCustomRequest(url, {}, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return transformToFilters(response.data.data);
}

export async function getAvailableDays() {
  const url = `${root}/catalogs/available-time`;
  const response = await sendCustomRequest(url, {}, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getVehiclesCatalog(
  vehicleTypes = [],
  brands = [],
  branch = '',
  lineSelected = undefined,
  page = 0,
  startRentalDay = null,
  category = '',
  noPageable = false,
  size = 10
) {
  const types = vehicleTypes.join(',');
  if (types !== '') {
    category = '';
  }
  const brand = brands.join(',');
  const params = {
    size,
    page,
    types,
    branch,
    category,
    lineSelected,
    startRentalDay,
    brand,
  };

  const filter = `?${qs.stringify(cleanParams(params))}`;

  const url = `${vehicleRoot}/catalog/rental${filter}`;
  const response = await sendCustomRequest(url, {}, undefined, 'get');
  //PASAJEROS
  const compactos = ['AVEO', 'VERSA', 'GRAND I10', 'ATTITUDE', 'HB20'];
  const medianos = [
    'VIRTUS',
    'VENTO',
    'ONIX',
    'MG5',
    'RIO',
    'CITY',
    'FORTE',
    'ELANTRA',
    'COROLLA',
  ];
  const suv5 = ['HR-V', 'EQUINOX', 'SPORTAGE', 'CR-V', 'HAVAL JOLION'];
  const suv7 = [
    'XPANDER',
    'CAPTIVA',
    'TRAVERSE',
    'SEDONA',
    'CR-V',
    'SUBURBAN',
    'YUKON',
  ];
  const vanPasajeros = ['HIACE 12 PASAJEROS', 'URVAN 14 o 15 PASAJEROS'];
  //CARGA
  const camiones = ['F-350', 'ELF-400', 'SILVERADO 3500 HD', 'F-450'];
  const pickup = [
    'SILVERADO',
    'F-150',
    'NP300',
    'L200',
    'S10',
    'FRONTIER',
    'RANGER',
    'HILUX',
    'AMAROK',
  ];
  const vanCarga = ['TORNADO', 'HIACE ', 'URVAN', 'TRANSPORTER'];
  console.log(types + ',' + params.types);
  if (params.types == 'c8c86c30-7d5d-4efd-b92e-5529e398de70') {
    response.data.data.content.sort((a, b) => {
      return (
        compactos.indexOf(a.vehicleLineName) -
        compactos.indexOf(b.vehicleLineName)
      );
    });
  }
  if (params.types == '563e2d7d-a4cd-4186-94f0-25d5ace99257') {
    response.data.data.content.sort((a, b) => {
      return (
        medianos.indexOf(a.vehicleLineName) -
        medianos.indexOf(b.vehicleLineName)
      );
    });
  }
  if (params.types == '5e96393b-5d4b-4c16-b144-dbd6161d9c31') {
    response.data.data.content.sort((a, b) => {
      return suv5.indexOf(a.vehicleLineName) - suv5.indexOf(b.vehicleLineName);
    });
  }
  if (params.types == '8ddcbfa9-e61c-4f72-b1b0-83938c112f0b') {
    response.data.data.content.sort((a, b) => {
      return suv7.indexOf(a.vehicleLineName) - suv7.indexOf(b.vehicleLineName);
    });
  }
  if (params.types == '8f0cab9f-e2fa-493a-8a98-7f61e01d2fcd') {
    response.data.data.content.sort((a, b) => {
      return (
        vanPasajeros.indexOf(a.vehicleLineName) -
        vanPasajeros.indexOf(b.vehicleLineName)
      );
    });
  }
  if (params.types == '47881e2e-6f8e-4d9e-a3b0-c3503e55de15') {
    response.data.data.content.sort((a, b) => {
      return (
        camiones.indexOf(a.vehicleLineName) -
        camiones.indexOf(b.vehicleLineName)
      );
    });
  }
  if (params.types == '2ed4fe80-eb0e-4a50-b4bc-74c6ba2df413') {
    response.data.data.content.sort((a, b) => {
      return (
        pickup.indexOf(a.vehicleLineName) - pickup.indexOf(b.vehicleLineName)
      );
    });
  }
  if (params.types == '31481c04-3965-4310-9071-66553c495326') {
    response.data.data.content.sort((a, b) => {
      return (
        vanCarga.indexOf(a.vehicleLineName) -
        vanCarga.indexOf(b.vehicleLineName)
      );
    });
  }
  if (noPageable) {
    delete params.size;
    delete params.page;
  }

  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function saveStepOne(request) {
  const url = `${root}/price-quotes/step-one`;
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function saveStepTwo(request) {
  const url = `${root}/price-quotes/step-two`;
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  sessionStorage.removeItem('tmp_set_fullname_user');
  sessionStorage.removeItem('tmp_set_uuid_user');
  return response.data.data;
}

export async function editStepOne(request, uuid) {
  const url = `${root}/price-quotes/step-one`;
  const response = await sendCustomRequest(
    url,
    {},
    { ...request, priceQuote: uuid },
    'PATCH'
  );
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function saveNotPriceQuote(request) {
  const url = `${root}/reservations/no-pricequote`;
  const response = await sendCustomRequest(url, {}, request, 'POST');

  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  return response.data.data;
}

export async function getDiscounts() {
  const url = `${root}/catalogs/manager-discount-types`;
  const response = await sendCustomRequest(url, {}, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return transformForSelect(response.data.data.content, 'uuid', 'symbol');
}

export async function saveDiscount(request, uuid) {
  const url = `${root}/price-quotes/manager-discount`;
  const response = await sendCustomRequest(
    url,
    {},
    { ...request, priceQuote: uuid },
    'POST'
  );
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function validatePromocode(request, uuid) {
  const url = `${root}/price-quotes/${uuid}/promotion`;
  const response = await sendCustomRequest(url, {}, request, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function saveStepThree(request, uuid) {
  const url = `${root}/price-quotes/${uuid}/email`;
  const response = await sendCustomRequest(url, {}, request, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function createReservation(request) {
  const url = `${root}/reservations`;
  const response = await sendCustomRequest(url, {}, request, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export const getFileExtension = (name) => {
  const splitted = name.split('.');
  return splitted[splitted.length - 1].trim();
};

async function saveFiles(url, fieldName, files = []) {
  const form = new FormData();
  files.forEach((element) => {
    form.append(fieldName, element.file, element.name);
  });
  const response = await sendFormRequest(url, form, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

const getAccountFileName = (file, uuid) =>
  `${uuid}_ACC_STATEMENT.${getFileExtension(file.name)}`;

const getPaymentProofFileName = (file, uuid) =>
  `${uuid}_PAYMENT_PROOF.${getFileExtension(file.name)}`;

async function createGeneralPublic(body) {
  const url = `api/v1/invoice/setInvoice/general-public`;
  const response = await sendCustomRequest(url, {}, body, 'POST', invoice);
  if (!response.success) {
    const { errorCode, message } = response;
    throw new APIError(errorCode, message);
  }
  return response.data;
}

async function multiPayment(request, url) {
  let uuid;
  for await (const element of request.multiRequest) {
    const newReq = { ...element, ...request.commons, invoice: request.invoice };
    const response = await sendCustomRequest(url, {}, newReq, 'POST');

    if (!response.success) {
      const { errorCode, message } = response.data;
      throw new APIError(errorCode, message);
    }
    uuid = response.data.data;
    if (newReq.paymentForm !== 'CASH') {
      const files = [
        {
          file: newReq.files.paymentProof,
          name: getPaymentProofFileName(newReq.files.paymentProof, uuid),
        },
      ];

      if (
        newReq.paymentForm.includes('CARD') &&
        !newReq.cardDetails.cardHasCustomerName
      ) {
        files.push({
          file: newReq.files.accountStatus,
          name: getAccountFileName(newReq.files.accountStatus, uuid),
        });
      }

      await saveFiles(`${root}/payments/${uuid}/files`, 'files', files);
    }
    // Factura
    if (
      request.commons.paymentType === 'EXTRA_CHARGES' &&
      newReq.isTheLastPayment
    ) {
      try {
        await getInvoice(newReq.reservation);
      } catch (error) {
        console.error('Failed to get invoice, continuing without it:', error);
      }
    }
  }
  return uuid;
}

export async function savePayment(request) {
  const url = `${root}/payments`;
  let uuid;
  if (request.multiRequest) {
    uuid = await multiPayment(request, url);
    return uuid;
  }

  // Si no son diferentes pagos ejecuta esto
  const response = await sendCustomRequest(url, {}, request, 'POST');

  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  uuid = response.data.data;
  if (request.paymentForm !== 'CASH') {
    const files = [
      {
        file: request.files.paymentProof,
        name: getPaymentProofFileName(request.files.paymentProof, uuid),
      },
    ];

    if (
      request.paymentForm.includes('CARD') &&
      !request.cardDetails.cardHasCustomerName
    ) {
      files.push({
        file: request.files.accountStatus,
        name: getAccountFileName(request.files.accountStatus, uuid),
      });
    }

    await saveFiles(`${root}/payments/${uuid}/files`, 'files', files);
  }
  // Factura
  if (request.paymentType === 'EXTRA_CHARGES') {
    try {
      await getInvoice(request.reservation);
    } catch (error) {
      console.error('Failed to get invoice, continuing without it:', error);
    }
  }

  return uuid;
}

export async function savePaymentTest(request) {
  return request;
}

export async function assignCustomer(request, reservation) {
  const url = `${root}/reservations/${reservation}/customer`;
  const response = await sendCustomRequest(url, {}, request, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getReservationsCatalogue({ params }, options) {
  const queryParams = `?${qs.stringify(cleanParams(params))}`;
  const url = `pricequotes/v1/reservations${queryParams}`;
  const response = await sendCustomRequest(url, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const { data = {} } = response.data;
  return data;
}

export async function getSingleReservation(uuid, options) {
  const endpoint = `${root}/reservations/${uuid}`;

  const response = await sendCustomRequest(endpoint, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  return response.data.data;
}

export async function postVerificationCheckListPoint(uuid, body, options) {
  const endpoint = `pricequotes/v1/verification-checklists/points/${uuid}`;

  const response = await sendCustomRequest(endpoint, options, body, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

export async function postVerificationCheckListPointImage(uuid, files) {
  const endpoint = `${root}/verification-checklists/points/${uuid}/images`;

  const form = new FormData();
  files.forEach((element) => {
    form.append('images', element.file, element.name);
  });
  const response = await sendFormRequest(endpoint, form, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

export async function postVehicleReservation(
  reservationUuid,
  body = {},
  options = {}
) {
  const url = `${root}/reservations/${reservationUuid}/vehicle`;

  const response = await sendCustomRequest(url, options, body, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    if (errorCode.includes('480'))
      throw {
        errorCode,
        message,
      };
    throw new APIError(errorCode, message);
  }
  return response;
}

export async function postChangeVehicleOfReservation(body, options = {}) {
  const url = `${root}/reservations/vehicle/change-request`;
  const response = await sendCustomRequest(url, options, body, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getCatalogueOfVehiclesService(params, options) {
  const formattedParams = cleanParams(params);
  delete formattedParams.statuses;

  const { statuses = [] } = params;
  const statusesQuery = `${statuses.map((status) => `${status}`)}`;

  if (statusesQuery) formattedParams.statuses = statusesQuery;

  const queryParams = `?${qs.stringify(formattedParams, {
    encode: false,
  })}`;

  const url = `${vehicleRoot}/management/rental${queryParams}`;
  let test1 = {};
  test1 = await sendCustomRequest(url, options, undefined, 'GET');
  if (!test1.success || test1.data.errorCode) {
    const { errorCode, message } = test1.data;
    throw new APIError(errorCode, message);
  }
  const { content = [], totalPages = 0, totalElements = 0 } = test1.data.data;
  return {
    content,
    totalPages,
    totalElements,
  };
}

async function getInvoice(uuid) {
  const url = `api/v1/invoice/setInvoice/${uuid}`;
  const response = await sendCustomRequest(url, {});
  return response;
}

export async function postCreateReservationContract(
  body,
  options = {},
  reservationAmount = 1
) {
  const url = `${root}/contracts`;
  const response = await sendCustomRequest(url, options, body, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const { data = {} } = response.data;
  if (reservationAmount != 0) {
    try {
      getInvoice(response.data.data.uuid);
    } catch (error) {
      console.error('Failed to get invoice, continuing without it:', error);
    }
  }
  return data;
}

export async function getContractsService(uuid, options = {}) {
  const url = `${root}/contracts?uuid=${uuid}`;

  const response = await sendCustomRequest(url, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

export async function getVerifyCompletedCheckList(uuid, options = {}) {
  const url = `${root}/verification-checklists/${uuid}/completed`;

  const response = await sendCustomRequest(url, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function postFormLink(body = {}, options = {}) {
  const url = `${root}/forms/link`;

  const response = await sendCustomRequest(url, options, body, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

export async function verifySignaturesStatus(signaturesForm) {
  const url = `${root}/forms/${signaturesForm}/completed`;

  const response = await sendCustomRequest(url, {}, undefined, 'GET');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function acceptSignatures(signaturesForm) {
  const url = `${root}/forms/${signaturesForm}/accepted`;

  const response = await sendCustomRequest(url, {}, {}, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data;
}

export async function rejectSignatures(signaturesForm) {
  const url = `${root}/forms/${signaturesForm}/rejected`;

  const response = await sendCustomRequest(url, {}, {}, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data;
}

export async function sendReservationService(body = {}, options = {}) {
  const url = `${root}/reservations/sendReservation`;

  const response = await sendCustomRequest(url, options, body, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data;
}

export async function getReservationForm(uuid) {
  const url = `${root}/contracts?reservation=${uuid}`;

  const response = await sendCustomRequest(url, {}, undefined, 'GET');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getReservationListFilter(params) {
  const url = `${root}/reservations/filter${qs.stringify(cleanParams(params), {
    addQueryPrefix: true,
  })}`;

  const response = await sendCustomRequest(url, {}, undefined, 'GET');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return transformReservationListFilters(response.data.data);
}

export async function cancelReservationService(uuid) {
  const endpoint = `${root}/reservations/cancel-reservation/${uuid}`;
  const response = await sendCustomRequest(endpoint, {}, undefined, 'PUT');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

export async function getCustomerCardsService(uuidCustomer) {
  const url = `${root}/payments/customer/${uuidCustomer}/client-cards`;
  const response = await sendCustomRequest(url, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const { data = {} } = response.data;
  return data;
}

export async function updateReservationService(body = {}, options = {}) {
  const url = `${root}/reservations`;

  const response = await sendCustomRequest(url, options, body, 'PUT');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

export async function updateCardService(body = {}, options = {}) {
  const url = `${root}/payments/update_bank_card`;

  const response = await sendCustomRequest(url, options, body, 'PUT');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}
