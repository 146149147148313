import types from './action-types';

export function setStep(payload) {
  return { type: types.SET_STEP, payload };
}

export function setTitles(payload) {
  return { type: types.SET_TITLES, payload };
}

export function selectVehicleCategory(payload) {
  return { type: types.SELECT_VEHICLE_CATEGORY, payload };
}

export function setStartAvailableHours(payload) {
  return { type: types.SET_START_HOURS, payload };
}

export function setStartLimitHours(payload) {
  return { type: types.SET_START_LIMIT_HOURS, payload };
}

export function setEndAvailableHours(payload) {
  return { type: types.SET_END_HOURS, payload };
}

export function setEndLimitHours(payload) {
  return { type: types.SET_END_LIMIT_HOURS, payload };
}

export function getVehicleTypes() {
  return { type: types.GET_VEHICLE_TYPES };
}

export function getVehicleTypesSucceed(payload) {
  return { type: types.GET_VEHICLE_TYPES_SUCCEED, payload };
}

export function getVehicleTypesFailed(payload) {
  return { type: types.GET_VEHICLE_TYPES_FAILED, payload };
}

export function getMostQuoted(payload) {
  return { type: types.GET_MOST_QUOTED, payload };
}

export function getMostQuotedSucceed(payload) {
  return { type: types.GET_MOST_QUOTED_SUCCEED, payload };
}

export function getMostQuotedFailed(payload) {
  return { type: types.GET_MOST_QUOTED_FAILED, payload };
}

export function getTimeLimits() {
  return { type: types.GET_TIME_LIMITS };
}

export function getTimeLimitsSucceed(payload) {
  return { type: types.GET_TIME_LIMITS_SUCCEED, payload };
}

export function getTimeLimitsFailed(payload) {
  return { type: types.GET_TIME_LIMITS_FAILED, payload };
}

export function getVehicles(payload) {
  return { type: types.GET_VEHICLES, payload };
}

export function getVehiclesSucceed(payload) {
  return { type: types.GET_VEHICLES_SUCCEED, payload };
}

export function getVehiclesFailed(payload) {
  return { type: types.GET_VEHICLES_FAILED, payload };
}

export function saveStepOne(payload) {
  return { type: types.SAVE_QUOTE_STEP_ONE, payload };
}

export function saveStepOneSucceed(payload) {
  return { type: types.SAVE_QUOTE_STEP_ONE_SUCCEED, payload };
}

export function saveStepOneFailed(payload) {
  return { type: types.SAVE_QUOTE_STEP_ONE_FAILED, payload };
}

export function saveStepTwo(payload) {
  return { type: types.SAVE_QUOTE_STEP_TWO, payload };
}

export function saveStepTwoSucceed(payload) {
  return { type: types.SAVE_QUOTE_STEP_TWO_SUCCEED, payload };
}

export function saveStepTwoFailed(payload) {
  return { type: types.SAVE_QUOTE_STEP_TWO_FAILED, payload };
}

export function editStepOne(payload) {
  return { type: types.EDIT_QUOTE_STEP_ONE, payload };
}

export function editStepOneSucceed(payload) {
  return { type: types.EDIT_QUOTE_STEP_ONE_SUCCEED, payload };
}

export function editStepOneFailed(payload) {
  return { type: types.EDIT_QUOTE_STEP_ONE_FAILED, payload };
}

export function setInProgressQuote(payload) {
  return { type: types.SET_IN_PROGRESS_QUOTE, payload };
}

export function saveNotPriceQuote(payload) {
  return { type: types.SAVE_NOT_PRICE_QUOTE, payload };
}

export function saveNotPriceQuoteSucceed(payload) {
  return { type: types.SAVE_NOT_PRICE_QUOTE_SUCCEED, payload };
}

export function saveNotPriceQuoteFailed(payload) {
  return { type: types.SAVE_NOT_PRICE_QUOTE_FAILED, payload };
}

export function cleanQuoteId(payload = null) {
  return { type: types.CLEAN_QUOTE_ID, payload };
}

export function closeResponseDialog() {
  return { type: types.CLOSE_RESPONSE_DIALOG };
}

export function closeResponseDialogS() {
  return { type: types.CLOSE_RESPONSE_DIALOG_S };
}

export function setResponseDialog(payload) {
  return { type: types.SET_RESPONSE_DIALOG, payload };
}

export function setContractQuote(payload) {
  return { type: types.SET_CONTRACT_QUOTE, payload };
}

export function setQuoteValues(payload) {
  return { type: types.SET_QUOTE_VALUES, payload };
}

export function setParams(payload) {
  return { type: types.SET_PARAMS, payload };
}

export function setCategorySelected(payload) {
  return { type: types.SET_SELECTED_CATEGORY, payload };
}

export function setPreviousCategorySelected(payload) {
  return { type: types.SET_PREVIOUS_SELECTED_CATEGORY, payload };
}

export function getDiscounts() {
  return { type: types.GET_DISCOUNTS };
}

export function getDiscountsSucceed(payload) {
  return { type: types.GET_DISCOUNTS_SUCCEED, payload };
}

export function getDiscountsFailed(payload) {
  return { type: types.GET_DISCOUNTS_FAILED, payload };
}

export function applyBranchDiscount(payload) {
  return { type: types.APPLY_BRANCH_DISCOUNT, payload };
}

export function cancelBranchDiscount(payload) {
  return { type: types.CANCEL_BRANCH_DISCOUNT, payload };
}

export function saveDiscount(payload) {
  return { type: types.SAVE_DISCOUNT, payload };
}

export function saveDiscountSucceed(payload) {
  return { type: types.SAVE_DISCOUNT_SUCCEED, payload };
}

export function saveDiscountFailed(payload) {
  return { type: types.SAVE_DISCOUNT_FAILED, payload };
}

export function validatePromocode(payload) {
  return { type: types.VALIDATE_PROMOCODE, payload };
}

export function validatePromocodeSucceed(payload) {
  return { type: types.VALIDATE_PROMOCODE_SUCCEED, payload };
}

export function validatePromocodeFailed(payload) {
  return { type: types.VALIDATE_PROMOCODE_FAILED, payload };
}

export function saveStepThree(payload) {
  return { type: types.SAVE_QUOTE_STEP_THREE, payload };
}

export function saveStepThreeSucceed(payload) {
  return { type: types.SAVE_QUOTE_STEP_THREE_SUCCEED, payload };
}

export function saveStepThreeFailed(payload) {
  return { type: types.SAVE_QUOTE_STEP_THREE_FAILED, payload };
}

export function openSendQuoteDialog() {
  return { type: types.OPEN_SET_QUOTE_DIALOG };
}

export function closeSendQuoteDialog() {
  return { type: types.CLOSE_SET_QUOTE_DIALOG };
}

export function openSendReservationDialog(payload) {
  return { type: types.OPEN_SET_RESERVATION_DIALOG, payload };
}

export function closeSendReservationDialog() {
  return { type: types.CLOSE_SET_RESERVATION_DIALOG };
}

export function openEditScheduleSection() {
  return { type: types.OPEN_EDIT_SCHEDULE_DATES };
}

export function closeEditScheduleSection() {
  return { type: types.CLOSE_EDIT_SCHEDULE_DATES };
}

export function editPlan() {
  return { type: types.EDIT_PLAN };
}

export function resetReservations(payload) {
  return { type: types.RESET, payload };
}

export function setBrands(payload) {
  return { type: types.SET_BRANDS, payload };
}

export function editDiscount() {
  return { type: types.EDIT_DISCOUNT };
}

export function cancelEditDiscount() {
  return { type: types.CANCEL_EDIT_DISCOUNT };
}

export function createReservation(payload) {
  return { type: types.CREATE_RESERVATION, payload };
}

export function createReservationSucceed(payload) {
  return { type: types.CREATE_RESERVATION_SUCCEED, payload };
}

export function createReservationFailed(payload) {
  return { type: types.CREATE_RESERVATION_FAILED, payload };
}

export function setDeliveryStepOne(payload) {
  return { type: types.SET_DELIVERY_STEP_ONE, payload };
}

export function setDeliveryStepTwo(payload) {
  return { type: types.SET_DELIVERY_STEP_TWO, payload };
}

export function setDeliveryStepThree(payload) {
  return { type: types.SET_DELIVERY_STEP_THREE, payload };
}

export function setDeliveryStep(payload) {
  return { type: types.SET_DELIVERY_STEP, payload };
}

export function confirmDelivery(payload) {
  return { type: types.CONFIRM_DELIVERY, payload };
}

export function finallyDelivery(payload) {
  return { type: types.FINALLY_DELIVERY, payload };
}

export function setChangeVehicle(payload) {
  return { type: types.SET_CHANGE_VEHICLE, payload };
}

export function setRefundStepOne(payload) {
  return { type: types.SET_REFUND_STEP_ONE, payload };
}

export function setRefundStepTwo(payload) {
  return { type: types.SET_REFUND_STEP_TWO, payload };
}

export function setRefundStepThree(payload) {
  return { type: types.SET_REFUND_STEP_THREE, payload };
}

export function setRefundStep(payload) {
  return { type: types.SET_REFUND_STEP, payload };
}

export function confirmRefund(payload) {
  return { type: types.CONFIRM_REFUND, payload };
}

export function finallyRefund(payload) {
  return { type: types.FINALLY_REFUND, payload };
}

export function getListOfReservations(payload) {
  return { type: types.GET_LIST_OF_RESERVATIONS_REQUESTED, payload };
}

export function getListOfReservationsSucceeded(payload) {
  return { type: types.GET_LIST_OF_RESERVATIONS_SUCCESSED, payload };
}

export function getListOfReservationsFailed(payload) {
  return { type: types.GET_LIST_OF_RESERVATIONS_FAILED, payload };
}

export function getListOfReservationsForExcel(payload) {
  return { type: types.GET_LIST_OF_RESERVATIONS_FOR_EXCEL, payload };
}

export function getListOfReservationsForExcelSucceeded(payload) {
  return { type: types.GET_LIST_OF_RESERVATIONS_FOR_EXCEL_SUCCEEDED, payload };
}

export function getListOfReservationsForExcelFailed(payload) {
  return { type: types.GET_LIST_OF_RESERVATIONS_FOR_EXCEL_FAILED, payload };
}

export function setListOfReservationsPage(payload) {
  return { type: types.SET_LIST_OF_RESERVATION_PAGE, payload };
}

export function setInitialParamsListOfReservations(payload) {
  return { type: types.SET_INITIAL_PARAMS_LIST_OF_RESERVATIONS, payload };
}

export function setParamsListOfReservations(payload) {
  return { type: types.SET_PARAMS_LIST_OF_RESERVATIONS, payload };
}

export function resetParamsListOfReservations(payload) {
  return { type: types.RESET_PARAMS_LIST_OF_RESERVATIONS, payload };
}

export function getReservationInfo(payload) {
  return { type: types.GET_RESERVATION_INFO, payload };
}

export function getReservationInfoSucceed(payload) {
  return { type: types.GET_RESERVATION_INFO_SUCCESSED, payload };
}

export function getReservationInfoFailed(payload) {
  return { type: types.GET_RESERVATION_INFO_FAILED, payload };
}

export function clearReservationInfo(payload = {}) {
  return { type: types.CLEAR_RESERVATION_INFO, payload };
}

export function editReservationInfo(payload = {}) {
  return { type: types.EDIT_RESERVATION_INFO, payload };
}

export function verifyCheckPoint(payload) {
  return { type: types.VERIFY_CHECK_POINT, payload };
}

export function verifyCheckPointSucceed(payload) {
  return { type: types.VERIFY_CHECK_POINT_SUCCESSED, payload };
}

export function verifyCheckPointFailed(payload) {
  return { type: types.VERIFY_CHECK_POINT_FAILED, payload };
}

export function setActualPosition(payload) {
  return { type: types.SET_ACTUAL_POSITION, payload };
}

export function verifyCompletedCheckList(payload) {
  return { type: types.VERIFY_COMPLETED_CHECK_LIST, payload };
}

export function verifyCompletedCheckListSucceed(payload) {
  return { type: types.VERIFY_COMPLETED_CHECK_LIST_SUCCEED, payload };
}

export function verifyCompletedCheckListFailed(payload) {
  return { type: types.VERIFY_COMPLETED_CHECK_LIST_FAILED, payload };
}

export function sendFormLinkFirm(payload) {
  return { type: types.SEND_FORM_LINK_FIRM, payload };
}

export function sendFormLinkFirmSucceed(payload) {
  return { type: types.SEND_FORM_LINK_FIRM_SUCCEED, payload };
}

export function sendFormLinkFirmFailed(payload) {
  return { type: types.SEND_FORM_LINK_FIRM_FAILED, payload };
}

export function cleanSendFormLinkFirm(payload) {
  return { type: types.CLEAN_SEND_FORM_LINK_FIRM, payload };
}

export function setTermsAndConditions(payload) {
  return { type: types.SET_TERMS_AND_CONDITIONS, payload };
}

export function setOPCustomerInfo(payload) {
  return { type: types.SET_OP_CUSTOMER_INFO, payload };
}

export function setLeaveStepOne(payload) {
  return { type: types.SET_LEAVE_STEP_ONE, payload };
}

export function setVehicleCategory(payload) {
  return { type: types.SET_VEHICLE_CATEGORY, payload };
}

export function setQuoteVehicleCategory(payload) {
  return { type: types.SET_QUOTE_VEHICLE_CATEGORY, payload };
}

export function setQuoteInternalError(payload) {
  return { type: types.SET_QUOTE_INTERNAL_ERROR, payload };
}

export function setNewInvoicePayment(payload) {
  return { type: types.SET_NEW_INVOICE_PAYMENT, payload };
}

export function savePayment(payload) {
  return { type: types.SAVE_PAYMENT, payload };
}

export function savePaymentSucceed(payload) {
  return { type: types.SAVE_PAYMENT_SUCCEED, payload };
}

export function savePaymentFailed(payload) {
  return { type: types.SAVE_PAYMENT_FAILED, payload };
}

export function setHasAddedBillingDetailsOfPayment(payload) {
  return { type: types.HAS_ADDED_BILLING_DETAILS_OF_PAYMENT, payload };
}

export function getVehicleLinePrices(payload) {
  return { type: types.GET_VEHICLE_LINE_PRICES, payload };
}

export function getVehicleLinePricesSucceed(payload) {
  return { type: types.GET_VEHICLE_LINE_PRICES_SUCCED, payload };
}

export function getVehicleLinePricesFailed(payload) {
  return { type: types.GET_VEHICLE_LINE_PRICES_FAILED, payload };
}

export function setCustomer(payload) {
  return { type: types.SET_CUSTOMER_VALUE, payload };
}

export function setCustomerNotPriceQuote(payload) {
  return { type: types.SET_CUSTOMER_VALUE_NO_PRICE_QUOTE, payload };
}

export function setVehicleReservation(payload) {
  return { type: types.SET_VEHICLE_OF_RESERVATION, payload };
}

export function setVehicleReservationSucceed(payload) {
  return { type: types.SET_VEHICLE_OF_RESERVATION_SUCCEED, payload };
}

export function setVehicleReservationFailed(payload) {
  return { type: types.SET_VEHICLE_OF_RESERVATION_FAILED, payload };
}

export function changeVehicleReservation(payload) {
  return { type: types.CHANGE_VEHICLE_OF_RESERVATION, payload };
}

export function changeVehicleReservationSucceed(payload) {
  return { type: types.CHANGE_VEHICLE_OF_RESERVATION_SUCCEED, payload };
}

export function changeVehicleReservationFailed(payload) {
  return { type: types.CHANGE_VEHICLE_OF_RESERVATION_FAILED, payload };
}

export function getCatalogueOfVehicles(payload) {
  return { type: types.GET_CATALOGUE_OF_VEHICLES, payload };
}

export function getCatalogueOfVehiclesSucceed(payload) {
  return { type: types.GET_CATALOGUE_OF_VEHICLES_SUCCEED, payload };
}

export function getCatalogueOfVehiclesFailed(payload) {
  return { type: types.GET_CATALOGUE_OF_VEHICLES_FAILED, payload };
}

export function clearCatalogueOfVehicle(payload) {
  return { type: types.CLEAR_CATALOGUE_OF_VEHICLES, payload };
}

export function setFiltersCatalogueOfVehicles(payload) {
  return { type: types.SET_FILTERS_CATALOGUE_OF_VEHICLES, payload };
}

export function setParamsOfVehiclesCatalogue(payload) {
  return { type: types.SET_PARAMS_OF_VEHICLES_CATALOGUE, payload };
}

export function createReservationContract(payload) {
  return { type: types.CREATE_RESERVATION_CONTRACT, payload };
}

export function createReservationContractSucceed(payload) {
  return { type: types.CREATE_RESERVATION_CONTRACT_SUCCEED, payload };
}

export function createReservationContractFailed(payload) {
  return { type: types.CREATE_RESERVATION_CONTRACT_FAILED, payload };
}

export function getContractsPDF() {
  return { type: types.GET_CONTRACTS_PDF };
}

export function getContractsPDFSucceed(payload) {
  return { type: types.GET_CONTRACTS_PDF_SUCCEED, payload };
}

export function getContractsPDFFailed(payload) {
  return { type: types.GET_CONTRACTS_PDF_FAILED, payload };
}

export function sendFormLink(payload) {
  return { type: types.SEND_FORM_REQ, payload };
}

export function sendFormLinkSucceed(payload) {
  return { type: types.SEND_FORM_REQ_SUCCEED, payload };
}

export function sendFormLinkFailed(payload) {
  return { type: types.SEND_FORM_REQ_FAILED, payload };
}

export function sendReservation(payload) {
  return { type: types.SEND_RESERVATION_REQ, payload };
}

export function sendReservationSucceed(payload) {
  return { type: types.SEND_RESERVATION_REQ_SUCCEED, payload };
}

export function sendReservationFailed(payload) {
  return { type: types.SEND_RESERVATION_REQ_FAILED, payload };
}

export function gotToContract() {
  return { type: types.GO_TO_CONTRACT };
}

export function verifySignaturesCompleted(payload) {
  return { type: types.VERIFY_FORM_COMPLETED, payload };
}

export function verifySignaturesCompletedSucceed(payload) {
  return { type: types.VERIFY_FORM_COMPLETED_SUCCEED, payload };
}

export function verifySignaturesCompletedFailed(payload) {
  return { type: types.VERIFY_FORM_COMPLETED_FAILED, payload };
}

export function acceptSignatures(payload) {
  return { type: types.ACCEPT_SIGNATURES, payload };
}

export function acceptSignaturesSucceed(payload) {
  return { type: types.ACCEPT_SIGNATURES_SUCCEED, payload };
}

export function acceptSignaturesFailed(payload) {
  return { type: types.ACCEPT_SIGNATURES_FAILED, payload };
}

export function rejectSignatures(payload) {
  return { type: types.REJECT_SIGNATURES, payload };
}

export function rejectSignaturesSucceed(payload) {
  return { type: types.REJECT_SIGNATURES_SUCCEED, payload };
}

export function rejectSignaturesFailed(payload) {
  return { type: types.REJECT_SIGNATURES_FAILED, payload };
}

export function tempAcceptSignatures() {
  return { type: types.TEMP_ACCEPT_SIGNATURES };
}

export function resetSignatures() {
  return { type: types.RESET_SIGNATURES };
}

export function rehydrateCustomerStep(payload) {
  return { type: types.REHYDRATE_CUSTOMER_STEP, payload };
}

export function rehydratePaymentStep(payload) {
  return { type: types.REHYDRATE_PAYMENT_STEP, payload };
}

export function getReservationForm(payload) {
  return { type: types.GET_RESERVATION_FORM, payload };
}

export function getReservationFormSucceed(payload) {
  return { type: types.GET_RESERVATION_FORM_SUCCEED, payload };
}

export function getReservationFormFailed(payload) {
  return { type: types.GET_RESERVATION_FORM_FAILED, payload };
}

export function getReservationListFilters(payload) {
  return { type: types.GET_RESERVATION_LIST_FILTERS, payload };
}

export function getReservationListFiltersSucceed(payload) {
  return { type: types.GET_RESERVATION_LIST_FILTERS_SUCCEED, payload };
}

export function getReservationListFiltersFailed(payload) {
  return { type: types.GET_RESERVATION_LIST_FILTERS_FAILED, payload };
}

export function setExtraCharges(payload) {
  return { type: types.SET_EXTRA_CHARGES, payload };
}

export function assignCustomer(payload) {
  return { type: types.ASSIGN_CUSTOMER, payload };
}

export function assignCustomerSucceed(payload) {
  return { type: types.ASSIGN_CUSTOMER_SUCCEED, payload };
}

export function assignCustomerFailed(payload) {
  return { type: types.ASSIGN_CUSTOMER_FAILED, payload };
}

export function hydrateChangeOfVehicleFlow(payload) {
  return { type: types.HYDRATE_CHANGE_OF_VEHICLE_FLOW, payload };
}

export function hydrateChangeOfVehicleFlowSucceed(payload) {
  return { type: types.HYDRATE_CHANGE_OF_VEHICLE_FLOW_SUCCESS, payload };
}

export function hydrateChangeOfVehicleFlowFailed(payload) {
  return { type: types.HYDRATE_CHANGE_OF_VEHICLE_FLOW_FAILED, payload };
}

export function getExtendedContracts(payload) {
  return { type: types.GET_EXTENDED_CONTRACTS, payload };
}

export function getExtendedContractsSuccess(payload) {
  return { type: types.GET_EXTENDED_CONTRACTS_SUCCESS, payload };
}

export function getExtendedContractsFailed(payload) {
  return { type: types.GET_EXTENDED_CONTRACTS_FAILED, payload };
}

export function setInProgressExtend(payload) {
  return { type: types.SET_IN_PROGRESS_EXTEND, payload };
}

export function setExtendContractVehicle(payload) {
  return { type: types.SET_EXTEND_CONTRACT_VEHICLE, payload };
}

export function createExtendContract(payload) {
  return { type: types.CREATE_EXTEND_CONTRACT, payload };
}

export function createExtendContractSuccessed(payload) {
  return { type: types.CREATE_EXTEND_CONTRACT_SUCCESSED, payload };
}

export function createExtendContractFailed(payload) {
  return { type: types.CREATE_EXTEND_CONTRACT_FAILED, payload };
}

export function hydrateExtendContractFlow(payload) {
  return { type: types.HYDRATE_EXTEND_CONTRACT_FLOW, payload };
}

export function hydrateExtendContractInitFlowSuccess(payload) {
  return { type: types.HYDRATE_EXTEND_CONTRACT_INIT_FLOW_SUCCESS, payload };
}

export function hydrateExtendContractFlowSuccess(payload) {
  return { type: types.HYDRATE_EXTEND_CONTRACT_FLOW_SUCCESS, payload };
}

export function hydrateExtendContractFlowFailed(payload) {
  return { type: types.HYDRATE_EXTEND_CONTRACT_FLOW_FAILED, payload };
}

export function finishExtendContract(payload) {
  return { type: types.FINISH_EXTENDED_CONTRACT, payload };
}

export function cancelReservation(payload) {
  return { type: types.CANCEL_RESERVATION, payload };
}

export function cancelReservationsSuccessed() {
  return { type: types.CANCEL_RESERVATION_SUCCESSED };
}

export function cancelReservationFailed(payload) {
  return { type: types.CANCEL_RESERVATION_FAILED, payload };
}

export function getCustomerCards(payload) {
  return { type: types.GET_CUSTOMER_CARDS, payload };
}

export function getCustomerCardsSuccessed(payload) {
  return { type: types.GET_CUSTOMER_CARDS_SUCCESSED, payload };
}

export function getCustomerCardsFailed() {
  return { type: types.GET_CUSTOMER_CARDS_FAILED };
}

export function updateReservation(payload) {
  return { type: types.UPDATE_RESERVATION, payload };
}

export function updateReservationSucceed(payload) {
  return { type: types.UPDATE_RESERVATION_SUCCEED, payload };
}

export function updateReservationFailed(payload) {
  return { type: types.UPDATE_RESERVATION_FAILED, payload };
}

export function resetResponseDialogUpdateReservation() {
  return { type: types.RESET_RESPONSE_DIALOG_UPDATE_RESERVATION };
}

export function setOpenCloseUpdateReservationModal(payload) {
  return { type: types.SET_OPEN_CLOSE_UPDATE_RESERVATION_MODAL, payload };
}

export function getDetailOfCustomerInUpdateModal(payload) {
  return { type: types.GET_DETAIL_OF_CUSTOMER_IN_UPDATE_MODAL, payload };
}

export function setReservationVehicleFailed(payload) {
  return { type: types.SET_RESERVATION_VEHICLE_FAILED, payload };
}

export function updateCard(payload) {
  return { type: types.UPDATE_CARD, payload };
}

export function updateCardSucceed() {
  return { type: types.UPDATE_CARD_SUCCEED };
}

export function updateCardFailed(payload) {
  return { type: types.UPDATE_CARD_FAILED, payload };
}

export function closeResponseDialogUpdateCard() {
  return { type: types.CLOSE_RESPONSE_DIALOG_UPDATE_CARD };
}
