import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DatePicker, DatePickerV2 } from '@casanova/casanova-common';
import { useFormikContext } from 'formik';
import { selectInstaller } from 'store/catalogs/actions';
import { MINIMUM_DATE, MAXIMUM_DATE } from '@vehicles/common/constants';
import _get from 'lodash/get';
import moment from 'moment';
import { SelectWithOptions } from '../../components';
import './index.scss';

const WrapperOrder = ({
  children,
  editable,
  isNew,
  folio,
  name,
  removeType,
  reinstallType,
}) => {
  const dispatch = useDispatch();
  const { setFieldValue, values } = useFormikContext();
  // console.log('🚀 ~ WrapperOrder ~ values', values);

  const handleSelectBrand = useCallback(
    (e) => {
      setFieldValue('installerExtra', undefined);
      dispatch(selectInstaller(e));
    },
    [dispatch, setFieldValue]
  );

  const names = removeType
    ? 'Fecha de RETIRO*'
    : reinstallType
    ? 'Fecha de reinstalación*'
    : 'Fecha de instalación*';

  // useEffect(() => {
  //   // console.log('🚀 ~ WrapperOrder ~ values', values);
  //   if (values?.orders?.[name]?.operationDate) {
  //     console.log(
  //       '🚀 ~ WrapperOrder ~ values?.orders?.[name]?.operationDate',
  //       values?.orders?.[name]?.operationDate
  //     );
  //     setFieldValue(
  //       `instalationDate`,
  //       // `orders.${name}.instalationDate`,
  //       // new Date(values?.orders?.[name]?.operationDate)
  //       values?.orders?.[name]?.operationDate
  //     );
  //   }

  //   if (values?.orders?.[name]?.gpsInstaller?.uuid) {
  //     setFieldValue('installer', values?.orders?.[name]?.gpsInstaller?.uuid);
  //   }
  // }, [values?.orders?.[name]?.operationDate, setFieldValue]);

  // console.log('🚀 ~ WrapperOrder ~ values', values);

  // console.log(`orders.${name}.instalationDate`);
  // console.log(`orders.${name}.gpsInstaller.uuid`);
  // console.log(
  //   `orders.${name}.insalationDate`,
  //   _get(values, `orders.${name}.instalationDate`)
  // );
  // console.log(
  //   `orders.${name}.gpsInstaller.uuid`,
  //   _get(values, `orders.${name}.gpsInstaller.uuid`)
  // );

  return (
    <div className={!editable ? 'wrapper mb-3' : ''}>
      <div
        className={`${
          editable ? 'd-none' : 'd-flex'
        } justify-content-between mb-4`}
      >
        <div className="my-auto">
          <p className="font-weight-bold n-orden">No. de orden {folio}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          {!editable ? (
            <div>
              <p className="col-form-label">{names}:</p>
              <p>
                {values.orders?.[name]?.operationDate
                  ? moment(values.orders[name].operationDate).format(
                      'dddd DD [de] MMMM [del] YYYY'
                    )
                  : ''}
              </p>
            </div>
          ) : (
            <DatePickerV2
              initialValue={
                !editable
                  ? values.orders?.[name]?.operationDate
                  : values.instalationDate
              }
              placeholder={names}
              name="instalationDate"
              label={names}
              minimumDate={MINIMUM_DATE}
              maximumDate={{
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                day: new Date().getDate(),
              }}
              // maximumDate={MAXIMUM_DATE}
              disabled={!editable && !isNew}
              formatDateValue
            />
          )}
        </div>
        <div className="col-12 col-md-4">
          <SelectWithOptions
            name={!editable ? `orders.${name}.gpsInstaller.uuid` : 'installer'}
            label="Instalador*"
            onChange={handleSelectBrand}
            options="catalogs.gpsInstallers"
            disabled={!editable && !isNew}
          />
        </div>
        {/* <div className="col-12 col-md-4">
          <SelectWithOptions
            name={
              !editable
                ? `orders.${name}.gpsInstallerExtra.uuid`
                : 'extraInstaller'
            }
            label="Instalador extra"
            options={
              !editable ? 'catalogs.gpsInstallers' : 'catalogs.installerExtra'
            }
            disabled={!editable && !isNew}
          />
        </div> */}
        {/* {(isNew || editable) && !removeType && (
          <div className="col-12 col-md-4">
            <SelectWithOptions
              name="icon"
              label="Ícono*"
              onChange={handleSelectBrand}
              options="catalogs.telematicIcon"
              disabled={!editable && !isNew}
            />
          </div>
        )} */}
        <div className="col-12 mt-2">{children}</div>
      </div>
    </div>
  );
};

export default WrapperOrder;
