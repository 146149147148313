import * as Yup from 'yup';
import moment from 'moment-timezone';
import 'moment/locale/es';
import _isEmpty from 'lodash/isEmpty';
import i18n from '@i18n';
import { existsChanges } from 'utils/validations/form';
import { CURP } from 'utils/constants/regularExpressions';
import { validityCardValidation } from 'utils/validations/cards';
import { getCurrentDateByTime } from 'utils/date';
import {
  REQUIRED_MESSAGE,
  FORMAT_TYPES_ALLOWED_MESSAGE,
  ALLOWED_FORMATS,
  EMAIL_NOT_VALID,
  YYYY_MM_DD,
  UPDATE_DOCUMENT_MESSAGE,
} from 'utils/constants';

export const shouldUpdateDocument = (value, fields, current, initials) => {
  const result = existsChanges(current, initials, fields);
  if (!result.withChanges) return true;
  return typeof value !== 'string';
};

export const fileTypeValidation = (file, allowedExtensions = []) => {
  if (!file || (file && !file.name)) return true;

  if (!allowedExtensions || allowedExtensions == null) return true;

  const matchedExtensions = allowedExtensions.filter((ext) => {
    const fileName = file.name.toLowerCase();
    const extension = ext.toLowerCase();
    return fileName.endsWith(extension);
  });

  return matchedExtensions.length > 0;
};

export const dateValidationGreat = Yup.string()
  .test('valid-date', i18n('ERROR__COMMONS__DATE__INVALID'), (value) => {
    if (!value) {
      return true;
    }
    const isValid = moment(value, YYYY_MM_DD).isValid();
    return isValid;
  })
  .test('max-date', 'La fecha no puede ser menor al día actual', (value) => {
    if (!value) {
      return true;
    }
    const date = moment(value, YYYY_MM_DD).toDate();
    const today = new Date();

    const cleanToday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    );

    return moment(date).isSameOrAfter(cleanToday);
  });

export const hourValidation = Yup.string()
  .min(8, i18n('ERROR__COMMONS__HOUR__INVALID'))
  .test('valid_hour', i18n('ERROR__COMMONS__HOUR__INVALID'), (value) => {
    if (!value || (value && value.length < 8)) return true;

    const splittedHour = value.split(':');
    const hour = parseInt(splittedHour[0], 10);
    const minutes = parseInt(splittedHour[1], 10);
    const seconds = parseInt(splittedHour[2], 10);

    const arePositive = hour >= 1 && minutes >= 0 && seconds >= 0;
    const lessThan60 = minutes < 60 && seconds < 60;
    return arePositive && lessThan60 && hour < 24;
  });

export const dateValidation = Yup.string()
  .test('valid-date', i18n('ERROR__COMMONS__DATE__INVALID'), (value) => {
    if (!value) {
      return true;
    }
    const isValid = moment(value, YYYY_MM_DD).isValid();
    return isValid;
  })
  .test('max-date', i18n('ERROR__COMMONS__DATE__MAX_TODAY'), (value) => {
    if (!value) {
      return true;
    }
    const date = moment(value, YYYY_MM_DD).toDate();
    return date <= new Date();
  });

export const minBetweenYearsValidation = dateValidation.test(
  'min-21-max-110',
  'La edad debe estar entre 21 y 110 años',
  (value) => {
    if (!value) {
      return true;
    }
    const date = moment(value, YYYY_MM_DD).toDate();
    const today = moment();
    const difference = today.diff(date, 'years', true);
    return difference >= 21 && difference <= 110;
  }
);

export const curpValidationTest = (schema) =>
  schema
    .test(
      'invalid-curp-format',
      i18n('ERROR__CUSTOMERS__CURP__WRONG__FORMAT'),
      (value) => {
        if (!value) return true;
        return CURP.test(value.toUpperCase());
      }
    )
    .min(18, i18n('ERROR__CUSTOMERS__CURP__MIN_LENGTH'));

export const curpProps = {
  curp: curpValidationTest(Yup.string().required(REQUIRED_MESSAGE)),
};

export const cardFormValidations = {
  cardType: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'card',
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: Yup.string(),
  }),
  bank: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'card',
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: Yup.string(),
  }),
  cardNumber: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'card',
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(15, i18n('ERROR__COMMONS__MIN_LENGTH', [15])),
    otherwise: Yup.string(),
  }),
  reference: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'card',
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(20, i18n('ERROR__COMMONS__MIN_LENGTH', [20])),
    otherwise: Yup.string(),
  }),
  authorization: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'card',
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(20, i18n('ERROR__COMMONS__MIN_LENGTH', [20])),
    otherwise: Yup.string(),
  }),
  movementDate: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'card',
    then: dateValidation
      .required(REQUIRED_MESSAGE)
      .test(
        'max-10-days-ago',
        i18n('ERROR__RESERVATIONS__MOVEMENT_DAY__TEN_DAYS'),
        (value) => {
          if (!value) {
            return true;
          }
          const date = moment(value, 'YYYY-MM-DD').toDate();
          const today = moment();
          const difference = today.diff(date, 'days', true);
          return difference <= 10;
        }
      ),
    otherwise: Yup.string(),
  }),
  movementHour: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'card',
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: Yup.string(),
  }),
  voucher: Yup.mixed().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'card',
    then: Yup.mixed()
      .required(REQUIRED_MESSAGE)
      .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
        fileTypeValidation(file, ALLOWED_FORMATS)
      ),
    otherwise: Yup.mixed(),
  }),
  accountStatus: Yup.mixed().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'card',
    then: Yup.mixed().when('hasHolderName', {
      is: false,
      then: Yup.mixed()
        .required(REQUIRED_MESSAGE)
        .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
          fileTypeValidation(file, ALLOWED_FORMATS)
        ),
      otherwise: Yup.mixed(),
    }),
    otherwise: Yup.mixed(),
  }),
};

export const bankFormValidations = {
  bankAuthorization: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'deposit',
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(20, i18n('ERROR__COMMONS__MIN_LENGTH', [20])),
    otherwise: Yup.string(),
  }),
  bankDate: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'deposit',
    then: dateValidation
      .required(REQUIRED_MESSAGE)
      .test(
        'max-10-days-ago',
        i18n('ERROR__RESERVATIONS__MOVEMENT_DAY__TEN_DAYS'),
        (value) => {
          if (!value) {
            return true;
          }
          const date = moment(value, 'YYYY-MM-DD').toDate();
          const today = moment();
          const difference = today.diff(date, 'deposit', true);
          return difference <= 10;
        }
      ),
    otherwise: Yup.string(),
  }),
  bankHour: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'deposit',
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: Yup.string(),
  }),
  bankVoucher: Yup.mixed().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'deposit',
    then: Yup.mixed()
      .required(REQUIRED_MESSAGE)
      .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
        fileTypeValidation(file, ALLOWED_FORMATS)
      ),
    otherwise: Yup.mixed(),
  }),
};

export const transferFormValidations = {
  bankTo: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'transfer',
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: Yup.string(),
  }),
  bankClave: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'transfer',
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(20, i18n('ERROR__COMMONS__MIN_LENGTH', [20])),
    otherwise: Yup.string(),
  }),
  bankReference: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'transfer',
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(20, i18n('ERROR__COMMONS__MIN_LENGTH', [20])),
    otherwise: Yup.string(),
  }),
  transferDate: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'transfer',
    then: dateValidation
      .required(REQUIRED_MESSAGE)
      .test(
        'max-10-days-ago',
        i18n('ERROR__RESERVATIONS__MOVEMENT_DAY__TEN_DAYS'),
        (value) => {
          if (!value) {
            return true;
          }
          const date = moment(value, 'YYYY-MM-DD').toDate();
          const today = moment();
          const difference = today.diff(date, 'days', true);
          return difference <= 10;
        }
      ),
    otherwise: Yup.string(),
  }),
  transferHour: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'transfer',
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: Yup.string(),
  }),
  transferVoucher: Yup.mixed().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'transfer',
    then: Yup.mixed()
      .required(REQUIRED_MESSAGE)
      .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
        fileTypeValidation(file, ALLOWED_FORMATS)
      ),
    otherwise: Yup.mixed(),
  }),
};

export const holdbackFormValidations = {
  holdbackCardHolder: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3])),
  holdbackBank: Yup.string().required(REQUIRED_MESSAGE),
  holdbackCard: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(13, i18n('ERROR__COMMONS__MIN_LENGTH', [13])),
  holdbackValidity: validityCardValidation.required(REQUIRED_MESSAGE),
  holdbackCode: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3])),
};

export const invoiceFormValidation = {
  invoiceSocialReason: Yup.string().when(['wantInvoice', 'isAdding'], {
    is: (wantInvoice, isAdding) => wantInvoice && isAdding,
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(5, i18n('ERROR__COMMONS__MIN_LENGTH', [5])),
    otherwise: Yup.string(),
  }),
  invoiceRfc: Yup.string().when(['wantInvoice', 'isAdding'], {
    is: (wantInvoice, isAdding) => wantInvoice && isAdding,
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(12, i18n('ERROR__COMMONS__MIN_LENGTH', [12])),
    otherwise: Yup.string(),
  }),
  invoiceCfdiUse: Yup.string().when(['wantInvoice', 'isAdding'], {
    is: (wantInvoice, isAdding) => wantInvoice && isAdding,
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: Yup.string(),
  }),
  emailInvoice: Yup.string().when('wantInvoice', {
    is: true,
    then: Yup.string().required(REQUIRED_MESSAGE).email(EMAIL_NOT_VALID),
    otherwise: Yup.string(),
  }),
};

export const notSunday = (date) => moment(date, YYYY_MM_DD).weekday() !== 6;

export const differentValue = (fieldSchema, keyOfFieldToEvalue, message) => {
  if (!keyOfFieldToEvalue) return fieldSchema;

  return fieldSchema.test('different-value', message, function (value) {
    if (!value) return true;
    const valueOfFieldToEvalue = this.parent[keyOfFieldToEvalue];
    return value !== valueOfFieldToEvalue;
  });
};

export const getIndexArrayOf = (path) => {
  const index = parseInt(path.split('[')[1].split(']')[0], 10);
  return index;
};

export const dynamicRequired = (params = {}) => {
  const {
    schema = Yup.string(),
    sectionKeys = null,
    message = REQUIRED_MESSAGE,
    parentFields = false,
  } = params;

  const schemaWithDinamycTest = schema.test(
    'dynamic-required',
    message,
    function (value) {
      const { initialValues } = this.options.context;
      const listOfFields = parentFields
        ? this.parent
        : sectionKeys || initialValues;

      const fields = Object.keys(listOfFields).filter(
        (key) => this.parent[key]
      );
      return !(fields.length > 0 && !value);
    }
  );
  return schemaWithDinamycTest;
};

export const validateSectionIfOneValue = dynamicRequired;

export const fileValidation = (params = {}) => {
  const { schema = Yup.mixed(), fields = [] } = params;
  return schema
    .test('should-update-document', UPDATE_DOCUMENT_MESSAGE, function (value) {
      const { initialValues } = this.options.context;
      if (!initialValues) return true;
      return shouldUpdateDocument(value, fields, this.parent, initialValues);
    })
    .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
      fileTypeValidation(file, ALLOWED_FORMATS)
    );
};

export const minHourInCurrentDay = (schema) =>
  schema.test(
    'limit-of-same-day',
    'La hora debe ser mayor a la actual como minimo 1 hora',
    (value) => {
      if (!value) return true;
      const selectedTime = getCurrentDateByTime(value);
      return selectedTime > moment().add(1, 'hour');
    }
  );

export const validateContactInfo = (availableUser, fieldKey) => {
  if (!availableUser || !availableUser.listOfFields) return true;
  if (
    !(
      availableUser &&
      availableUser.listOfFields &&
      availableUser.listOfFields[fieldKey]
    ) ||
    _isEmpty(availableUser.listOfFields[fieldKey].data) ||
    (!_isEmpty(availableUser.listOfFields[fieldKey].data) &&
      availableUser.listOfFields[fieldKey].data.success)
  )
    return true;

  return false;
};
