import * as Yup from 'yup';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import { VEHICLE_BUY_DATE_MESSAGE } from 'utils/constants';

moment.addRealMonth = function addRealMonth(d, months) {
  const fm = moment(d).add(months, 'M');
  const fmEnd = moment(fm).endOf('month');
  return d.date() !== fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD'))
    ? fm.add(1, 'd')
    : fm;
};

const messages = {
  required: 'Este campo es obligatorio',
};

const dateValidationKey = Yup.string()
  .test('valid-date', 'La fecha es invalida', (value) => {
    if (!value) {
      return true;
    }
    const isValid = moment(value, 'YYYY-MM-DD').isValid();
    return isValid;
  })
  .test(
    'max-date-today',
    'La fecha no puede ser mayor al día actual',
    (value) => {
      if (!value) {
        return true;
      }
      const date = moment(value, 'YYYY-MM-DD').toDate();
      return date <= new Date();
    }
  )
  .test('max-date', VEHICLE_BUY_DATE_MESSAGE, function (value) {
    if (!value) {
      return true;
    }
    const { buyDate } = this.options.context;
    const date = moment(value, 'YYYY-MM-DD').toDate();
    return date > moment(buyDate, 'YYYY-MM-DD').toDate();
  })
  .test('validation-lost-lostDate', messages.required, function () {
    const { elementIndex, lostDate } = this.parent;
    const { tabSelected, key, status } = this.options.context;
    if (elementIndex !== tabSelected) return true;
    if (key[elementIndex].keyStatus.uuid !== status.Extraviada) return true;
    if (!lostDate) return false;
    return true;
  });

const dateValidation = Yup.string()
  .test('valid-date', 'La fecha es invalida', (value) => {
    if (!value) {
      return true;
    }
    const isValid = moment(value, 'YYYY-MM-DD').isValid();
    return isValid;
  })
  .test('minor-than-vehicle-date', VEHICLE_BUY_DATE_MESSAGE, function (value) {
    if (!value || !this.options.context.vehicle) {
      return true;
    }
    const { buyDate } = this.options.context.vehicle;
    const date = moment(value, 'YYYY-MM-DD').toDate();
    const buyDateMoment = moment(buyDate, 'YYYY-MM-DD').toDate();
    return date >= buyDateMoment;
  })
  .test('max-date', 'La fecha no puede ser mayor al día actual', (value) => {
    if (!value) {
      return true;
    }
    const date = moment(value, 'YYYY-MM-DD').toDate();
    return date <= new Date();
  });

const dateValidationThreeMonths = Yup.string()
  .test('valid-date', 'La fecha es invalida', (value) => {
    if (!value) {
      return true;
    }
    return moment(value, 'YYYY-MM-DD').isValid();
  })
  .test(
    'at least 3 months',
    'La fecha tiene que ser mayor a 3 meses de la fecha actual',
    (value) => {
      if (!value) {
        return true;
      }
      const date = moment(value, 'YYYY-MM-DD').toDate();
      return date >= moment.addRealMonth(moment(), 3).toDate();
    }
  );
const folioValidation = Yup.string()
  .min(5, 'El número de folio no es válido')
  .max(30, 'El número de folio no es válido')
  .required(messages.required);

export const plate = Yup.object().shape({
  externalPlate: Yup.boolean().required(messages.required),
  plateNumber: Yup.string()
    .required('Ingresa el número de placa')
    .min(5, 'El número de placa no es válido')
    .max(10, 'El número de placa no es válido'),
  countryState: Yup.string().required('Selecciona un estado'),
  plateProceed: dateValidation.required('Ingresa la fecha de trámite'),
  serviceType: Yup.string().required('Selecciona un tipo de servicio'),
  price: Yup.number().when('externalPlate', {
    is: false,
    then: Yup.number()
      .min(0, 'El importe pagado no es válido')
      .max(99_999.99, 'El importe pagado no es válido')
      .required('Ingresa el importe pagado'),
    else: Yup.number().nullable(),
  }),
  receipt: Yup.mixed().when('externalPlate', {
    is: false,
    then: Yup.mixed().required('Adjunta el comprobante de pago (pdf)'),
    else: Yup.mixed().nullable(),
  }),
  remarks: Yup.string().nullable().max(200, 'El limite son 200 caracteres'),
});

const dateValidationGps = Yup.string()
  .required(messages.required)
  .test('valid-date', 'La fecha es invalida', (value) => {
    if (!value) {
      return true;
    }
    return moment(value, 'YYYY-MM-DD').isValid();
  })
  .test('date-invalid', VEHICLE_BUY_DATE_MESSAGE, function (value) {
    if (!value) {
      return true;
    }
    const { buyDate, modeRemove, removeType } = this.options.context;
    if (modeRemove || removeType) {
      return true;
    }
    const dateSelected = moment(value, 'YYYY-MM-DD').toDate();
    const date = moment(buyDate, 'YYYY-MM-DD').toDate();
    return dateSelected >= date;
  })
  .test(
    'date-invalid-removeType',
    'La fecha no puede ser mayor al día actual',
    function (value) {
      if (!value) {
        return true;
      }
      const { modeRemove, removeType } = this.options.context;
      const dateSelected = moment(value, 'YYYY-MM-DD').toDate();

      if (modeRemove || removeType) {
        return dateSelected <= new Date();
      }
      return true;
    }
  )
  .test(
    'date-invalid-removeType',
    'La fecha no puede ser menor a la fecha de operación',
    function (value) {
      if (!value) {
        return true;
      }
      const { modeRemove, removeType, selectGPS } = this.options.context;

      if (selectGPS.length === 0) {
        return true;
      }

      const dateSelected = moment(value, 'YYYY-MM-DD').toDate();
      const dateOperation = moment(
        selectGPS[0].dateOperation,
        'YYYY-MM-DD'
      ).toDate();
      if (!modeRemove || !removeType) {
        return true;
      }
      return dateSelected >= dateOperation;
    }
  )
  .test(
    'date-invalid-reinstallType',
    'La fecha no puede ser mayor al día actual',
    function (value) {
      if (!value) {
        return true;
      }
      const { reinstallType, modeReinstall } = this.options.context;
      const dateSelected = moment(value, 'YYYY-MM-DD').toDate();

      if (reinstallType || modeReinstall) {
        return dateSelected <= new Date();
      }
      return dateSelected <= new Date();
    }
  );

export const gps = Yup.object().shape({
  instalationDate: dateValidationGps,
  installer: Yup.string().required(messages.required),
  // icon: Yup.string().test('icon-when-add', messages.required, function (value) {
  //   const { modeRemove, modeReinstall } = this.options.context;
  //   if (modeRemove || modeReinstall) return true;
  //   return !_isEmpty(value);
  // }),
  gps: Yup.array().of(
    Yup.object().shape({
      // tagGPS: Yup.string().test(
      //   'required',
      //   messages.required,
      //   function (value) {
      //     const { modeRemove } = this.options.context;
      //     if (modeRemove) {
      //       return true;
      //     }

      //     return !_isEmpty(value);
      //   }
      // ),
      gpsTag: Yup.string().test(
        'required',
        messages.required,
        function (value) {
          const { modeRemove, modeReinstall } = this.options.context;
          if (modeRemove) {
            return true;
          }
          if (modeReinstall) {
            return true;
          }

          return !_isEmpty(value);
        }
      ),
      tagGPS_search: Yup.string().min(5, 'Ingresa minimo 5 caracteres'),
      // .test('required', messages.required, function (value) {
      //   const { modeRemove } = this.options.context;
      //   if (modeRemove) {
      //     return true;
      //   }

      //   return !_isEmpty(value);
      // }),
      installationPlace: Yup.string()
        // .min(6, 'Ingresa minimo 6 caracteres')
        .test('required', messages.required, function (value) {
          const { modeRemove } = this.options.context;
          if (modeRemove) {
            return true;
          }

          return !_isEmpty(value);
        }),
    })
  ),
});

export const keys = Yup.object().shape({
  key: Yup.array().of(
    Yup.object().shape({
      lost: Yup.object().shape({
        lostDate: dateValidationKey,
        lostDescription: Yup.string().test(
          'validation-lost-lostDescription',
          messages.required,
          function () {
            const { elementIndex, lostDescription } = this.parent;
            const { tabSelected, key, status } = this.options.context;
            if (elementIndex !== tabSelected) return true;
            if (key[elementIndex].keyStatus.uuid !== status.Extraviada)
              return true;
            if (!lostDescription) return false;
            return true;
          }
        ),
      }),
      sinister: Yup.object().shape({
        sinister: Yup.string().test(
          'validation-sinister-sinister',
          messages.required,
          function () {
            const { elementIndex, sinister } = this.parent;
            const { tabSelected, key, status } = this.options.context;
            if (elementIndex !== tabSelected) return true;
            if (key[elementIndex].keyStatus.uuid !== status.Siniestro)
              return true;
            if (!sinister) return false;
            return true;
          }
        ),
        sinisterResponsible: Yup.string().test(
          'validation-sinister-sinisterResponsible',
          messages.required,
          function () {
            const { elementIndex, sinisterResponsible } = this.parent;
            const { tabSelected, key, status } = this.options.context;
            if (elementIndex !== tabSelected) return true;
            if (key[elementIndex].keyStatus.uuid !== status.Siniestro)
              return true;
            if (!sinisterResponsible) return false;
            return true;
          }
        ),
      }),
      lend: Yup.object().shape({
        lendDate: Yup.string()
          .test('valid-date', 'La fecha es invalida', (value) => {
            if (!value) {
              return true;
            }
            const isValid = moment(value, 'YYYY-MM-DD').isValid();
            return isValid;
          })
          .test('validation-lend-lendDate', messages.required, function () {
            const { elementIndex, lendDate } = this.parent;
            const { tabSelected, key, status } = this.options.context;
            if (elementIndex !== tabSelected) return true;
            if (key[elementIndex].keyStatus.uuid !== status.Préstamo)
              return true;
            if (!lendDate) return false;
            return true;
          })
          .test(
            'max-date-today',
            'La fecha no puede ser mayor al día actual',
            (value) => {
              if (!value) {
                return true;
              }
              const date = moment(value, 'YYYY-MM-DD').toDate();
              return date <= new Date();
            }
          )
          .test(
            'max-date-2',
            'La fecha no puede ser menor al día de compra del vehiculo',
            function (value) {
              if (!value) {
                return true;
              }
              const { buyDate } = this.options.context;
              const date = moment(value, 'YYYY-MM-DD').toDate();
              return date >= moment(buyDate, 'YYYY-MM-DD').toDate();
            }
          ),
        lendResponsible: Yup.string().test(
          'validation-lend-lendResponsible',
          messages.required,
          function () {
            const { elementIndex, lendResponsible } = this.parent;
            const { tabSelected, key, status } = this.options.context;
            if (elementIndex !== tabSelected) return true;
            if (key[elementIndex].keyStatus.uuid !== status.Préstamo)
              return true;
            if (!lendResponsible) return false;
            return true;
          }
        ),
        lendBranch: Yup.string().test(
          'validation-lend-lendBranch',
          messages.required,
          function () {
            const { elementIndex, lendBranch } = this.parent;
            const { tabSelected, key, status } = this.options.context;
            if (elementIndex !== tabSelected) return true;
            if (key[elementIndex].keyStatus.uuid !== status.Préstamo)
              return true;
            if (!lendBranch) return false;
            return true;
          }
        ),
        branch: Yup.string().test(
          'validation-lend-branch',
          messages.required,
          function () {
            const { elementIndex, branch, lendBranch } = this.parent;
            const {
              tabSelected,
              key,
              status,
              lendBranchs,
            } = this.options.context;
            if (elementIndex !== tabSelected) return true;
            if (lendBranch !== lendBranchs.Otro) return true;
            if (key[elementIndex].keyStatus.uuid !== status.Préstamo)
              return true;
            if (!branch) return false;
            return true;
          }
        ),
        // lendReason: Yup.string().test(
        //   "validation-lend-lendReason",
        //   messages.required,
        //   function () {
        //     const { elementIndex, lendReason } = this.parent
        //     const { tabSelected, key, status } = this.options.context
        //     if (elementIndex !== tabSelected) return true;
        //     if (key[elementIndex].keyStatus.uuid !== status.Préstamo) return true;
        //     if (!lendReason) return false
        //     return true
        //   }
        // ),
      }),
    })
  ),
});

export const verification = Yup.object().shape({
  dateProcess: dateValidation.required(messages.required),
  nextProcess: dateValidationThreeMonths.required(messages.required),
  verificationType: Yup.string().required(messages.required),
  amountFine: Yup.string()
    .min(5, 'La multa y monto no es válida')
    .max(200, 'La multa y monto no es válida')
    .required(messages.required),
  amountPaid: Yup.number()
    .min(0, 'El importe pagado no es válido')
    .max(99_999.99, 'El importe pagado no es válido')
    .required(messages.required),
  plate: Yup.string().required(messages.required),
  hologram: Yup.string().required(messages.required),
  folio: folioValidation,
  verificationReceipt: Yup.mixed().required(messages.required),
  certificate: Yup.mixed().required(messages.required),
});

const roadTaxMessages = {
  folio: 'El número de folio no es válido',
  price: 'El importe pagado no es válido',
};
export const roadTax = Yup.object().shape({
  plate: Yup.string().required(messages.required),
  fiscalYear: Yup.string().required(messages.required),
  folio: Yup.string()
    .required(messages.required)
    .min(5, roadTaxMessages.folio)
    .max(30, roadTaxMessages.folio),
  price: Yup.number()
    .min(0, roadTaxMessages.price)
    .max(99_999.99, roadTaxMessages.price)
    .required(messages.required),
  roadTaxReceipt: Yup.mixed().required('Adjunta el comprobante de pago (pdf)'),
});

const circulationCardMessages = {
  folio: 'El número de folio no es válido',
  price: 'El precio no es válido',
};

export const circulationCard = Yup.object().shape({
  plate: Yup.string().required(messages.required),
  cardClass: Yup.string().required(messages.required),
  type: Yup.string().required(messages.required),
  folio: Yup.string()
    .required(messages.required)
    .min(5, circulationCardMessages.folio)
    .max(30, circulationCardMessages.folio),
  issueDate: dateValidation.required(messages.required),
  validity: Yup.string().required(messages.required),
  price: Yup.number()
    .min(0, circulationCardMessages.price)
    .max(99_999.99, circulationCardMessages.price)
    .required(messages.required),
  circulationCardReceipt: Yup.mixed().required(messages.required),
  card: Yup.mixed().required(messages.required),
  certifiedCopy: Yup.boolean().required(messages.required),
  certifiedCopy1Folio: Yup.string().when('certifiedCopy', {
    is: true,
    then: Yup.string().required(messages.required),
    otherwise: Yup.string().nullable(),
  }),
  certifiedCopy2Folio: Yup.string().when('certifiedCopy', {
    is: true,
    then: Yup.string().required(messages.required),
    otherwise: Yup.string().nullable(),
  }),
  certifiedCopyDate: Yup.string().when('certifiedCopy', {
    is: true,
    then: dateValidation.required(messages.required),
    otherwise: Yup.string().nullable(),
  }),
  lost: Yup.boolean().required(messages.required),
  dateLost: Yup.string().when('lost', {
    is: true,
    then: dateValidation.required(messages.required),
    otherwise: Yup.string().nullable(),
  }),
});

const fineMessages = {
  folio: 'El número de folio no es válido',
  amountPaid: 'El importe es inválido',
  description: 'La descripción no es válida',
};

export const fineValidation = Yup.object().shape({
  finePlate: Yup.string().required(messages.required),
  fineFolio: Yup.string()
    .required(messages.required)
    .min(5, fineMessages.folio)
    .max(30, fineMessages.folio),
  fineDate: dateValidation.required(messages.required),
  amountPaidFine: Yup.number().when('paid', {
    is: true,
    then: Yup.number()
      .required(messages.required)
      .min(0, fineMessages.amountPaid)
      .max(99_999.99, fineMessages.amountPaid),
    otherwise: Yup.number().nullable(),
  }),
  fineReceipt: Yup.mixed().when('paid', {
    is: true,
    then: Yup.mixed().required(messages.required),
    otherwise: Yup.mixed().nullable(),
  }),
  sanction: Yup.string().required(messages.required),
  reason: Yup.string()
    .required(messages.required)
    .min(5, fineMessages.number)
    .max(200, fineMessages.description),
});

const policyMessages = {
  number: 'El número de póliza no es válido',
  totalBonus: 'La prima total no es válida',
  pdfType: 'El tipo de archivo debe ser pdf',
  validityEndGreatThanStart: 'El fin de la vigencia debe ser mayor al inicio',
  validityStartGreatThanEmission:
    'El inicio de la vigencia debe ser mayor o igual a la fecha de emisión',
  emissionEqualToStart:
    'La fecha emisión no puede ser diferente a Vigencia desde',
};
export const policyValidation = Yup.object().shape({
  insurance: Yup.string().required(messages.required),
  policyNumber: Yup.string()
    .required(messages.required)
    .min(5, policyMessages.number),
  endorsement: Yup.string().required(messages.required),
  topic: Yup.string().required(messages.required),
  validityStart: dateValidation
    .required(messages.required)
    .test(
      'greater-or-equal-than-emission',
      policyMessages.validityStartGreatThanEmission,
      function (value) {
        const { emissionDate } = this.parent;
        if (!emissionDate) {
          return true;
        }

        return value >= emissionDate;
      }
    ),
  validityEnd: Yup.string().required(messages.required),
  emissionDate: dateValidation.required(messages.required),
  policyUse: Yup.string().required(messages.required),
  totalBonus: Yup.number()
    .required(messages.required)
    .min(0, policyMessages.totalBonus)
    .max(99_999.99, policyMessages.totalBonus),
  policyReceipt: Yup.mixed().required(messages.required),
  policy: Yup.mixed().required(messages.required),
});

const sinisterMessages = {
  number: 'El número de siniestro no es válido',
  description: 'La descripción no es válida',
};
export const sinisterValidation = Yup.object().shape({
  sinisterNumber: Yup.string()
    .required(messages.required)
    .min(5, sinisterMessages.number),
  policyNumber: Yup.string().required(messages.required),
  sinisterDate: dateValidation.required(messages.required),
  sinisterType: Yup.string().required(messages.required),
  sinisterStatus: Yup.string().required(messages.required),
  description: Yup.string()
    .required(messages.required)
    .min(5, sinisterMessages.number)
    .max(200, sinisterMessages.description),
});
