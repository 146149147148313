import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import store from 'store';
import i18n from '@i18n';
import { getExtraChargesTransformer } from '@reservations/commons/transformers';

export const getVerificationCheckListDeliveryReturn = ({
  reservationInfo = {},
  checkListType = '',
  completeReservation = false,
}) => {
  const reservationData = completeReservation
    ? reservationInfo
    : reservationInfo?.data;

  if (_isEmpty(reservationData)) return {};

  const { verificationChecklists = [] } = reservationData;

  const findCheckList = ({ uuid, type, createdDate }) => {
    const hasSame = type === checkListType;

    const isLastDate =
      verificationChecklists.filter((checkList) => {
        const isBefore = moment(checkList.createdDate).isAfter(createdDate);
        return checkList.type === type && uuid !== checkList.uuid && isBefore;
      }).length === 0;

    return Boolean(hasSame && isLastDate);
  };

  const checkList = verificationChecklists.find(findCheckList) || {};
  return checkList;
};

export const getVerificationCheckListsDeliveryReturn = ({
  reservationInfo = {},
  completeReservation = false,
}) => {
  const reservationData = completeReservation
    ? reservationInfo
    : reservationInfo?.data;

  if (_isEmpty(reservationData)) return {};

  const { verificationChecklists = [] } = reservationData;

  return verificationChecklists;
};

export const getFolioMessage = ({ folio = '' }) => {
  const folioMessage = `Folio ${folio}`;
  return folioMessage;
};

export const isRejected = (history) => {
  const params = history.location.search;
  const rejected = params.includes('rejected');
  return rejected;
};

// TODO:  wait for octavio changes
export const getDiscount = (quote) => {
  const { managerDiscount, total } = quote;
  if (!managerDiscount)
    return {
      isPromocode: false,
    };
  let discountAmount = 0;

  if (managerDiscount.managerDiscountType.symbol === '%') {
    discountAmount =
      (total / (100 - managerDiscount.amount)) * managerDiscount.amount;
  } else {
    discountAmount = managerDiscount.amount;
  }

  return {
    units: 1,
    openForm: false,
    submiting: false,
    finished: true,
    success: true,
    discountAmount,
    total,
    isPromocode: true,
  };
};

export const getTotalPriceExtraCharges = ({ listOfExtraCharges = [] }) => {
  const listOfPrices = listOfExtraCharges.map(({ price }) => price);
  let total = 0;

  listOfPrices.forEach((price) => {
    total += price;
  });

  return total;
};

export const getPaymentMethod = ({
  paymentInfo,
  catalogsBankCardTypes = [],
}) => {
  if (paymentInfo) {
    const { method = '', bankCardType = '' } = paymentInfo;

    let paymentName = i18n(`COMMONS__PAYMENT_METHOD__${method}`);

    if (method === 'CARD' && catalogsBankCardTypes.length > 0) {
      const fullCardMethod = catalogsBankCardTypes.find(
        (type) => type.uuid === bankCardType
      );
      paymentName = fullCardMethod
        ? `${paymentName} de ${fullCardMethod.name}`
        : paymentName;
    }

    return paymentName || paymentInfo.type;
  }
  return '';
};

export const isGeneralGroup = ({
  position = 0,
  type = 'DEFAULT',
  additionalFields = {},
}) => {
  if (type === 'DEFAULT') {
    const isGeneral = position === 1;
    return isGeneral;
  }
  const isGeneral = Boolean(additionalFields.circulationCardCopy);
  return isGeneral;
};

const getField = (fieldKey, fields) => {
  const fieldValue = fields[fieldKey] || {};
  return { fieldValue, fieldKey };
};

export const getPrincipalFieldsGeneral = (
  additionalFields = {},
  vehicleFirst = false
) =>
  Object.keys(additionalFields)
    .sort((element, element2) => {
      if (vehicleFirst) {
        return element === 'vehicle'
          ? -1
          : element2 === 'vehicle'
          ? 1
          : element.localeCompare(element2);
      }
      return 1;
    })
    .filter(
      (fieldKey) =>
        fieldKey !== 'circulationCardCopy' &&
        fieldKey !== 'circulationCardOriginal'
    )
    .map((fieldKey) => getField(fieldKey, additionalFields));

export const getCirculationFieldsGeneral = (additionalFields = {}) =>
  Object.entries(additionalFields)
    .filter(
      ([fieldKey]) =>
        fieldKey === 'circulationCardCopy' ||
        fieldKey === 'circulationCardOriginal'
    )
    .map(([fieldKey, fieldValue]) => ({
      fieldValue,
      fieldKey,
    }));

export const isPassedGeneralGroup = ({
  remarks = '',
  infoSet = false,
  extraCharges = [],
}) => infoSet && !remarks && extraCharges.length === 0;

export const makeRemarkName = ({ uuid }) => `remark_${uuid}`;

export const isChangeOfVehicleFlow = () => {
  const isValidFromStore = _get(
    store?.getState(),
    'reservations.changeVehicle',
    false
  );
  const isValidFromUrl = window.location.search.includes('change_vehicle=true');
  return isValidFromStore || isValidFromUrl;
};

export const isExtendOfContractFlow = () => {
  const isValidFromStore = _get(
    store?.getState(),
    'reservations.extendContract',
    false
  );
  const isValidFromUrl = window.location.search.includes('extend=true');
  return isValidFromStore || isValidFromUrl;
};

export const getExtraChargesByCheckListType = (
  type = '',
  reservationInfo = {}
) => {
  const verificationCheckList = getVerificationCheckListDeliveryReturn({
    reservationInfo,
    checkListType: type,
    completeReservation: true,
  });

  const { verificationGroups = [] } = verificationCheckList;
  const listOfExtraCharges = getExtraChargesTransformer(verificationGroups);
  const totalExtraChargesReturn = getTotalPriceExtraCharges({
    listOfExtraCharges,
  });

  return {
    listOfExtraCharges,
    totalPrice: totalExtraChargesReturn,
  };
};
