import { number2mxn } from 'utils/currency';
import { MOVEMENTS_TYPES } from '@customers/common/constants';

export function statementListTransformer(statementList) {
  return statementList.map(
    ({ id, uuid, movementType, amount, concept, branch, applicationDate }) => {
      const charge =
        movementType.identifier === MOVEMENTS_TYPES.CHARGE
          ? number2mxn(amount)
          : number2mxn(0);
      const payment =
        movementType.identifier === MOVEMENTS_TYPES.PAYMENT ||
        movementType.identifier === MOVEMENTS_TYPES.BONUS
          ? number2mxn(amount)
          : number2mxn(0);

      return {
        id: id.toString(),
        uuid,
        movementId: id.toString(),
        movementType: movementType.name,
        concept,
        branch,
        charge: charge.toString(),
        payment: payment.toString(),
        date: applicationDate,
      };
    }
  );
}

export function getTotal(statementList) {
  const [totalCharge, totalPayment] = statementList
    .map(({ amount, movementType }) => {
      const charge =
        movementType.identifier === MOVEMENTS_TYPES.CHARGE ? amount : 0;
      const payment =
        movementType.identifier === MOVEMENTS_TYPES.PAYMENT ||
        movementType.identifier === MOVEMENTS_TYPES.BONUS
          ? amount
          : 0;
      return { charge, payment };
    })
    .reduce(
      (acc, { charge, payment }) => {
        acc[0] += charge;
        acc[1] += payment;
        return acc;
      },
      [0, 0]
    );

  return totalPayment - totalCharge;
}
