const SET_STEP = 'reservations/SET_QUOTE_STEP';
const SET_TITLES = 'reservations/SET_QUOTE_TITLES';
const SET_START_HOURS = 'reservations/SET_START_HOURS';
const SET_START_LIMIT_HOURS = 'reservations/SET_START_LIMIT_HOURS';
const SET_END_HOURS = 'reservations/SET_END_HOURS';
const SET_END_LIMIT_HOURS = 'reservations/SET_END_LIMIT_HOURS';
const SELECT_VEHICLE_CATEGORY = 'reservations/SELECT_VEHICLE_CATEGORY';
const GET_VEHICLE_TYPES = 'reservations/GET_VEHICLE_TYPES';
const GET_VEHICLE_TYPES_SUCCEED = 'reservations/GET_VEHICLE_TYPES_SUCCEED';
const GET_VEHICLE_TYPES_FAILED = 'reservations/GET_VEHICLE_TYPES_FAILED';

const GET_MOST_QUOTED = 'reservations/GET_MOST_QUOTED';
const GET_MOST_QUOTED_SUCCEED = 'reservations/GET_MOST_QUOTED_SUCCEED';
const GET_MOST_QUOTED_FAILED = 'reservations/GET_MOST_QUOTED_FAILED';

const GET_TIME_LIMITS = 'reservations/GET_TIME_LIMITS';
const GET_TIME_LIMITS_SUCCEED = 'reservations/GET_TIME_LIMITS_SUCCEED';
const GET_TIME_LIMITS_FAILED = 'reservations/GET_TIME_LIMITS_FAILED';

const GET_VEHICLES = 'reservations/GET_VEHICLES';
const GET_VEHICLES_SUCCEED = 'reservations/GET_VEHICLES_SUCCEED';
const GET_VEHICLES_FAILED = 'reservations/GET_VEHICLES_FAILED';

const SAVE_QUOTE_STEP_ONE = 'reservations/SAVE_QUOTE_STEP_ONE';
const SAVE_QUOTE_STEP_ONE_SUCCEED = 'reservations/SAVE_QUOTE_STEP_ONE_SUCCEED';
const SAVE_QUOTE_STEP_ONE_FAILED = 'reservations/SAVE_QUOTE_STEP_ONE_FAILED';

const SAVE_QUOTE_STEP_TWO = 'reservations/SAVE_QUOTE_STEP_TWO';
const SAVE_QUOTE_STEP_TWO_SUCCEED = 'reservations/SAVE_QUOTE_STEP_TWO_SUCCEED';
const SAVE_QUOTE_STEP_TWO_FAILED = 'reservations/SAVE_QUOTE_STEP_TWO_FAILED';

const EDIT_QUOTE_STEP_ONE = 'reservations/EDIT_QUOTE_STEP_ONE';
const EDIT_QUOTE_STEP_ONE_SUCCEED = 'reservations/EDIT_QUOTE_STEP_ONE_SUCCEED';
const EDIT_QUOTE_STEP_ONE_FAILED = 'reservations/EDIT_QUOTE_STEP_ONE_FAILED';

const SET_IN_PROGRESS_QUOTE = 'reservations/SET_IN_PROGRESS_QUOTE';

const SAVE_NOT_PRICE_QUOTE = 'reservations/SAVE_NOT_PRICE_QUOTE';
const SAVE_NOT_PRICE_QUOTE_SUCCEED =
  'reservations/SAVE_NOT_PRICE_QUOTE_SUCCEED';
const SAVE_NOT_PRICE_QUOTE_FAILED = 'reservations/SAVE_NOT_PRICE_QUOTE_FAILED';

const CLOSE_RESPONSE_DIALOG = 'reservations/CLOSE_RESPONSE_DIALOG';
const CLOSE_RESPONSE_DIALOG_S = 'reservations/CLOSE_RESPONSE_DIALOG_S';
const SET_RESPONSE_DIALOG = 'reservations/SET_RESPONSE_DIALOG';

const SET_CONTRACT_QUOTE = 'reservations/SET_CONTRACT_QUOTE';
const SET_QUOTE_VALUES = 'reservations/SET_QUOTE_VALUES';

const OPEN_SET_QUOTE_DIALOG = 'reservations/OPEN_SET_QUOTE_DIALOG';
const CLOSE_SET_QUOTE_DIALOG = 'reservations/CLOSE_SET_QUOTE_DIALOG';

const OPEN_SET_RESERVATION_DIALOG = 'reservations/OPEN_SET_RESERVATION_DIALOG';
const CLOSE_SET_RESERVATION_DIALOG =
  'reservations/CLOSE_SET_RESERVATION_DIALOG';

const OPEN_EDIT_SCHEDULE_DATES = 'reservations/OPEN_EDIT_SCHEDULE_DATES';
const CLOSE_EDIT_SCHEDULE_DATES = 'reservations/CLOSE_EDIT_SCHEDULE_DATES';

const SET_PARAMS = 'reservations/SET_PARAMS';

const SET_SELECTED_CATEGORY = 'reservations/SET_SELECTED_CATEGORY';
const SET_PREVIOUS_SELECTED_CATEGORY =
  'reservations/SET_PREVIOUS_SELECTED_CATEGORY';
const SET_BRANDS = 'reservations/SET_BRANDS';

const GET_DISCOUNTS = 'reservations/GET_DISCOUNTS';
const GET_DISCOUNTS_SUCCEED = 'reservations/GET_DISCOUNTS_SUCCEED';
const GET_DISCOUNTS_FAILED = 'reservations/GET_DISCOUNTS_FAILED';

const APPLY_BRANCH_DISCOUNT = 'reservations/APPLY_BRANCH_DISCOUNT';
const CANCEL_BRANCH_DISCOUNT = 'reservations/CANCEL_BRANCH_DISCOUNT';

const SAVE_DISCOUNT = 'reservations/SAVE_DISCOUNT';
const SAVE_DISCOUNT_SUCCEED = 'reservations/SAVE_DISCOUNT_SUCCEED';
const SAVE_DISCOUNT_FAILED = 'reservations/SAVE_DISCOUNT_FAILED';

const VALIDATE_PROMOCODE = 'reservations/VALIDATE_PROMOCODE';
const VALIDATE_PROMOCODE_SUCCEED = 'reservations/VALIDATE_PROMOCODE_SUCCEED';
const VALIDATE_PROMOCODE_FAILED = 'reservations/VALIDATE_PROMOCODE_FAILED';

const SAVE_QUOTE_STEP_THREE = 'reservations/SAVE_QUOTE_STEP_THREE';
const SAVE_QUOTE_STEP_THREE_SUCCEED =
  'reservations/SAVE_QUOTE_STEP_THREE_SUCCEED';
const SAVE_QUOTE_STEP_THREE_FAILED =
  'reservations/SAVE_QUOTE_STEP_THREE_FAILED';

const EDIT_PLAN = 'reservations/EDIT_PLAN';
const EDIT_DISCOUNT = 'reservations/EDIT_DISCOUNT';
const CANCEL_EDIT_DISCOUNT = 'reservations/CANCEL_EDIT_DISCOUNT';

const RESET = 'reservations/RESET';

const CREATE_RESERVATION = 'reservations/CREATE_RESERVATION';
const CREATE_RESERVATION_SUCCEED = 'reservations/CREATE_RESERVATION_SUCCEED';
const CREATE_RESERVATION_FAILED = 'reservations/CREATE_RESERVATION_FAILED';

const SET_DELIVERY_STEP_ONE = 'reservations/SET_DELIVERY_STEP_ONE';
const SET_DELIVERY_STEP_TWO = 'reservations/SET_DELIVERY_STEP_TWO';
const SET_DELIVERY_STEP_THREE = 'reservations/SET_DELIVERY_STEP_THREE';
const SET_DELIVERY_STEP = 'reservations/SET_DELIVERY_STEP';
const CONFIRM_DELIVERY = 'reservations/CONFIRM_DELIVERY';
const FINALLY_DELIVERY = 'reservations/FINALLY_DELIVERY';

const SET_CHANGE_VEHICLE = 'reservations/SET_CHANGE_VEHICLE';

const SET_REFUND_STEP_ONE = 'reservations/SET_REFUND_STEP_ONE';
const SET_REFUND_STEP_TWO = 'reservations/SET_REFUND_STEP_TWO';
const SET_REFUND_STEP_THREE = 'reservations/SET_REFUND_STEP_THREE';
const SET_REFUND_STEP = 'reservations/SET_REFUND_STEP';
const CONFIRM_REFUND = 'reservations/CONFIRM_REFUND';
const FINALLY_REFUND = 'reservations/FINALLY_REFUND';

export const GET_LIST_OF_RESERVATIONS_REQUESTED =
  'reservations/GET_LIST_OF_RESERVATIONS_REQUESTED';
export const GET_LIST_OF_RESERVATIONS_SUCCESSED =
  'reservations/GET_LIST_OF_RESERVATIONS_SUCCESSED';
export const GET_LIST_OF_RESERVATIONS_FAILED =
  'reservations/GET_LIST_OF_RESERVATIONS_FAILED';
export const SET_LIST_OF_RESERVATION_PAGE =
  'reservations/SET_LIST_OF_RESERVATION_PAGE';
export const SET_INITIAL_PARAMS_LIST_OF_RESERVATIONS =
  'reservations/SET_INITIAL_PARAMS_LIST_OF_RESERVATIONS';
export const SET_PARAMS_LIST_OF_RESERVATIONS =
  'reservations/SET_PARAMS_LIST_OF_RESERVATIONS';
export const RESET_PARAMS_LIST_OF_RESERVATIONS =
  'reservations/RESET_PARAMS_LIST_OF_RESERVATIONS';

export const GET_LIST_OF_RESERVATIONS_FOR_EXCEL =
  'reservations/GET_LIST_OF_RESERVATIONS_FOR_EXCEL';
export const GET_LIST_OF_RESERVATIONS_FOR_EXCEL_SUCCEEDED =
  'reservations/GET_LIST_OF_RESERVATIONS_FOR_EXCEL_SUCCEEDED';
export const GET_LIST_OF_RESERVATIONS_FOR_EXCEL_FAILED =
  'reservations/GET_LIST_OF_RESERVATIONS_FOR_EXCEL_FAILED';

const GET_RESERVATION_INFO = 'reservations/GET_RESERVATIONS_INFO';
const GET_RESERVATION_INFO_SUCCESSED =
  'reservations/GET_RESERVATIONS_INFO_SUCCESSED';
const GET_RESERVATION_INFO_FAILED = 'reservations/GET_RESERVATIONS_INFO_FAILED';
const CLEAR_RESERVATION_INFO = 'reservations/CLEAR_RESERVATION_INFO';
const CREATE_EXTEND_CONTRACT = 'reservations/CREATE_EXTEND_CONTRACT';
const CREATE_EXTEND_CONTRACT_SUCCESSED =
  'reservations/CREATE_EXTEND_CONTRACT_SUCCESSED';
const CREATE_EXTEND_CONTRACT_FAILED =
  'reservations/CREATE_EXTEND_CONTRACT_FAILED';
const EDIT_RESERVATION_INFO = 'reservations/EDIT_RESERVATION_INFO';

const VERIFY_CHECK_POINT = 'reservations/VERIFY_CHECK_POINT';
const VERIFY_CHECK_POINT_SUCCESSED =
  'reservations/VERIFY_CHECK_POINT_SUCCESSED';
const VERIFY_CHECK_POINT_FAILED = 'reservations/VERIFY_CHECK_POINT_FAILED';
const SET_ACTUAL_POSITION = 'reservations/SET_ACTUAL_POSITION';

const VERIFY_COMPLETED_CHECK_LIST = 'reservations/VERIFY_COMPLETED_CHECK_LIST';
const VERIFY_COMPLETED_CHECK_LIST_SUCCEED =
  'reservations/VERIFY_COMPLETED_CHECK_LIST_SUCCEED';
const VERIFY_COMPLETED_CHECK_LIST_FAILED =
  'reservations/VERIFY_COMPLETED_CHECK_LIST_FAILED';

const SEND_FORM_LINK_FIRM = 'reservations/SEND_FORM_LINK_FIRM';
const SEND_FORM_LINK_FIRM_SUCCEED = 'reservations/SEND_FORM_LINK_FIRM_SUCCEED';
const SEND_FORM_LINK_FIRM_FAILED = 'reservations/SEND_FORM_LINK_FIRM_FAILED';
const CLEAN_SEND_FORM_LINK_FIRM = 'reservations/CLEAN_SEND_FORM_LINK_FIRM';

const SET_TERMS_AND_CONDITIONS = 'reservations/SET_TERMS_AND_CONDITIONS';

const SET_OP_CUSTOMER_INFO = 'reservations/SET_OP_CUSTOMER_INFO';

const SET_LEAVE_STEP_ONE = 'reservations/SET_LEAVE_STEP_ONE';
const SET_VEHICLE_CATEGORY = 'reservations/SET_VEHICLE_CATEGORY';
const SET_QUOTE_VEHICLE_CATEGORY = 'reservations/SET_QUOTE_VEHICLE_CATEGORY';

const CLEAN_QUOTE_ID = 'reservations/CLEAN_QUOTE_ID';
const SET_QUOTE_INTERNAL_ERROR = 'reservations/SET_QUOTE_INTERNAL_ERROR';

const SET_NEW_INVOICE_PAYMENT = 'reservations/SET_NEW_INVOICE_PAYMENT';

const SAVE_PAYMENT = 'reservations/SAVE_PAYMENT';
const SAVE_PAYMENT_SUCCEED = 'reservations/SAVE_PAYMENT_SUCCEED';
const SAVE_PAYMENT_FAILED = 'reservations/SAVE_PAYMENT_FAILED';
const HAS_ADDED_BILLING_DETAILS_OF_PAYMENT =
  'reservations/HAS_ADDED_BILLING_DETAILS_OF_PAYMENT';

const GET_VEHICLE_LINE_PRICES = 'reservations/GET_VEHICLE_LINE_PRICES';
const GET_VEHICLE_LINE_PRICES_SUCCED =
  'reservations/GET_VEHICLE_LINE_PRICES_SUCCED';
const GET_VEHICLE_LINE_PRICES_FAILED =
  'reservations/GET_VEHICLE_LINE_PRICES_FAILED';

const SET_VEHICLE_OF_RESERVATION_REQUESTED =
  'reservations/SET_VEHICLE_OF_RESERVATION_REQUESTED';
const SET_CUSTOMER_VALUE = 'reservations/SET_CUSTOMER_VALUE';
const SET_CUSTOMER_VALUE_NO_PRICE_QUOTE =
  'reservations/SET_CUSTOMER_VALUE_NO_PRICE_QUOTE';

const SET_VEHICLE_OF_RESERVATION = 'reservations/SET_VEHICLE_OF_RESERVATION';
const SET_VEHICLE_OF_RESERVATION_SUCCEED =
  'reservations/SET_VEHICLE_OF_RESERVATION_SUCCEED';
const SET_VEHICLE_OF_RESERVATION_FAILED =
  'reservations/SET_VEHICLE_OF_RESERVATION_FAILED';

const CHANGE_VEHICLE_OF_RESERVATION =
  'reservations/CHANGE_VEHICLE_OF_RESERVATION';
const CHANGE_VEHICLE_OF_RESERVATION_SUCCEED =
  'reservations/CHANGE_VEHICLE_OF_RESERVATION_SUCCEED';
const CHANGE_VEHICLE_OF_RESERVATION_FAILED =
  'reservations/CHANGE_VEHICLE_OF_RESERVATION_FAILED';

const GET_CATALOGUE_OF_VEHICLES = 'reservations/GET_CATALOGUE_OF_VEHICLES';
const GET_CATALOGUE_OF_VEHICLES_SUCCEED =
  'reservations/GET_CATALOGUE_OF_VEHICLES_SUCCEED';
const GET_CATALOGUE_OF_VEHICLES_FAILED =
  'reservations/GET_CATALOGUE_OF_VEHICLES_FAILED';

const CLEAR_CATALOGUE_OF_VEHICLES = 'reservations/CLEAR_CATALOGUE_OF_VEHICLES';

const SET_FILTERS_CATALOGUE_OF_VEHICLES =
  'reservations/SET_FILTERS_CATALOGUE_OF_VEHICLES';

const SET_PARAMS_OF_VEHICLES_CATALOGUE =
  'reservations/SET_PARAMS_OF_VEHICLES_CATALOGUE';

const CREATE_RESERVATION_CONTRACT = 'reservations/CREATE_RESERVATION_CONTRACT';
const CREATE_RESERVATION_CONTRACT_SUCCEED =
  'reservations/CREATE_RESERVATION_CONTRACT_SUCCEED';
const CREATE_RESERVATION_CONTRACT_FAILED =
  'reservations/CREATE_RESERVATION_CONTRACT_FAILED';

const GET_CONTRACTS_PDF = 'reservations/GET_CONTRACTS_PDF';
const GET_CONTRACTS_PDF_SUCCEED = 'reservations/GET_CONTRACTS_PDF_SUCCEED';
const GET_CONTRACTS_PDF_FAILED = 'reservations/GET_CONTRACTS_PDF_FAILED';

const SEND_FORM_REQ = 'reservations/SEND_FORM_REQ';
const SEND_FORM_REQ_SUCCEED = 'reservations/SEND_FORM_REQ_SUCCEED';
const SEND_FORM_REQ_FAILED = 'reservations/SEND_FORM_REQ_FAILED';

const SEND_RESERVATION_REQ = 'reservations/SEND_RESERVATION_REQ';
const SEND_RESERVATION_REQ_SUCCEED =
  'reservations/SEND_RESERVATION_REQ_SUCCEED';
const SEND_RESERVATION_REQ_FAILED = 'reservations/SEND_RESERVATION_REQ_FAILED';

const GO_TO_CONTRACT = 'reservations/GO_TO_CONTRACT';
const VERIFY_FORM_COMPLETED = 'reservations/VERIFY_FORM_COMPLETED';
const VERIFY_FORM_COMPLETED_SUCCEED =
  'reservations/VERIFY_FORM_COMPLETED_SUCCEED';
const VERIFY_FORM_COMPLETED_FAILED =
  'reservations/VERIFY_FORM_COMPLETED_FAILED';

const TEMP_ACCEPT_SIGNATURES = 'reservations/TEMP_ACCEPT_SIGNATURES';

const ACCEPT_SIGNATURES = 'reservations/ACCEPT_SIGNATURES';
const ACCEPT_SIGNATURES_SUCCEED = 'reservations/ACCEPT_SIGNATURES_SUCCEED';
const ACCEPT_SIGNATURES_FAILED = 'reservations/ACCEPT_SIGNATURES_FAILED';

const REJECT_SIGNATURES = 'reservations/REJECT_SIGNATURES';
const REJECT_SIGNATURES_SUCCEED = 'reservations/REJECT_SIGNATURES_SUCCEED';
const REJECT_SIGNATURES_FAILED = 'reservations/REJECT_SIGNATURES_FAILED';

const RESET_SIGNATURES = 'reservations/RESET_SIGNATURES';

const REHYDRATE_CUSTOMER_STEP = 'reservations/REHYDRATE_CUSTOMER_STEP';
const REHYDRATE_PAYMENT_STEP = 'reservations/REHYDRATE_PAYMENT_STEP';

const GET_RESERVATION_FORM = 'reservations/GET_RESERVATION_FORM';
const GET_RESERVATION_FORM_SUCCEED =
  'reservations/GET_RESERVATION_FORM_SUCCEED';
const GET_RESERVATION_FORM_FAILED = 'reservations/GET_RESERVATION_FORM_FAILED';

const GET_RESERVATION_LIST_FILTERS =
  'reservations/GET_RESERVATION_LIST_FILTERS';
const GET_RESERVATION_LIST_FILTERS_SUCCEED =
  'reservations/GET_RESERVATION_LIST_FILTERS_SUCCEED';
const GET_RESERVATION_LIST_FILTERS_FAILED =
  'reservations/GET_RESERVATION_LIST_FILTERS_FAILED';

const SET_EXTRA_CHARGES = 'reservations/SET_EXTRA_CHARGES';

const ASSIGN_CUSTOMER = 'reservations/ASSIGN_CUSTOMER';
const ASSIGN_CUSTOMER_SUCCEED = 'reservations/ASSIGN_CUSTOMER_SUCCEED';
const ASSIGN_CUSTOMER_FAILED = 'reservations/ASSIGN_CUSTOMER_FAILED';

const HYDRATE_CHANGE_OF_VEHICLE_FLOW =
  'reservations/HYDRATE_CHANGE_OF_VEHICLE_FLOW';
const HYDRATE_CHANGE_OF_VEHICLE_FLOW_SUCCESS =
  'reservations/HYDRATE_CHANGE_OF_VEHICLE_FLOW_SUCCESS';
const HYDRATE_CHANGE_OF_VEHICLE_FLOW_FAILED =
  'reservations/HYDRATE_CHANGE_OF_VEHICLE_FLOW_FAILED';

const GET_EXTENDED_CONTRACTS = 'reservations/GET_EXTENDED_CONTRACTS';
const GET_EXTENDED_CONTRACTS_SUCCESS =
  'reservations/GET_EXTENDED_CONTRACTS_SUCCESS';
const GET_EXTENDED_CONTRACTS_FAILED =
  'reservations/GET_EXTENDED_CONTRACTS_FAILED';

const SET_IN_PROGRESS_EXTEND = 'reservations/SET_IN_PROGRESS_EXTEND';
const SET_EXTEND_CONTRACT_VEHICLE = 'reservations/SET_EXTEND_CONTRACT_VEHICLE';
const HYDRATE_EXTEND_CONTRACT_FLOW =
  'reservations/HYDRATE_EXTEND_CONTRACT_FLOW';
const HYDRATE_EXTEND_CONTRACT_INIT_FLOW_SUCCESS =
  'reservations/HYDRATE_EXTEND_CONTRACT_INIT_FLOW_SUCCESS';
const HYDRATE_EXTEND_CONTRACT_FLOW_SUCCESS =
  'reservations/HYDRATE_EXTEND_CONTRACT_FLOW_SUCCESS';
const HYDRATE_EXTEND_CONTRACT_FLOW_FAILED =
  'reservations/HYDRATE_EXTEND_CONTRACT_FLOW_FAILED';

const FINISH_EXTENDED_CONTRACT = 'reservations/FINISH_EXTENDED_CONTRACT';

const CANCEL_RESERVATION = 'reservations/CANCEL_RESERVATION';
const CANCEL_RESERVATION_SUCCESSED =
  'reservations/CANCEL_RESERVATION_SUCCESSED';
const CANCEL_RESERVATION_FAILED = 'reservations/CANCEL_RESERVATION_FAILED';

const GET_CUSTOMER_CARDS = 'reservations/GET_CUSTOMER_CARDS';
const GET_CUSTOMER_CARDS_SUCCESSED =
  'reservations/GET_CUSTOMER_CARDS_SUCCESSED';
const GET_CUSTOMER_CARDS_FAILED = 'reservations/GET_CUSTOMER_CARDS_FAILED';
const UPDATE_RESERVATION = 'reservations/UPDATE_RESERVATION';
const UPDATE_RESERVATION_SUCCEED = 'reservations/UPDATE_RESERVATION_SUCCEED';
const UPDATE_RESERVATION_FAILED = 'reservations/UPDATE_RESERVATION_FAILED';
const RESET_RESPONSE_DIALOG_UPDATE_RESERVATION =
  'reservations/RESET_RESPONSE_DIALOG_UPDATE_RESERVATION';
const SET_OPEN_CLOSE_UPDATE_RESERVATION_MODAL =
  'reservations/SET_OPEN_CLOSE_UPDATE_RESERVATION_MODAL';
const GET_DETAIL_OF_CUSTOMER_IN_UPDATE_MODAL =
  'reservations/GET_DETAIL_OF_CUSTOMER_IN_UPDATE_MODAL';
const SET_RESERVATION_VEHICLE_FAILED = 'SET_RESERVATION_VEHICLE_FAILED';
const UPDATE_CARD = 'reservations/UPDATE_CARD';
const UPDATE_CARD_SUCCEED = 'reservations/UPDATE_CARD_SUCCEED';
const UPDATE_CARD_FAILED = 'reservations/UPDATE_CARD_FAILED';
const CLOSE_RESPONSE_DIALOG_UPDATE_CARD =
  'reservations/CLOSE_RESPONSE_DIALOG_UPDATE_CARD';

export default {
  ASSIGN_CUSTOMER_FAILED,
  ASSIGN_CUSTOMER_SUCCEED,
  ASSIGN_CUSTOMER,
  GET_VEHICLES,
  GET_VEHICLES_SUCCEED,
  GET_VEHICLES_FAILED,
  SET_STEP,
  SET_TITLES,
  SET_START_HOURS,
  SET_END_HOURS,
  SELECT_VEHICLE_CATEGORY,
  GET_VEHICLE_TYPES,
  GET_VEHICLE_TYPES_SUCCEED,
  GET_VEHICLE_TYPES_FAILED,
  GET_MOST_QUOTED,
  GET_MOST_QUOTED_SUCCEED,
  GET_MOST_QUOTED_FAILED,
  GET_TIME_LIMITS,
  GET_TIME_LIMITS_SUCCEED,
  GET_TIME_LIMITS_FAILED,
  SAVE_QUOTE_STEP_ONE,
  SAVE_QUOTE_STEP_ONE_SUCCEED,
  SAVE_QUOTE_STEP_ONE_FAILED,
  CLOSE_RESPONSE_DIALOG,
  CLOSE_RESPONSE_DIALOG_S,
  SET_RESPONSE_DIALOG,
  SET_CONTRACT_QUOTE,
  SET_QUOTE_VALUES,
  SET_PARAMS,
  EDIT_QUOTE_STEP_ONE,
  EDIT_QUOTE_STEP_ONE_SUCCEED,
  EDIT_QUOTE_STEP_ONE_FAILED,
  SET_IN_PROGRESS_QUOTE,
  SAVE_NOT_PRICE_QUOTE,
  SAVE_NOT_PRICE_QUOTE_SUCCEED,
  SAVE_NOT_PRICE_QUOTE_FAILED,
  SAVE_QUOTE_STEP_TWO,
  SAVE_QUOTE_STEP_TWO_SUCCEED,
  SAVE_QUOTE_STEP_TWO_FAILED,
  GET_DISCOUNTS,
  GET_DISCOUNTS_SUCCEED,
  GET_DISCOUNTS_FAILED,
  APPLY_BRANCH_DISCOUNT,
  CANCEL_BRANCH_DISCOUNT,
  SAVE_DISCOUNT,
  SAVE_DISCOUNT_SUCCEED,
  SAVE_DISCOUNT_FAILED,
  VALIDATE_PROMOCODE,
  VALIDATE_PROMOCODE_FAILED,
  VALIDATE_PROMOCODE_SUCCEED,
  SAVE_QUOTE_STEP_THREE,
  SAVE_QUOTE_STEP_THREE_FAILED,
  SAVE_QUOTE_STEP_THREE_SUCCEED,
  OPEN_SET_QUOTE_DIALOG,
  CLOSE_SET_QUOTE_DIALOG,
  OPEN_SET_RESERVATION_DIALOG,
  CLOSE_SET_RESERVATION_DIALOG,
  SET_SELECTED_CATEGORY,
  SET_PREVIOUS_SELECTED_CATEGORY,
  OPEN_EDIT_SCHEDULE_DATES,
  CLOSE_EDIT_SCHEDULE_DATES,
  EDIT_PLAN,
  EDIT_DISCOUNT,
  CANCEL_EDIT_DISCOUNT,
  SET_BRANDS,
  RESET,
  CREATE_RESERVATION,
  CREATE_RESERVATION_FAILED,
  CREATE_RESERVATION_SUCCEED,
  SET_DELIVERY_STEP_ONE,
  SET_DELIVERY_STEP_TWO,
  SET_DELIVERY_STEP_THREE,
  SET_DELIVERY_STEP,
  CONFIRM_DELIVERY,
  FINALLY_DELIVERY,
  SET_CHANGE_VEHICLE,
  SET_REFUND_STEP_ONE,
  SET_REFUND_STEP_TWO,
  SET_REFUND_STEP_THREE,
  SET_REFUND_STEP,
  CONFIRM_REFUND,
  FINALLY_REFUND,
  GET_LIST_OF_RESERVATIONS_REQUESTED,
  GET_LIST_OF_RESERVATIONS_SUCCESSED,
  GET_LIST_OF_RESERVATIONS_FAILED,
  GET_LIST_OF_RESERVATIONS_FOR_EXCEL,
  GET_LIST_OF_RESERVATIONS_FOR_EXCEL_SUCCEEDED,
  GET_LIST_OF_RESERVATIONS_FOR_EXCEL_FAILED,
  SET_LIST_OF_RESERVATION_PAGE,
  SET_INITIAL_PARAMS_LIST_OF_RESERVATIONS,
  SET_PARAMS_LIST_OF_RESERVATIONS,
  RESET_PARAMS_LIST_OF_RESERVATIONS,
  GET_RESERVATION_INFO,
  GET_RESERVATION_INFO_SUCCESSED,
  GET_RESERVATION_INFO_FAILED,
  CLEAR_RESERVATION_INFO,
  CREATE_EXTEND_CONTRACT,
  CREATE_EXTEND_CONTRACT_SUCCESSED,
  CREATE_EXTEND_CONTRACT_FAILED,
  EDIT_RESERVATION_INFO,
  VERIFY_CHECK_POINT,
  VERIFY_CHECK_POINT_SUCCESSED,
  VERIFY_CHECK_POINT_FAILED,
  SET_ACTUAL_POSITION,
  VERIFY_COMPLETED_CHECK_LIST,
  VERIFY_COMPLETED_CHECK_LIST_SUCCEED,
  VERIFY_COMPLETED_CHECK_LIST_FAILED,
  SEND_FORM_LINK_FIRM,
  SEND_FORM_LINK_FIRM_SUCCEED,
  SEND_FORM_LINK_FIRM_FAILED,
  CLEAN_SEND_FORM_LINK_FIRM,
  SET_TERMS_AND_CONDITIONS,
  SET_OP_CUSTOMER_INFO,
  SET_LEAVE_STEP_ONE,
  SET_VEHICLE_CATEGORY,
  SET_QUOTE_VEHICLE_CATEGORY,
  SET_END_LIMIT_HOURS,
  SET_START_LIMIT_HOURS,
  CLEAN_QUOTE_ID,
  SET_QUOTE_INTERNAL_ERROR,
  SET_NEW_INVOICE_PAYMENT,
  SAVE_PAYMENT,
  SAVE_PAYMENT_SUCCEED,
  SAVE_PAYMENT_FAILED,
  HAS_ADDED_BILLING_DETAILS_OF_PAYMENT,
  GET_VEHICLE_LINE_PRICES,
  GET_VEHICLE_LINE_PRICES_SUCCED,
  GET_VEHICLE_LINE_PRICES_FAILED,
  SET_VEHICLE_OF_RESERVATION_REQUESTED,
  SET_CUSTOMER_VALUE,
  SET_CUSTOMER_VALUE_NO_PRICE_QUOTE,
  SET_VEHICLE_OF_RESERVATION,
  SET_VEHICLE_OF_RESERVATION_SUCCEED,
  SET_VEHICLE_OF_RESERVATION_FAILED,
  CHANGE_VEHICLE_OF_RESERVATION,
  CHANGE_VEHICLE_OF_RESERVATION_SUCCEED,
  CHANGE_VEHICLE_OF_RESERVATION_FAILED,
  SET_FILTERS_CATALOGUE_OF_VEHICLES,
  GET_CATALOGUE_OF_VEHICLES,
  GET_CATALOGUE_OF_VEHICLES_SUCCEED,
  GET_CATALOGUE_OF_VEHICLES_FAILED,
  CLEAR_CATALOGUE_OF_VEHICLES,
  SET_PARAMS_OF_VEHICLES_CATALOGUE,
  CREATE_RESERVATION_CONTRACT,
  CREATE_RESERVATION_CONTRACT_SUCCEED,
  CREATE_RESERVATION_CONTRACT_FAILED,
  GET_CONTRACTS_PDF,
  GET_CONTRACTS_PDF_SUCCEED,
  GET_CONTRACTS_PDF_FAILED,
  SEND_FORM_REQ,
  SEND_FORM_REQ_SUCCEED,
  SEND_FORM_REQ_FAILED,
  SEND_RESERVATION_REQ,
  SEND_RESERVATION_REQ_SUCCEED,
  SEND_RESERVATION_REQ_FAILED,
  GO_TO_CONTRACT,
  VERIFY_FORM_COMPLETED,
  VERIFY_FORM_COMPLETED_SUCCEED,
  VERIFY_FORM_COMPLETED_FAILED,
  ACCEPT_SIGNATURES,
  ACCEPT_SIGNATURES_SUCCEED,
  ACCEPT_SIGNATURES_FAILED,
  REJECT_SIGNATURES,
  REJECT_SIGNATURES_SUCCEED,
  REJECT_SIGNATURES_FAILED,
  TEMP_ACCEPT_SIGNATURES,
  RESET_SIGNATURES,
  REHYDRATE_CUSTOMER_STEP,
  REHYDRATE_PAYMENT_STEP,
  GET_RESERVATION_FORM,
  GET_RESERVATION_FORM_SUCCEED,
  GET_RESERVATION_FORM_FAILED,
  GET_RESERVATION_LIST_FILTERS,
  GET_RESERVATION_LIST_FILTERS_SUCCEED,
  GET_RESERVATION_LIST_FILTERS_FAILED,
  SET_EXTRA_CHARGES,
  HYDRATE_CHANGE_OF_VEHICLE_FLOW,
  HYDRATE_CHANGE_OF_VEHICLE_FLOW_SUCCESS,
  HYDRATE_CHANGE_OF_VEHICLE_FLOW_FAILED,
  GET_EXTENDED_CONTRACTS,
  GET_EXTENDED_CONTRACTS_SUCCESS,
  GET_EXTENDED_CONTRACTS_FAILED,
  SET_IN_PROGRESS_EXTEND,
  SET_EXTEND_CONTRACT_VEHICLE,
  HYDRATE_EXTEND_CONTRACT_FLOW,
  HYDRATE_EXTEND_CONTRACT_INIT_FLOW_SUCCESS,
  HYDRATE_EXTEND_CONTRACT_FLOW_SUCCESS,
  HYDRATE_EXTEND_CONTRACT_FLOW_FAILED,
  FINISH_EXTENDED_CONTRACT,
  CANCEL_RESERVATION,
  CANCEL_RESERVATION_SUCCESSED,
  CANCEL_RESERVATION_FAILED,
  GET_CUSTOMER_CARDS,
  GET_CUSTOMER_CARDS_SUCCESSED,
  GET_CUSTOMER_CARDS_FAILED,
  UPDATE_RESERVATION,
  UPDATE_RESERVATION_SUCCEED,
  UPDATE_RESERVATION_FAILED,
  RESET_RESPONSE_DIALOG_UPDATE_RESERVATION,
  SET_OPEN_CLOSE_UPDATE_RESERVATION_MODAL,
  GET_DETAIL_OF_CUSTOMER_IN_UPDATE_MODAL,
  SET_RESERVATION_VEHICLE_FAILED,
  UPDATE_CARD,
  UPDATE_CARD_SUCCEED,
  UPDATE_CARD_FAILED,
  CLOSE_RESPONSE_DIALOG_UPDATE_CARD,
};
