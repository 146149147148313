import { call, put, select, all, delay, take, fork } from 'redux-saga/effects';
import _get from 'lodash/get';
import { push } from 'connected-react-router';
import { getOptionsAuth } from 'services/auth';
import {
  getQuotePath,
  PATH_CUSTOMERS_BASE,
  isContractQuote,
} from 'utils/navigation';
import { CUSTOMER_PLATFORM } from 'utils/constants';
import { RESERVATION_STEP } from 'utils/constants/reservations';
import { showLoader, hideLoader } from 'store/commons/actions';
import { getDetail } from 'store/customers/actions';
import {
  GET_DETAIL_SUCCEEDED,
  GET_DETAIL_FAILED,
} from 'store/customers/action-types';
import {
  addBillingInfo,
  editBillingInfo,
  deleteBillingInfo,
} from 'services/customers';
import {
  getVehicleTypesFilters,
  getAvailableDays,
  getVehiclesCatalog,
  saveStepOne,
  editStepOne,
  saveNotPriceQuote,
  saveStepTwo,
  getDiscounts,
  saveDiscount,
  validatePromocode,
  saveStepThree,
  getMostQuoted,
  getVehicleLinePrices,
  createReservation,
  getReservationsCatalogue,
  getSingleReservation,
  postVerificationCheckListPoint,
  postVerificationCheckListPointImage,
  savePayment as savePaymentService,
  postVehicleReservation,
  postChangeVehicleOfReservation,
  getCatalogueOfVehiclesService,
  postCreateReservationContract,
  getContractsService,
  getVerifyCompletedCheckList,
  postFormLink,
  verifySignaturesStatus,
  acceptSignatures,
  rejectSignatures,
  getReservationForm,
  getReservationListFilter,
  assignCustomer,
  sendReservationService,
  cancelReservationService,
  getCustomerCardsService,
  updateReservationService,
  updateCardService,
} from 'services/reservations';
import {
  postReservationExtendContract,
  getExtendedContracts,
  finishExtendContract,
} from 'services/dailyRentContracts';
import {
  AREA_OPTIONS,
  DELIVERY_REFUND_TYPES,
} from '@reservations/commons/constants';
import { VEHICLES_STATUS } from '@vehicles/common/constants';
import {
  getVehicleTypesFailed,
  getVehicleTypesSucceed,
  getTimeLimitsSucceed,
  getTimeLimitsFailed,
  getVehiclesSucceed,
  getVehiclesFailed,
  saveStepOneFailed,
  saveStepOneSucceed,
  setInProgressQuote,
  editStepOneFailed,
  editStepOneSucceed,
  saveNotPriceQuoteFailed,
  saveNotPriceQuoteSucceed,
  saveStepTwoFailed,
  saveStepTwoSucceed,
  getDiscountsFailed,
  getDiscountsSucceed,
  saveDiscountFailed,
  saveDiscountSucceed,
  validatePromocodeFailed,
  validatePromocodeSucceed,
  saveStepThreeSucceed,
  saveStepThreeFailed,
  openEditScheduleSection,
  getMostQuotedFailed,
  getMostQuotedSucceed,
  getVehicleLinePricesSucceed,
  getVehicleLinePricesFailed,
  createReservationSucceed,
  createReservationFailed,
  getListOfReservationsSucceeded,
  getListOfReservationsFailed,
  getListOfReservationsForExcelSucceeded,
  getListOfReservationsForExcelFailed,
  getReservationInfo,
  getReservationInfoSucceed,
  getReservationInfoFailed,
  editReservationInfo,
  verifyCheckPointSucceed,
  verifyCheckPointFailed,
  sendFormLinkFirmSucceed,
  sendFormLinkFirmFailed,
  savePaymentFailed,
  savePaymentSucceed,
  setHasAddedBillingDetailsOfPayment,
  setVehicleReservationSucceed,
  setVehicleReservationFailed,
  changeVehicleReservationSucceed,
  changeVehicleReservationFailed,
  getCatalogueOfVehiclesSucceed,
  getCatalogueOfVehiclesFailed,
  createReservationContractSucceed,
  createReservationContractFailed,
  getContractsPDFSucceed,
  getContractsPDFFailed,
  verifyCompletedCheckListSucceed,
  verifyCompletedCheckListFailed,
  sendFormLinkSucceed,
  sendFormLinkFailed,
  verifySignaturesCompletedSucceed,
  verifySignaturesCompletedFailed,
  acceptSignaturesSucceed,
  acceptSignaturesFailed,
  rejectSignaturesSucceed,
  rejectSignaturesFailed,
  rehydrateCustomerStep,
  rehydratePaymentStep,
  getReservationFormSucceed,
  getReservationFormFailed,
  getReservationListFiltersSucceed,
  getReservationListFiltersFailed,
  setActualPosition,
  assignCustomerSucceed,
  assignCustomerFailed,
  setExtraCharges,
  hydrateChangeOfVehicleFlowSucceed,
  hydrateChangeOfVehicleFlowFailed,
  setQuoteValues,
  openSendReservationDialog,
  sendReservationSucceed,
  sendReservationFailed,
  setInProgressExtend,
  hydrateExtendContractInitFlowSuccess,
  hydrateExtendContractFlowSuccess,
  hydrateExtendContractFlowFailed,
  getExtendedContractsSuccess,
  getExtendedContractsFailed,
  cancelReservationsSuccessed,
  cancelReservationFailed,
  getCustomerCardsSuccessed,
  getCustomerCardsFailed,
  updateReservationSucceed,
  updateReservationFailed,
  setOpenCloseUpdateReservationModal,
  updateCardSucceed,
  updateCardFailed,
} from './actions';
import { setReservationVehicleFailed } from '../reservations/actions';

import { getSummaryInfo } from '../customers/actions';
import { GET_SUMMARY_INFO_SUCCEEDED } from '../customers/action-types';

const { DELIVERY, REFUND } = DELIVERY_REFUND_TYPES;

export function* getMostQuotedSaga({ payload }) {
  try {
    yield put(showLoader());
    if (payload) {
      const data = yield call(getMostQuoted, payload);
      yield put(getMostQuotedSucceed({ data }));
    }
  } catch (error) {
    yield put(getMostQuotedFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getVehicleLinePricesSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(getVehicleLinePrices, payload);
    yield put(getVehicleLinePricesSucceed({ data }));
  } catch (error) {
    yield put(getVehicleLinePricesFailed({ error }));
  } finally {
    yield put(hideLoader());
  }
}

export function* getVehicleTypesSaga() {
  try {
    yield put(showLoader());
    const data = yield call(getVehicleTypesFilters);
    yield put(getVehicleTypesSucceed({ data }));
  } catch (error) {
    yield put(getVehicleTypesFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getAvailableTimeSaga() {
  try {
    yield put(showLoader());
    const data = yield call(getAvailableDays);
    yield put(getTimeLimitsSucceed({ data }));
  } catch (error) {
    yield put(getTimeLimitsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveStepOneSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(saveStepOne, payload);
    yield put(setInProgressQuote(true));
    yield put(saveStepOneSucceed({ data }));
    yield put(push(getQuotePath('two')));
  } catch (error) {
    yield put(saveStepOneFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* editStepOneSaga({ payload }) {
  try {
    yield put(showLoader());
    const currentQuote = yield select((state) =>
      _get(state, 'reservations.quote.values.quoteId', '')
    );
    const data = yield call(editStepOne, payload, currentQuote);
    yield put(editStepOneSucceed({ data }));
  } catch (error) {
    yield put(editStepOneFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveNotPriceQuoteSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(saveNotPriceQuote, payload);
    yield put(saveNotPriceQuoteSucceed(data));
    // This is responsible of hide loader
    yield put(getReservationInfo({ ...data, isNotPriceQuote: true }));
  } catch (error) {
    yield put(saveNotPriceQuoteFailed(error));
    yield put(hideLoader());
  }
}

export function* getVehiclesCatalogSaga({ payload }) {
  try {
    yield put(showLoader());
    const selectedCategory = yield select((state) =>
      _get(state, 'reservations.filters.selectedCategory.value', '')
    );
    const data = yield call(
      getVehiclesCatalog,
      payload.vehicleTypes,
      payload.brands,
      payload.branch,
      payload.lineSelected,
      payload.page,
      payload.startRentalDay,
      selectedCategory,
      payload.noPageable,
      payload.size
    );
    yield put(getVehiclesSucceed({ data }));
  } catch (error) {
    yield put(getVehiclesFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveStepTwoSaga({ payload: { fullVehicle, ...payload } }) {
  try {
    yield put(showLoader());
    const priceQuote = yield select((state) =>
      _get(state, 'reservations.quote.values.quoteId', '')
    );
    const data = yield call(saveStepTwo, { ...payload, priceQuote });
    yield put(setQuoteValues({ fullVehicle }));
    yield put(saveStepTwoSucceed({ data }));
    yield put(push(getQuotePath('three')));
  } catch (error) {
    yield put(saveStepTwoFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getDiscountsSaga() {
  try {
    yield put(showLoader());
    const data = yield call(getDiscounts);
    yield put(getDiscountsSucceed({ data }));
  } catch (error) {
    yield put(getDiscountsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveDiscountSaga({ payload }) {
  try {
    yield put(showLoader());
    const priceQuote = yield select((state) =>
      _get(state, 'reservations.quote.values.quoteId', '')
    );
    const data = yield call(saveDiscount, payload, priceQuote);
    yield put(saveDiscountSucceed({ data }));
  } catch (error) {
    yield put(saveDiscountFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* validatePromocodeSaga({ payload }) {
  try {
    yield put(showLoader());
    const priceQuote = yield select((state) =>
      _get(state, 'reservations.quote.values.quoteId', '')
    );
    const data = yield call(validatePromocode, payload, priceQuote);
    yield put(validatePromocodeSucceed({ data }));
  } catch (error) {
    yield put(validatePromocodeFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveStepThreeSaga({ payload }) {
  try {
    yield put(showLoader());
    const priceQuote = yield select((state) =>
      _get(state, 'reservations.quote.values.quoteId', '')
    );
    yield call(saveStepThree, payload, priceQuote);
    yield put(saveStepThreeSucceed({ email: payload.email }));
  } catch (error) {
    yield put(saveStepThreeFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* editPlanSaga() {
  yield put(push(getQuotePath('two')));
  yield put(openEditScheduleSection());
}

export function* createReservationSaga({ payload }) {
  try {
    yield put(showLoader());
    const priceQuote = yield select((state) =>
      _get(state, 'reservations.quote.values.quoteId', '')
    );
    const customerUuid = yield select((state) =>
      _get(state, 'reservations.quote.values.customer')
    );
    const data = yield call(createReservation, {
      priceQuote,
      observations:
        payload.comment.trim().length === 0 ? 'n/a' : payload.comment,
    });
    yield put(setInProgressQuote(false));
    yield put(createReservationSucceed({ data }));
    let path = `${PATH_CUSTOMERS_BASE}add`;
    if (customerUuid) {
      yield put(getSummaryInfo(customerUuid));
      yield take(GET_SUMMARY_INFO_SUCCEEDED);
      path = `${PATH_CUSTOMERS_BASE}${customerUuid}/detail`;
      const platform = yield select((state) =>
        _get(
          state,
          'customers.customerDetail.data.userInfo.registrationPlatform.code',
          ''
        )
      );
      if (platform === CUSTOMER_PLATFORM.BATCH) {
        path = `${PATH_CUSTOMERS_BASE}${customerUuid}/edit`;
      }
    }
    yield put(openSendReservationDialog(path));
  } catch (error) {
    yield put(createReservationFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getListOfReservationsSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(
      getReservationsCatalogue,
      action.payload,
      getOptionsAuth()
    );
    yield put(getListOfReservationsSucceeded(data));
  } catch (error) {
    yield put(getListOfReservationsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getListOfReservationsForExcelSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(
      getReservationsCatalogue,
      action.payload,
      getOptionsAuth()
    );
    yield put(getListOfReservationsForExcelSucceeded(data));
  } catch (error) {
    yield put(getListOfReservationsForExcelFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getReservationInfoSaga(action) {
  try {
    yield put(showLoader());
    const {
      uuid = '',
      redirect = true,
      isNotPriceQuote = false,
      vehicleUuid: notPriceQuoteVehicle = '',
      isUpdateReservationModalOpen = false,
    } = action.payload;

    const data = yield call(getSingleReservation, uuid, getOptionsAuth());
    data.isUpdateReservationModalOpen = isUpdateReservationModalOpen;

    if (isNotPriceQuote && notPriceQuoteVehicle) {
      data.vehicleUuid = notPriceQuoteVehicle;
    }

    if (isNotPriceQuote) {
      yield put(saveNotPriceQuoteSucceed(data));
    }

    if (redirect) {
      switch (data.step) {
        case RESERVATION_STEP.CUSTOMER:
          yield put(rehydrateCustomerStep(data));
          yield put(push('/reservations/customer/add'));
          break;
        case RESERVATION_STEP.CREATE_PAYMENT:
          const paymentPath = isContractQuote()
            ? `/reservations/payment`
            : `/reservations/customer/${data.naturalPerson.uuid}/detail`;

          yield put(rehydrateCustomerStep(data));
          yield put(rehydratePaymentStep(data));
          yield put(push(paymentPath));
          break;
        case RESERVATION_STEP.VEHICLE_ASSIGNMENT:
        case RESERVATION_STEP.CREATE_CONTRACT:
          yield put(rehydrateCustomerStep(data));
          yield put(rehydratePaymentStep(data));
          yield put(push('/daily-rent-contracts/contract'));
          break;
        case RESERVATION_STEP.SIGN_CONTRACT:
          yield put(rehydrateCustomerStep(data));
          yield put(rehydratePaymentStep(data));
          yield put(push('/daily-rent-contracts/leasing'));
          break;
        case RESERVATION_STEP.DELIVERY:
          yield put(
            push(`/daily-rent-contracts/${data.uuid}/delivery-vehicle-parts`)
          );
          break;
        case RESERVATION_STEP.SIGN_DELIVERY:
          yield put(
            push(
              `/daily-rent-contracts/${data.uuid}/delivery-information-and-firm`
            )
          );
          break;
        case RESERVATION_STEP.RETURN:
          yield put(push(`/returns/${data.uuid}/refund`));
          break;
        case RESERVATION_STEP.SIGN_RETURN:
          yield put(push(`/returns/${data.uuid}/refund-information-and-firm`));
          break;
        case RESERVATION_STEP.CREATE_EXTRA_CHARGES_PAYMENT:
          yield put(push(`/returns/${data.uuid}/extra-charges`));
          break;
        default:
          break;
      }
    }
    yield put(getReservationInfoSucceed(data));
  } catch (error) {
    yield put(getReservationInfoFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* verifyCheckPointSaga({ payload = {} }) {
  try {
    yield put(showLoader());
    const { dataOfVerifyPoints = [], position, groupUuid = '' } = payload;
    let groupData = {};

    const listOfRequests = dataOfVerifyPoints.map(
      ({ checkPointBody, listOfImages = [], uuid }) => {
        let verifyCheckListPointImage = null;

        function* postVerifyCheckPoint() {
          const verifyCheckListPoint = yield call(
            postVerificationCheckListPoint,
            uuid,
            checkPointBody,
            getOptionsAuth()
          );

          if (listOfImages.length > 0) {
            verifyCheckListPointImage = yield call(
              postVerificationCheckListPointImage,
              uuid,
              listOfImages,
              getOptionsAuth()
            );
          }

          return {
            verifyCheckListPoint,
            verifyCheckListPointImage,
          };
        }

        return postVerifyCheckPoint();
      }
    );

    const dataOfRequests = yield all(listOfRequests);

    const listOfCheckPointsWithNewData = dataOfRequests.map(
      ({ verifyCheckListPoint = {}, verifyCheckListPointImage = null }) => {
        let verifyCheckList = { ...verifyCheckListPoint };

        if (verifyCheckListPointImage) {
          const { data = {} } = verifyCheckListPointImage;
          const { isSet = false, images = [] } = data.data;

          verifyCheckList = isSet
            ? verifyCheckListPointImage
            : { ...verifyCheckList, images };
        }

        const { success = false, data = {} } = verifyCheckList;

        if (success) {
          const pointData = data.data || {};
          return pointData;
        }

        return data;
      }
    );

    const reservationInfo = yield select(
      (state) => state.reservations.reservationInfo.data
    );

    const { step = '', verificationChecklists = [] } = reservationInfo;

    const formattedStep = step.includes(DELIVERY) ? DELIVERY : REFUND;

    const checkList = verificationChecklists.find(
      (singleCheckList) => singleCheckList.type === formattedStep
    );

    const { verificationGroups = [] } = checkList;

    const group = verificationGroups.find(
      ({ uuid = '' }) => uuid === groupUuid
    );

    if (group) groupData = group;

    if (position) {
      yield put(setActualPosition(position));
    }

    const checkListWithNewValue = verificationChecklists.map(
      (currentCheckList = {}) => {
        const singleCheckList = { ...currentCheckList };

        const sameList = singleCheckList.type === formattedStep;
        if (sameList) {
          // eslint-disable-next-line no-shadow
          const { verificationGroups = [] } = singleCheckList;

          const mapVerificationGroup = (singleGroup = {}) => {
            const { verificationPoints = [] } = singleGroup;

            const mapVerificationPoint = (verifyPoint) => {
              const checkPointWithNewData = listOfCheckPointsWithNewData.find(
                (point) => point.uuid === verifyPoint.uuid
              );

              if (checkPointWithNewData) {
                const { isParentChecklistCompleted } = checkPointWithNewData;

                if (isParentChecklistCompleted && !singleCheckList.completed) {
                  singleCheckList.completed = isParentChecklistCompleted;
                }
                return checkPointWithNewData;
              }

              return verifyPoint;
            };

            const verificationPointsWithNewData = verificationPoints.map(
              mapVerificationPoint
            );

            return {
              ...singleGroup,
              verificationPoints: verificationPointsWithNewData,
            };
          };

          const groupsWithNewGroup = verificationGroups.map(
            mapVerificationGroup
          );

          singleCheckList.verificationGroups = groupsWithNewGroup;

          return singleCheckList;
        }

        return singleCheckList;
      }
    );

    const newReservationState = {
      success: true,
      error: false,
      errorMessage: false,
      data: {
        ...reservationInfo,
        verificationChecklists: checkListWithNewValue,
      },
    };

    yield put(editReservationInfo(newReservationState));
    yield put(verifyCheckPointSucceed({ dataOfRequests, groupData }));
  } catch (error) {
    yield put(verifyCheckPointFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* sendFormLinkFirmSaga({ payload = {} }) {
  try {
    yield put(showLoader());
    const { uuid = '' } = yield select(
      (state) => state.reservations.reservationInfo.data
    );

    const body = {
      cellphone: null,
      email: null,
      reservation: uuid || payload.match.params.id,
    };

    if (payload.area !== AREA_OPTIONS.USER) {
      if (payload.area === AREA_OPTIONS.CELLPHONE) {
        body.cellphone = payload.cellphone;
        body.email = null;
      }
      if (payload.area === AREA_OPTIONS.EMAIL) {
        body.email = payload.email;
        body.cellphone = null;
      }
    }

    const data = yield call(postFormLink, body, getOptionsAuth());
    yield put(sendFormLinkFirmSucceed(data));
  } catch (error) {
    yield put(sendFormLinkFirmFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* savePaymentSaga({ payload = {} }) {
  try {
    yield put(showLoader());
    const {
      redirect = '/daily-rent-contracts/confirm',
      inExtraCharges = false,
    } = payload;
    let { request } = payload;
    const redirectExtend = '/daily-rent-contracts/confirm?extend=true';

    const extendContract = yield select(
      (state) => state.reservations.contract.extendContract
    );
    const customerUuid = yield select((state) =>
      _get(state, 'reservations.quote.values.customer')
    );

    const customerUuidOfReservation = yield select((state) =>
      _get(state, 'reservations.reservationInfo.data.naturalPerson.uuid', '')
    );

    const noPriceQuoteVehicle = yield select((state) =>
      _get(state, 'reservations.notPriceQuote.data.vehicleUuid', '')
    );

    const reservationUuid = yield select((state) =>
      _get(state, 'reservations.reservationInfo.data.uuid', '')
    );

    const hasAddedBillingDetails = yield select((state) =>
      _get(
        state,
        'reservations.reservation.payment.hasAddedBillingDetails',
        false
      )
    );

    if (request.invoiceDeleted) {
      yield call(deleteBillingInfo, request.invoiceDeleted);
    }

    if (request.invoiceForm && !hasAddedBillingDetails) {
      const {
        emailInvoice,
        invoiceSocialReason,
        invoiceRfc,
        invoiceCfdiUse,
        invoicePayForm = '',
        invoicePayMethod = '',
        invoiceSelected = '',
        invoiceZipCode = '',
        invoiceTaxRegime = '',
        observationsInvoice = '',
      } = request.invoiceForm;

      const addBillingRequest = {
        cfdiUseUuid: invoiceCfdiUse,
        name: invoiceSocialReason,
        naturalPerson: customerUuid || customerUuidOfReservation,
        rfc: invoiceRfc,
        paymentForm: invoicePayForm,
        paymentMethod: invoicePayMethod,
        taxZipCode: invoiceZipCode,
        fiscalRegime: invoiceTaxRegime,
      };

      if (invoiceSelected) {
        addBillingRequest.uuid = invoiceSelected;
        addBillingRequest.isPrimary = false;
      }

      const billing = yield call(
        invoiceSelected ? editBillingInfo : addBillingInfo,
        addBillingRequest
      );

      request = {
        ...request,
        invoice: {
          billingDetails: invoiceSelected || billing.data.uuid,
          email: emailInvoice,
          observations: observationsInvoice,
        },
      };
      delete request.invoiceForm;

      yield put(setHasAddedBillingDetailsOfPayment(true));
    }

    const data = yield call(savePaymentService, request);
    yield put(savePaymentSucceed(data));

    if (noPriceQuoteVehicle && reservationUuid && !inExtraCharges) {
      const body = { vehicle: noPriceQuoteVehicle, isNotPriceQuote: true };

      const vehicleData = yield call(
        postVehicleReservation,
        reservationUuid,
        body,
        getOptionsAuth()
      );

      yield put(setVehicleReservationSucceed(vehicleData));

      const reservationData = yield call(
        getSingleReservation,
        reservationUuid,
        getOptionsAuth()
      );

      yield put(getReservationInfoSucceed(reservationData));
    }

    if (inExtraCharges) {
      yield put(setExtraCharges({ success: true }));
    }
    if (redirect) {
      yield put(
        extendContract.extendContractInProgress
          ? push(redirectExtend)
          : push(redirect)
      );
    }
  } catch (error) {
    if (error.errorCode && error.errorCode.includes('480'))
      yield put(setReservationVehicleFailed(error));
    else yield put(savePaymentFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getCatalogueOfVehiclesSaga({ payload = { params: {} } }) {
  try {
    yield put(showLoader());
    const { fullVehicle = {} } = yield select((state) =>
      _get(state, 'reservations.quote.values', '')
    );

    // This work for change of vehicle flow
    const prevSelectedLineUuid = yield select((state) =>
      _get(
        state,
        'reservations.reservationInfo.data.priceQuote.selectedVehicleLinePrice.vehicle.vehicleLineUuid',
        ''
      )
    );

    const params = {
      ...payload.params,
      line: payload.params.type
        ? ''
        : fullVehicle.vehicleLineUuid || prevSelectedLineUuid,
      type: payload.params.type,
    };

    const { content, totalPages, totalElements } = yield call(
      getCatalogueOfVehiclesService,
      params,
      getOptionsAuth()
    );
    let data;
    if (payload.onlyOnFloor) {
      data = content.filter((vehicle) => vehicle.statusName === 'Disponible');
    } else {
      data = content;
    }
    yield put(
      getCatalogueOfVehiclesSucceed({ data, totalPages, totalElements })
    );
  } catch (error) {
    console.log(error);
    yield put(getCatalogueOfVehiclesFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* setVehicleReservationSaga({ payload = '' }) {
  try {
    yield put(showLoader());
    const { id = '' } = yield select((state) => state.reservations.reservation);
    const reservationInfo = yield select(
      (state) => state.reservations.reservationInfo
    );
    const { uuid = '' } = yield select(
      (state) => state.reservations.contract.setVehicle.vehicle
    );
    const body = {
      vehicle: payload || uuid,
    };

    const data = yield call(
      postVehicleReservation,
      reservationInfo.uuid || id,
      body,
      getOptionsAuth()
    );
    yield put(setVehicleReservationSucceed(data));
    return true;
  } catch (error) {
    yield put(setVehicleReservationFailed(error));
    return false;
  } finally {
    yield put(hideLoader());
  }
}

export function* changeVehicleReservationSaga(action = {}) {
  try {
    yield put(showLoader());
    const reservationUuid = yield select((state) =>
      _get(state, 'reservations.reservationInfo.data.uuid', '')
    );

    const data = {
      vehicleChangeReasonUuid: _get(action.payload, 'changeVehicleReason', ''),
      reservationUuid,
    };

    const changeRequestUuid = yield call(
      postChangeVehicleOfReservation,
      data,
      getOptionsAuth()
    );

    yield put(
      changeVehicleReservationSucceed({ changeRequestUuid, reservationUuid })
    );
  } catch (error) {
    yield put(changeVehicleReservationFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* createReservationContractSaga({ payload }) {
  let inVehicleRequest = true;

  try {
    yield put(showLoader());
    const {
      dataReservationContract = {},
      vehicle = null,
      redirectPath = './leasing',
    } = payload;

    const reservationId = yield select(
      (state) => state.reservations.reservation?.id
    );
    const reservationInfo = yield select(
      (state) => state.reservations.reservationInfo.data
    );
    const reservationUuid = reservationId || reservationInfo.uuid;

    const contractStep =
      reservationInfo?.step === RESERVATION_STEP.CREATE_CONTRACT;

    const hasSetVehicle = yield select(
      (state) => state.reservations.contract.setVehicle.success
    );

    const extendContract = yield select(
      (state) => state.reservations.contract.extendContract
    );

    inVehicleRequest = !hasSetVehicle;

    if (vehicle && !contractStep && !hasSetVehicle) {
      const data = extendContract.extendContractInProgress
        ? {
            vehicle: extendContract.assignedVehicle,
            extensionInProgress: extendContract.extendContractInProgress,
          }
        : {
            vehicle: vehicle.uuid,
          };
      const vehicleData = yield call(
        postVehicleReservation,
        reservationUuid,
        data
      );
      yield put(setVehicleReservationSucceed(vehicleData));
      inVehicleRequest = false;
    }

    const changeOfVehicle = yield select(
      (state) => state.reservations.contract.changeVehicle.data
    );
    const hasSameReservation =
      changeOfVehicle.reservationUuid === reservationUuid;
    const data = {
      ...dataReservationContract,
      reservation: reservationUuid,
    };
    if (changeOfVehicle.changeRequestUuid && hasSameReservation) {
      data.changeRequest = changeOfVehicle.changeRequestUuid;
    }
    if (extendContract.extendContractInProgress)
      data.extendNumber = extendContract.extendNumber;

    const reservationAmount = yield select(
      (state) => state.reservations.reservationInfo.data?.payments[0]?.total
    );

    const reservationData = yield call(
      postCreateReservationContract,
      data,
      {},
      reservationAmount
    );

    yield put(
      createReservationContractSucceed({ ...reservationData, hasCreated: true })
    );
    if (redirectPath)
      yield put(
        extendContract.extendContractInProgress
          ? push('./leasing?extend=true')
          : push(redirectPath)
      );
  } catch (error) {
    console.log('error', error);
    if (inVehicleRequest) {
      yield put(setVehicleReservationFailed(error));
    } else {
      yield put(createReservationContractFailed(error));
    }
  } finally {
    yield put(hideLoader());
  }
}

export function* getContractsPdfSaga({ payload = {} }) {
  try {
    yield put(showLoader());
    const { uuid = '' } = payload;
    const data = yield call(getContractsService, uuid, getOptionsAuth());
    yield put(getContractsPDFSucceed(data));
  } catch (error) {
    yield put(getContractsPDFFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* verifyCompletedCheckListSaga({ payload = {} }) {
  try {
    let isCompleted = false;
    do {
      isCompleted = yield call(getVerifyCompletedCheckList, payload);
      yield delay(5 * 1000);
    } while (!isCompleted);
    yield put(verifyCompletedCheckListSucceed(isCompleted));
  } catch (error) {
    yield put(verifyCompletedCheckListFailed(error));
  }
}

export function* sendFormLinkSaga({ payload = {} }) {
  try {
    yield put(showLoader());
    const { id = '' } = yield select((state) => state.reservations.reservation);
    const body = {
      ...payload,
      reservation: id,
      formTemplate: 'CONTRACT',
    };

    if (body.area === 'user') {
      body.cellphone = null;
      body.email = null;
    }

    if (body.area === 'email') {
      body.cellphone = null;
    }

    if (body.area === 'cellphone') {
      body.email = null;
    }

    delete body.area;

    const data = yield call(postFormLink, body, getOptionsAuth());
    yield put(sendFormLinkSucceed(data));
  } catch (error) {
    yield put(sendFormLinkFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* sendReservation({ payload = {} }) {
  try {
    yield put(showLoader());
    const { id = '' } = yield select((state) => state.reservations.reservation);
    const body = {
      ...payload,
      reservation: id,
      formTemplate: 'CONTRACT',
    };

    if (body.area === 'user') {
      body.cellphone = null;
      body.email = null;
    }

    if (body.area === 'email') {
      body.cellphone = null;
    }

    if (body.area === 'cellphone') {
      body.email = null;
    }

    delete body.area;
    const data = yield call(sendReservationService, body, getOptionsAuth());
    yield put(sendReservationSucceed(data));
  } catch (error) {
    yield put(sendReservationFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* goToContractSaga() {
  const extendContract = yield select(
    (state) => state.reservations.contract.extendContract
  );
  if (extendContract.extendContractInProgress)
    yield put(push('/daily-rent-contracts/contract?extend=true'));
  else yield put(push('/daily-rent-contracts/contract'));
}

export function* verifySignaturesCompletedSagas({ payload }) {
  try {
    let isCompleted = false;
    do {
      isCompleted = yield call(verifySignaturesStatus, payload);
      yield delay(5 * 1000);
    } while (!isCompleted);
    yield put(verifySignaturesCompletedSucceed(isCompleted));
  } catch (error) {
    yield put(verifySignaturesCompletedFailed(error));
  }
}

export function* acceptSignaturesSagas({ payload }) {
  try {
    yield put(showLoader());
    const isString = typeof payload === 'string';

    const data = yield call(
      acceptSignatures,
      isString ? payload : payload?.form
    );

    yield put(acceptSignaturesSucceed(data));

    if (!isString && payload?.redirectPath) {
      yield put(push(payload?.redirectPath));
    }
  } catch (error) {
    yield put(acceptSignaturesFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* rejectSignaturesSagas({ payload }) {
  try {
    yield put(showLoader());
    const {
      form = '',
      redirect = '/daily-rent-contracts/promissory-note?rejected=true',
    } = payload;
    const data = yield call(rejectSignatures, form);
    yield put(rejectSignaturesSucceed(data));
    if (redirect) {
      yield put(push(redirect));
    }
  } catch (error) {
    yield put(rejectSignaturesFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getReservationFormSagas({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(getReservationForm, payload.reservation);
    yield put(getReservationFormSucceed(data));
    if (payload.redirectPath) {
      yield put(push(payload.redirectPath));
    }
  } catch (error) {
    yield put(getReservationFormFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getReservationListFiltersSagas({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(getReservationListFilter, payload);
    yield put(getReservationListFiltersSucceed(data));
  } catch (error) {
    yield put(getReservationListFiltersFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* assignCustomerSagas({ payload }) {
  try {
    yield put(showLoader());
    const reservation = yield select((state) =>
      _get(state, 'reservations.reservation.id', '')
    );
    yield call(assignCustomer, { customer: payload }, reservation);
    yield put(assignCustomerSucceed(payload));
  } catch (error) {
    yield put(assignCustomerFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* hydrateChangeOfVehicleFlowSaga(action) {
  try {
    yield put(showLoader());
    const { reservationUuid, changeRequestUuid } = action.payload;
    const reservationInfo = yield call(
      getSingleReservation,
      reservationUuid,
      getOptionsAuth()
    );
    yield put(
      hydrateChangeOfVehicleFlowSucceed({ reservationInfo, changeRequestUuid })
    );
    const step = changeRequestUuid ? reservationInfo.step : 'default';
    switch (step) {
      case RESERVATION_STEP.VEHICLE_ASSIGNMENT:
      case RESERVATION_STEP.CREATE_CONTRACT:
        yield put(rehydrateCustomerStep(reservationInfo));
        yield put(rehydratePaymentStep(reservationInfo));
        yield put(push('/daily-rent-contracts/contract?change_vehicle=true'));
        break;
      case RESERVATION_STEP.SIGN_CONTRACT:
        yield put(rehydrateCustomerStep(reservationInfo));
        yield put(rehydratePaymentStep(reservationInfo));
        yield put(push('/daily-rent-contracts/leasing?change_vehicle=true'));
        break;
      case RESERVATION_STEP.DELIVERY:
        yield put(
          push(
            `/daily-rent-contracts/${reservationUuid}/delivery-vehicle-parts`
          )
        );
        break;
      case RESERVATION_STEP.SIGN_DELIVERY:
        yield put(
          push(
            `/daily-rent-contracts/${reservationUuid}/delivery-information-and-firm`
          )
        );
        break;
      case RESERVATION_STEP.RETURN:
        yield put(push(`/returns/${reservationUuid}/refund`));
        break;
      case RESERVATION_STEP.SIGN_RETURN:
        yield put(
          push(`/returns/${reservationUuid}/refund-information-and-firm`)
        );
        break;
      case RESERVATION_STEP.CREATE_EXTRA_CHARGES_PAYMENT:
        yield put(push(`/returns/${reservationUuid}/extra-charges`));
        break;
      default:
        yield put(push('/daily-rent-contracts/contract?change_vehicle=true'));
        break;
    }
  } catch (error) {
    console.log('error', error);
    yield put(hydrateChangeOfVehicleFlowFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* createExtendContractSaga(action) {
  try {
    yield put(showLoader());
    const { reservationUuid } = action.payload;
    const body = { ...action.payload };
    const extendContractReq = yield call(postReservationExtendContract, body);
    const reservationInfo = yield call(
      getSingleReservation,
      extendContractReq.newReservationUuid,
      getOptionsAuth()
    );
    yield put(rehydrateCustomerStep(reservationInfo));
    yield put(rehydratePaymentStep(reservationInfo));
    yield put(
      hydrateExtendContractFlowSuccess({
        extendContractUuid: extendContractReq?.extendContractUuid,
        daysAdded: extendContractReq?.daysAdded,
        extendNumber: extendContractReq?.extendNumber,
        assignedVehicle: extendContractReq?.vehicleUuid,
        reservationInfo,
        originalReservation: reservationUuid,
        ...action.payload,
      })
    );
    yield put(setInProgressExtend(true));
    yield put(push('/reservations/payment?extend=true'));
  } catch (error) {
    yield put(hydrateExtendContractFlowFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* hydrateExtendContractFlowSaga(action) {
  try {
    yield put(showLoader());
    const { currentContract } = action.payload;
    const { reservationUuid } = currentContract;
    const extendedContracts = yield call(getExtendedContracts, reservationUuid);
    yield put(
      getExtendedContractsSuccess({
        extendedContracts,
        currentContract,
      })
    );
    const extendContractActive = extendedContracts.filter(
      (contract) => !contract.isEnabled
    );
    if (extendContractActive.length > 0) {
      const { reservationUuid: ecReservationUuid } = extendContractActive[0];
      const data = yield call(
        getSingleReservation,
        ecReservationUuid,
        getOptionsAuth()
      );
      yield put(setInProgressExtend(true));
      yield put(rehydrateCustomerStep(data));
      yield put(rehydratePaymentStep(data));
      yield put(
        hydrateExtendContractFlowSuccess({
          extendContractUuid: extendContractActive[0]?.uuid,
          daysAdded: extendContractActive[0]?.daysAdded,
          extendNumber: extendContractActive[0]?.extendNumber,
          assignedVehicle: extendContractActive[0]?.vehicleUuid,
          reservationInfo: data,
          originalReservation: ecReservationUuid,
          ...action.payload,
        })
      );
      switch (data.step) {
        case RESERVATION_STEP.CREATE_PAYMENT:
          yield put(push(`/reservations/payment?extend=true`));
          break;
        case RESERVATION_STEP.VEHICLE_ASSIGNMENT:
        case RESERVATION_STEP.CREATE_CONTRACT:
          yield put(push('/daily-rent-contracts/contract?extend=true'));
          break;
        case RESERVATION_STEP.SIGN_CONTRACT:
          yield put(push('/daily-rent-contracts/leasing?extend=true'));
          break;
      }
      yield put(getReservationInfoSucceed(data));
    } else {
      const data = yield call(
        getSingleReservation,
        reservationUuid,
        getOptionsAuth()
      );
      yield put(getReservationInfoSucceed(data));
      yield put(hydrateExtendContractInitFlowSuccess());
      yield put(
        push(`/daily-rent-contracts/${reservationUuid}/extend-contract`)
      );
    }
  } catch (error) {
    console.log('error', error);
    yield put(hydrateExtendContractFlowFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getExtendedContractsSaga(action) {
  try {
    yield put(showLoader());
    const { reservationUuid } = action.payload;
    const extendedContracts = yield call(getExtendedContracts, reservationUuid);
    yield put(getExtendedContractsSuccess([...extendedContracts]));
  } catch (error) {
    yield put(getExtendedContractsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* finishExtendContractSaga() {
  try {
    yield put(showLoader());
    const reservationContractUuid = yield select((state) =>
      _get(state, 'reservations.contract.reservationContract.data.uuid', '')
    );
    const extendContract = yield select((state) =>
      _get(state, 'reservations.contract.extendContract.extendContractUuid', '')
    );
    yield call(finishExtendContract, reservationContractUuid, extendContract);
    yield put(setInProgressExtend(false));
    yield put(push('/daily-rent-contracts/'));
  } catch (error) {
    yield put(getExtendedContractsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* cancelReservaionSaga(action) {
  try {
    yield put(showLoader());
    yield call(cancelReservationService, action.payload);
    yield put(cancelReservationsSuccessed());
  } catch (error) {
    yield put(cancelReservationFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getCustomerCardsSaga(action) {
  try {
    yield put(showLoader());
    const cardsCustomer = yield call(getCustomerCardsService, action.payload);
    yield put(getCustomerCardsSuccessed([...cardsCustomer]));
  } catch (error) {
    yield put(getCustomerCardsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* updateReservationSaga({ payload }) {
  try {
    yield put(showLoader());
    yield call(updateReservationService, payload);

    const listOfReservations = yield select((state) =>
      _get(state, 'reservations.listOfReservations.list', {})
    );
    const { params, searchPattern, filters } = listOfReservations;
    yield fork(getListOfReservationsSaga, {
      payload: { params, filters, searchPattern },
    });

    yield put(updateReservationSucceed());
  } catch (error) {
    yield put(updateReservationFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getDetailOfCustomerInUpdateModalSaga({ payload }) {
  try {
    yield put(setOpenCloseUpdateReservationModal({ toggle: false }));
    yield put(getDetail({ id: payload.id, withLoader: true }));
    yield take([GET_DETAIL_SUCCEEDED, GET_DETAIL_FAILED]);
  } finally {
    yield put(setOpenCloseUpdateReservationModal({ toggle: true }));
  }
}

export function* updateCardSaga({ payload }) {
  try {
    yield put(showLoader());
    yield call(updateCardService, payload);
    yield put(updateCardSucceed());
  } catch (error) {
    yield put(updateCardFailed(error));
  } finally {
    yield put(hideLoader());
  }
}
